import React, { useRef, forwardRef, useImperativeHandle } from "react";

import { externalViewLayerDefinitions } from "./layerdefinitions/externalViewLayerDefinitions";
import { GeoExtension } from "karhu-geo-extension-component";

const tools = ["TOOL_SELECTION"];
const controls = ["CONTROL_ZOOM", "CONTROL_LAYER_PANEL", "CONTROL_SCALELINE"];

function PropertyMapGeo2(
  { property, stands, onSelectStand, url, mapToken },
  ref
) {
  if (!property) return;
  const mapRef = useRef();

  useImperativeHandle(ref, () => ({
    clearSelection() {
      const clearEvent = {
        type: "geoext.selection.clear",
      };
      mapRef.current.dispatchEvent(clearEvent);
    },
  }));

  const handleMapReady = (map) => {
    console.log("map ready");
    mapRef.current = map;

    map.on("geoext.layer.addfeature", addGeometryListener);
    map.on("geoext.layer.changefeature", changeGeometryListener);
    map.on("geoext.layer.removefeature", removeGeometryListener);
    map.on("geoext.layer.selectfeature", selectFeatureListener);
    map.on("geoext.selection.cleared", selectionCleared);
    map.on("change:geoext.selection", selectionChanged);

    const insertPropertyDataEvt = {
      type: "geoext.layer.insertdata",
      layer: "compartment-vector",
      data: { property, stands }, // JSON containing property and stand data
    };
    map.dispatchEvent(insertPropertyDataEvt);
    map.dispatchEvent({
      type: "geo.targetlayer.use",
      layerId: "compartment-vector",
    });
    map.dispatchEvent({ type: "geo.tool.use", tool: "TOOL_SELECTION" });
  };

  function addGeometryListener(event) {
    console.log(event);
    // Handle new geometry from desired layer
    // Remember to handle also effects
  }
  function changeGeometryListener(event) {
    console.log(event);
    // Handle changed geometry from desired layer
    // Remember to handle also effects
  }
  function removeGeometryListener(event) {
    console.log(event);
    // Handle removed geometry from desired layer
  }
  function selectFeatureListener(event) {
    console.log("select", event);

    const currentSelection = mapRef.current.geoextGetSelections();

    // Already selected, don't go to infinite loop
    if (currentSelection.length === 1 && currentSelection[0].id === event.id) {
      return;
    }

    const clearEvent = {
      type: "geoext.selection.clear",
    };
    mapRef.current.dispatchEvent(clearEvent);

    const selectEvent = {
      type: "geoext.layer.selectfeature",
      layer: "compartment-vector",
      id: event.id,
    };
    mapRef.current.dispatchEvent(selectEvent);
  }

  function selectionChanged(event) {
    console.log(event, mapRef.current.geoextGetSelections());
    const selections = mapRef.current.geoextGetSelections();
    onSelectStand(selections && selections[0] && selections[0].id);
  }

  function selectionCleared(event) {
    console.log(event);
    // Handle selection changed
    // console.log(map.get('geoext.selection'));
  }

  if (!url || !mapToken) {
    return;
  }

  return (
    <div>
      <GeoExtension
        layerDefinitions={externalViewLayerDefinitions({ url, mapToken })}
        locale={"fi"}
        mapReady={handleMapReady}
        tools={tools}
        controls={controls}
      />
    </div>
  );
}

export default forwardRef(PropertyMapGeo2);
