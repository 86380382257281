document.addEventListener("turbolinks:load", function (event) {
  bindSupportButton();
});

function bindSupportButton() {
  const button = document.querySelector("[data-action='support-button']");
  const popup = document.querySelector("[data-role='support-popup']");
  button &&
    button.addEventListener("click", (event) => {
      popup.classList.toggle("contact-support-popup--hidden");
    });
}
