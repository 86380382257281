export function externalViewLayerDefinitions({ url, mapToken }) {
  return [
    {
      type: "WMTS",
      version: 1,
      name: "mmlTaustakarttarasteri",
      title: {
        en: "Background map",
        fi: "Taustakartta",
      },
      visible: true,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      attributions: "&copy; MML",
      layer: "mml_taustakarttarasteri",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "mml_taustakarttayhdistelma",
      title: {
        en: "Custom background map",
        fi: "Taustakartta (yhdistelmä)", // this does not render any view when the box is checked
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      attributions: "&copy; MML",
      layer: "mml_taustakarttayhdistelma",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "mmlMaastokarttarasteri",
      title: {
        en: "Topographical map",
        fi: "Maastokartta",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      attributions: "&copy; MML",
      layer: "mml_maastokarttarasteri",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "mmlOrtokuvaRgb",
      title: {
        en: "Orthophotograph (RGB)",
        fi: "Ortokuva ",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: 60000,
      attributions: "&copy; MML",
      layer: "mml_orto_rgb",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "mmlOrtokuvaVaaravari",
      title: {
        en: "Ortophotograph (False colour)",
        fi: "Ortokuva (Vääräväri)",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: 60000,
      attributions: "&copy; MML",
      layer: "mml_orto_false_color",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "cds_fi_sentinel_rgb",
      title: {
        en: "Satellite",
        fi: "Satelliitti",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      layer: "cds_fi_sentinel_rgb",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "date",
            title: "Kuvauspäivä",
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "cds_fi_sentinel_false_color",
      title: {
        en: "Satellite, false color",
        fi: "Satelliitti, vääräväri",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      layer: "cds_fi_sentinel_false_color",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "date",
            title: "Kuvauspäivä",
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "cds_fi_sentinel_rgb_all",
      title: {
        en: "Satellite (All year round))",
        fi: "Satelliitti (Koko vuoden päivittyvä)",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      layer: "cds_fi_sentinel_rgb_all",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "date",
            title: "Kuvauspäivä",
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "cds_fi_sentinel_false_color_all",
      title: {
        en: "Satellite, false color (All year round)",
        fi: "Satelliitti, vääräväri (Koko vuoden päivittyvä)",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      layer: "cds_fi_sentinel_false_color_all",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "date",
            title: "Kuvauspäivä",
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "chm_1m",
      title: {
        en: "Canopy height model (1m)",
        fi: "Puuston korkeusmalli (1m)",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      layer: "chm_1m",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "korkeus_m",
            title: "Puuston korkeus (m)",
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "chm_2m",
      title: {
        en: "Canopy height model (2m)",
        fi: "Puuston korkeusmalli (2m)",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      layer: "chm_2m",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "korkeus_m",
            title: "Puuston korkeus (m)",
          },
        ],
      },
    },
    {
      version: 1,
      type: "Vector",
      name: "compartment-vector",
      title: {
        en: "Compartments",
        fi: "Kuviot",
      },
      visible: true,
      opacity: 1,
      minScaleDenominator: null,
      maxScaleDenominator: null,
      format: "GeoJSON",
      projection: "EPSG:3067",
      styles: [
        {
          version: 1,
          title: {
            en: "Compartments",
            fi: "Kuviot",
          },
          stroke: {
            color: "#0d3be0",
            width: 3,
          },
          fill: {
            color: "rgba(255,255,255,0.3)",
          },
          text: {
            font: "14px sans-serif",
            template: {
              en: "{{properties.identifier}}",
              fi: "{{properties.identifier}}",
            },
          },
        },
      ],
      properties: {
        "geo-layer-type": "overlay",
        "geo-layer-geometry-types": ["Polygon"],
        "geo-layer-info-fields": [
          {
            field: "forestIdentifier",
            title: "Kiinteistötunnus",
          },
          {
            field: "identifier",
            title: "Kuvionumero",
          },
          {
            field: "siteClass",
            title: "Kasvupaikkaluokka",
          },
          {
            field: "soilType",
            title: "Kuivatustilanne",
          },
          {
            field: "developmentClass",
            title: "Kehitysluokka",
          },
          {
            field: "accessibility",
            title: "Korjuukelpoisuus",
          },
          {
            field: "age",
            title: "Puuston keski-ikä (v)",
          },
          {
            field: "area",
            title: "Pinta-ala (ha)",
          },
          {
            field: "basalArea",
            title: "Pohjapinta-ala (m2/ha)",
          },
          {
            field: "density",
            title: "Runkoluku (kpl/ha)",
          },
          {
            field: "averageDiameter",
            title: "Keskiläpimitta (cm)",
          },
          {
            field: "averageLength",
            title: "Keskipituus (m)",
          },
          {
            field: "totalTreeVolume",
            title: "Tilavuus (m3/ha)",
          },
        ],
      },
    },
  ];
}
