import React, { Component } from "react";
import _ from "lodash";

import { formatCurrency } from "../../../helpers/currency";
import earningsEstimateShape from "../../../proptypes/EarningsEstimate";

import KarhuBarChart from "../../KarhuBarChart";

class PropertyDetailsValueGrowth extends Component {
  renderTableRows() {
    const { earningsEstimate } = this.props;
    const rows = earningsEstimate.map(item => {
      return (
        <tr key={item.year}>
          <td className="border-bottom border-color-border padding-0-25">
            {item.year}
          </td>
          <td className="border-bottom border-color-border padding-0-25 text-align-right color-green">
            {item.valueOfGrowth === 0 ? "" : formatCurrency(item.valueOfGrowth)}
          </td>
          <td className="border-bottom border-color-border padding-0-25 text-align-right color-green">
            {item.fellingIncomes === 0
              ? ""
              : formatCurrency(item.fellingIncomes)}
          </td>
          <td className="border-bottom border-color-border padding-0-25 text-align-right color-red">
            {item.treatmentCosts === 0
              ? ""
              : formatCurrency(item.treatmentCosts)}
          </td>
        </tr>
      );
    });
    return rows;
  }
  renderTableFooter() {
    const { earningsEstimate } = this.props;
    let totalValueOfGrowth = _.sum(
      earningsEstimate.map(function(i) {
        return i.valueOfGrowth;
      })
    );
    let totalFellingIncomes = _.sum(
      earningsEstimate.map(function(i) {
        return i.fellingIncomes;
      })
    );
    let totalTreatmentCosts = _.sum(
      earningsEstimate.map(function(i) {
        return i.treatmentCosts;
      })
    );
    return (
      <tfoot>
        <tr>
          <td className="no-borders padding-0-25 size-1">Yhteensä</td>
          <td className="no-borders padding-0-25 text-align-right">
            <span className="color-green size-1 block line-height-1">
              {formatCurrency(totalValueOfGrowth)}
            </span>
          </td>
          <td className="no-borders padding-0-25 text-align-right">
            <span className="color-green size-1 block line-height-1">
              {formatCurrency(totalFellingIncomes)}
            </span>
          </td>
          <td className="no-borders padding-0-25 text-align-right">
            <span className="color-red size-1 block line-height-1">
              {formatCurrency(totalTreatmentCosts)}
            </span>
          </td>
        </tr>
      </tfoot>
    );
  }
  render() {
    const { earningsEstimate } = this.props;
    return (
      <section className="border-bottom border-color-border">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-l-7">
              <div className="text-align-center padding-1-5 padding-top">
                <h2 className="size-1-5 margin-0-5 margin-bottom">
                  Ennuste tilan arvonkehityksestä
                </h2>
                <KarhuBarChart data={earningsEstimate} />
              </div>
            </div>
            <div className="col-xs-12 col-l-5 col-l-border-left border-color-border">
              <div>
                <table>
                  <thead>
                    <tr>
                      <th className="border-bottom border-color-border color-gray-darken-1 light padding-0-25 size-0-75 uppercase">
                        Vuosi
                      </th>
                      <th className="border-bottom border-color-border color-gray-darken-1 light padding-0-25 size-0-75 uppercase text-align-right">
                        Kasvu
                      </th>
                      <th className="border-bottom border-color-border color-gray-darken-1 light padding-0-25 size-0-75 uppercase text-align-right">
                        Hakkuutulot
                      </th>
                      <th className="border-bottom border-color-border color-gray-darken-1 light padding-0-25 size-0-75 uppercase text-align-right">
                        Investoinnit
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderTableRows()}</tbody>
                  {this.renderTableFooter()}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

PropertyDetailsValueGrowth.propTypes = {
  earningsEstimate: earningsEstimateShape.isRequired
};

export default PropertyDetailsValueGrowth;
