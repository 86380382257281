import React from "react";

import {Button} from "../../Button/Button";
import {DetailsListItem} from "../../DetailsListItem/DetailsListItem";

import DocumentLink from "./DocumentLink";

export default function ValuationSummary({item, setValuationFormOpen}) {
  function recommenderPercentage() {
    if (item.correction_percent != item.recommended_correction_percent) {
      return <span style={{textDecoration: "line-through"}}>{item.recommended_correction_percent} %</span>;
    } else {
      return null;
    }
  }

  return (
    <React.Fragment>
      <div className="metsa-arvio-content__top">
        <div className="metsa-arvio-section">
          <ul>
            <DetailsListItem label="Käypä arvo" value={item.total_value} />
            <DetailsListItem label="Euroa / motti" value={item.euro_per_encirclement} />
            <DetailsListItem label="Euroa / hehtaari" value={item.euro_per_hectare} />
          </ul>
        </div>
        <div className="padding-0-5 child-margins-y-0-25">
          <div>
            <h4 className="size-1 weight-500">Muut arvioon vaikuttavat tekijät {item.value_adjustment} €</h4>
            <p className="size-0-75">
              {item.value_adjustment_description || <span className="color-gray-darken-1">Ei perusteluita</span>}
            </p>
          </div>
          <div>
            <h4 className="size-1 weight-500">Arvonkorjausprosentti {item.correction_percent} % {recommenderPercentage()}</h4>
            <p className="size-0-75">
              {item.correction_percent_text || <span className="color-gray-darken-1">Ei perusteluita</span>}
            </p>
          </div>
        </div>
      </div>
      <div className="metsa-arvio-content__bottom">
        <div className="metsa-arvio-section flex align-center justify">
          <div className="size-0-75">{item.processed_by ? `Arvion tehnyt: ${item.processed_by}` : ""}</div>
          <Button onClick={() => setValuationFormOpen(true)} size="tiny">Muokkaa arviointia</Button>
        </div>
        <DocumentLink id={item.id} title="Lataa valmis metsäarvio"/>
      </div>
    </React.Fragment>
  );
}
