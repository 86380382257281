import React, { Component } from "react";
import PropTypes from "prop-types";

class ForestGroupSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasValue: false
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleFocus(event) {
    this.setState({ isFocused: true });
    this.props.onFocus(event);
  }
  handleBlur(event) {
    this.setState({ hasValue: Boolean(event.currentTarget.value) });
    this.setState({ isFocused: false });
    this.props.onBlur(event);
  }
  handleChange(event) {
    this.setState({ hasValue: Boolean(event.currentTarget.value) });
    this.props.onChange(event);
  }

  renderLabel() {
    if (this.props.label) {
      return (
        <div className="forest-group-select__label">{this.props.label}</div>
      );
    }
  }

  render() {
    const { disabled, value, getLabel } = this.props;

    let wrapperClasses = ["forest-group-select"];
    if (this.state.isFocused) {
      wrapperClasses.push("forest-group-select--focused");
    }
    return (
      <div className={wrapperClasses.join(" ")}>
        <div className="flex align-center">
          <div className="grow">
            {this.renderLabel()}
            <div className="forest-group-select__value">{getLabel(value)}</div>
          </div>
        </div>
        <select
          className="forest-group-select__input"
          value={value}
          disabled={disabled}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          {this.props.children}
        </select>
      </div>
    );
  }
}

ForestGroupSelect.propTypes = {
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  getLabel: PropTypes.func,
  value: PropTypes.any,
  children: PropTypes.node
};

ForestGroupSelect.defaultProps = {
  disabled: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  getLabel: value => value
};

export default ForestGroupSelect;
