import React from "react";
import cx from "classnames";

export function DetailsListItem({label, value, strike}) {
  return (
    <li className="details-list-item">
      <div className="details-list-item__label">{label}</div>
      <div className={cx("details-list-item__value", {"details-list-item__value--strike": strike})}>{value}</div>
    </li>
  );
}
