import React, { Component } from "react";
import PropTypes from "prop-types";
import { Circle } from "rc-progress";

const STROKE_WIDTH = 6;
const STROKE_COLOR = "#ff6a10";

class PropertyProgressBox extends Component {
  percent() {
    const { total, done } = this.props;
    if (total === 0 || done === 0) return 0;
    return (100 / total) * done;
  }

  render() {
    const { total, done } = this.props;
    return (
      <div className="product-progress-box">
        <h3 className="product-progress-box__numbers size-38">
          <span className="color-primary">{done}</span>
          <span className="color-gray">/{total}</span>
        </h3>
        <Circle
          percent={this.percent()}
          strokeWidth={STROKE_WIDTH}
          strokeColor={STROKE_COLOR}
        />
      </div>
    );
  }
}

PropertyProgressBox.propTypes = {
  total: PropTypes.number.isRequired,
  done: PropTypes.number.isRequired
};

export default PropertyProgressBox;
