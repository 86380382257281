document.addEventListener("turbolinks:load", function(event) {
  const opKotiRequestConsentCheckbox = document.querySelector("[data-action='op-koti-request-consent-toggle']");
  const opKotiRequestSubmit = document.querySelector("[data-role='op-koti-request-submit']");

  // Enable submit button only if the consent checkbox is checked.
  if (opKotiRequestConsentCheckbox) {
    opKotiRequestConsentCheckbox.addEventListener("change", (event) => {
      if (event.target.checked) {
        opKotiRequestSubmit.disabled = false;
      } else {
        opKotiRequestSubmit.disabled = true;
      }
    });
  }
});
