import React from "react";
import {Loader} from "../Loader/Loader";

export function BlockLink({title, subtitle, href, loading, loadingText, notice}) {
  if (loading) {
    return (
      <div className="metsa-arvio-link metsa-arvio-link--loading">
        <Loader />
        {loadingText}
      </div>
    );
  } else if (notice) {
    return (
      <div id="draft-link-notice" className="metsa-arvio-link metsa-arvio-link--message">
        <i className="opux-icon opux-icon-alert metsa-arvio-link__icon" />
        {notice}
      </div>
    );
  } else {
    return (
      <a className="metsa-arvio-link" href={href} target="_blank">
        <i className="opux-icon opux-icon-file metsa-arvio-link__icon" />
        <div className="metsa-arvio-link__text">
          {subtitle && <div className="metsa-arvio-link__subtext">
            {subtitle}
          </div>}
          {title}
        </div>
        <i className="opux-icon opux-icon-chevron-r metsa-arvio-link__chevron" />
      </a>
    );
  }
}
