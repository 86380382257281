import React, {useState} from "react";
import {ConfirmDialog} from "../ConfirmDialog/ConfirmDialog";
import request from "../../../request";

export default function PaymentStatus({orderData, orderToken}) {
  const [resendNotice, setResendNotice] = useState("");
  const [noticeColor, setNoticeColor] = useState("");

  function displayNotice(notice, color) {
    setResendNotice(notice);
    setNoticeColor(color);

    setTimeout(() => {
      setResendNotice("");
    }, 5000)
  }

  function resendReceipt() {
    request.forestValuationResendReceipt(orderToken)
      .then(response => {
        if (response.notice) {
          displayNotice(response.notice, "green");
        } else {
          displayNotice(response.error, "red");
        }
      })
      .catch((error) => {
        if(error?.responseJSON?.error) {
          displayNotice(error.responseJSON.error, "red");
        } else {
          displayNotice("Sähköpostia ei saatu lähetettyä. Yritä myöhemmin uudelleen.", "red");
        }
      });
  }

  const paymentStatusSection = () => {
    let resend = orderData.send_receipt ? (
      <div className={"tile margin-1 margin-top padding-0-5 flex justify-center"}>
        <ConfirmDialog onClick={resendReceipt} buttonText="Lähetä">
          <button>Lähetä tilausvahvistus uudelleen</button>
          <div className={"flex justify-center"} style={{ color: noticeColor}}>{resendNotice}</div>
        </ConfirmDialog>
      </div>
    ) : null;

    let paymentStatus = null;
    switch (orderData.payment_status) {
      case "not_paid":
        paymentStatus = <div className={"color-gray-darken-1 text-align-center"}>Odotetaan maksutapahtumaa</div>;
        break;
      case "cancelled":
        paymentStatus = <div className={"color-red text-align-center"}>Peruttu</div>;
        break;
      default:
        paymentStatus = <div className={"color-primary text-align-center"}>Maksettu: {orderData.payment_at}</div>;
    }
    return (
      <div>
        {resend}
        <div className={"tile margin-1 margin-top padding-0-5"}>
          {paymentStatus}
        </div>
      </div>
    );
  };
  return (
    <div>
      {paymentStatusSection()}
    </div>
  );
}
