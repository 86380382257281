import React, {useState, useEffect, createRef} from "react";
import request from "../../../request";

// Components
import ForestValuationOrderItem from "./ForestValuationOrderItem";
import AssignmentHeader from "./AssignmentHeader";
import OrderInfo from "./OrderInfo";
import SendToRequesterSection from "./SendToRequesterSection";

export default function ForestValuationOrder({orderToken = null}) {
  const [orderData, setOrderData] = useState({});
  const [items, setItems] = useState([]);
  const [orderStatus, setOrderStatus] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);

  function handleOrderData() {
    request.forestValuationOrderData(orderToken)
      .then(response => {
        if (response.data) {
          setOrderData(response.data);
          setItems(response.data.item_ids.map((id) =>
            <ForestValuationOrderItem itemId={id} key={id} reloadOrder={handleOrderData}/>
          ));
          setOrderStatus(response.status);
        } else {
          setOrderData(response.error);
        }
      })
      .catch(() => {
        setErrorMessage("Tietoja ei saatu haettua. Yritä myöhemmin uudelleen.");
      });
  }

  useEffect(() => {
    handleOrderData();
  }, []);

  function canProcessOrder() {
    return orderData.hasOwnProperty("current_user_can_process") && orderData.current_user_can_process;
  }

  return (
    <div>
      <AssignmentHeader orderToken={orderToken} orderData={orderData}/>
      <section className={`padding-1 padding-y ${canProcessOrder() ? "" : "disabled-overlay"}`}>
        <div className={"container"}>
          <div className={"row"}>
            <OrderInfo orderData={orderData} orderToken={orderToken}/>
            <div className={"col-xs-12"}>
              <h3 className={"size-1-25 weight-500 margin-0-5 margin-bottom"}>Tuotteet</h3>
              <div className="child-margins-y-2">

                {items}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SendToRequesterSection orderStatus={orderStatus} orderToken={orderToken} reloadOrder={handleOrderData}/>
    </div>
  );
}

