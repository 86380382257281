import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class PikaAnalyysiSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      submitEnabled: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateInput(value) {
    return /^([0-9]+-){3}[0-9]+$/.test(value);
  }

  handleInputChange(e) {
    const value = e.target.value;
    this.setState({
      inputValue: value,
      submitEnabled: this.validateInput(value)
    });
  }

  handleSubmit(e) {
    const { inputValue } = this.state;
    const { disabled } = this.props;
    e.preventDefault();
    if (!disabled && this.validateInput(inputValue)) {
      this.props.onSubmit(inputValue);
    }
  }
  renderError() {
    if (this.props.errorMessage) {
      return (
        <div className="code-search__error">
          <div className="code-search__error-icon">
            <i className="size-1 opux-icon opux-icon-alert top-1" />
          </div>
          <div className="code-search__error-text">
            {this.props.errorMessage}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  render() {
    const { inputValue, submitEnabled } = this.state;
    const { disabled } = this.props;
    const submitButtonEnabled = submitEnabled && !disabled;
    return (
      <form
        disabled={this.props.disabled}
        onSubmit={this.handleSubmit}
        className="real-estate-code-search-input">
        <div className="code-search">
          <div
            className={classnames("code-search__inner", {
              "code-search__inner--focused": this.state.inputFocused
            })}>
            <input
              id="propertyCodeInput"
              name="propertyCodeInput"
              placeholder="Aloita syöttämällä kiinteistötunnus"
              type="text"
              className="code-search__input"
              onFocus={() => this.setState({ inputFocused: true })}
              onBlur={() => this.setState({ inputFocused: false })}
              onChange={this.handleInputChange}
              value={inputValue}
            />
            <button
              type="submit"
              className={classnames(
                "code-search__button opux-btn opux-btn-primary",
                {
                  "opux-btn-disabled": !submitButtonEnabled
                }
              )}>
              Tee pika-analyysi
            </button>
          </div>
          {this.renderError()}
        </div>
      </form>
    );
  }
}

PikaAnalyysiSearch.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

export default PikaAnalyysiSearch;
