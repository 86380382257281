import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { formatCurrency } from "../../helpers/currency";

import ActionTimelineItem from "./ActionTimelineItem";

class ActionTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsShown: 5,
      hideShowMoreButton: this.props.actions.length <= 5,
      loading: false
    };

    this.handleShowMore = this.handleShowMore.bind(this);
  }

  renderItems() {
    const { forSingleProperty } = this.props;

    let actionsShown = [];

    if (forSingleProperty) {
      actionsShown = this.props.actions;
    } else {
      actionsShown = _.take(this.props.actions, this.state.itemsShown);
    }

    return actionsShown.map(action => {
      return (
        <ActionTimelineItem
          forSingleProperty={forSingleProperty}
          key={action.id}
          propertyName={action.propertyName}
          propertySlug={action.propertySlug}
          stand={action.propertyStand}
          operationType={action.operationType}
          operation={action.operation}
          proposedYear={action.proposedYear}
        />
      );
    });
  }
  renderShowMore() {
    if (!this.props.forSingleProperty && !this.state.hideShowMoreButton) {
      return (
        <li className="timeline__more">
          <button
            className="timeline__load opux-btn opux-btn-medium"
            onClick={this.handleShowMore}
          >
            Näytä lisää
          </button>
        </li>
      );
    }
  }

  handleShowMore() {
    this.setState({ itemsShown: this.state.itemsShown + 5 });

    if (this.props.actions.length <= this.state.itemsShown + 6) {
      this.setState({ hideShowMoreButton: true });
    }
  }

  renderEmptyMessage() {
    if (this.props.forSingleProperty) {
      if (this.props.dataSource === "Luonnonvarakeskus") {
        return "Metsätilan tiedot perustuvat Luonnonvarakeskuksen tietoihin, eivätkä sisällä toimenpidesuosituksia.";
      } else {
        return "Metsäkeskuksen tiedot eivät sisällä tämän tilan toimenpidesuosituksia.";
      }
    } else {
      return "Metsätilojen toimenpidesuosituksia ei ole saatavilla.";
    }
  }

  render() {
    if (this.props.actions.length > 0) {
      return (
        <section className="bg-gray-lighten-3 padding-1-5 padding-y">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-xl-8 col-xl-shift-2 col-l-10 col-l-shift-1">
                <h2 className="size-1-5 text-align-center margin-0-5 margin-bottom">
                  Suositellut toimenpiteet
                </h2>
                <p className="size-1 color-gray-darken-1 text-align-center margin-0-25 margin-bottom">
                  {" "}
                  Toimenpidesuositukset sekä tulo- ja menoarviot perustuvat
                  Metsään.fi -palvelun perustietoihin.
                </p>
                <div className="text-align-center margin-1-5 margin-bottom">
                  <p>
                    <span className="size-1 nowrap inline-block color-gray-darken-1">
                      Arvio tuloista (10v){" "}
                      <span className="color-gray-darken-2">
                        {formatCurrency(this.props.totalOperationIncome)}&nbsp;
                      </span>
                    </span>
                    <span className="size-1 nowrap inline-block color-gray-darken-1">
                      Arvio menoista (10v){" "}
                      <span className="color-gray-darken-2">
                        {formatCurrency(this.props.totalOperationCost)}
                      </span>
                    </span>
                  </p>
                </div>
                <ul className="timeline">
                  {this.renderItems()}
                  {this.renderShowMore()}
                </ul>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section className="bg-gray-lighten-3 padding-1-5 padding-y">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-xl-8 col-xl-shift-2 col-l-10 col-l-shift-1">
                <h2 className="size-1-5 text-align-center marign-0-5 margin-bottom">
                  Toimenpidetietoja ei saatavilla
                </h2>
                <p className="size-1 color-gray-darken-1 text-align-center">
                  {this.renderEmptyMessage()}
                </p>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

ActionTimeline.propTypes = {
  actions: PropTypes.array.isRequired,
  dataSource: PropTypes.string,
  forSingleProperty: PropTypes.bool,
  totalOperationIncome: PropTypes.number.isRequired,
  totalOperationCost: PropTypes.number.isRequired
};

export default ActionTimeline;
