import React, {useState, useEffect} from "react";
import request from "../../../request";
import ForestValuationOrderItem from "./ForestValuationOrderItem";

export default function SendToRequesterSection({orderStatus, orderToken, reloadOrder}) {
  const [errorMessage, setErrorMessage] = useState("");

  const ONE_MINUTE_MS = 60000;

  const send = () => {
    request.forestValuationFinish(orderToken)
      .then()
      .catch(() => {
        setErrorMessage("Tilausta ei saatu toimitettua. Yritä uudelleen myöhemmin.");
      });
  };

  const sendContent = () => {
    if (orderStatus.email_has_been_sent) {
      return <div className={"size-1-25"}><div className={"size-1-25.weight-500"}>{orderStatus.email_sent_at}</div></div>;
    } else {
      return (
        <button
          className={"opux-btn opux-btn-primary opux-btn-small"}
          disabled={!orderStatus.can_be_sent}
          onClick={send}>Lähetä asiakkaalle</button>);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      reloadOrder();
    }, ONE_MINUTE_MS);
    if (orderStatus.can_be_sent) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  });
  return (
    <section className={"padding-2 padding-y border-top border-color-border-lighten-1"}>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-xs-12"}>
            <div className={"text-align-center padding-1 padding-bottom"}>
              {sendContent()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
