import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { formatCurrency } from "../../../helpers/currency";
import { forestName } from "../../../helpers/forest";
import earningsEstimateShape from "../../../proptypes/EarningsEstimate";

class PropertyDetailsHero extends Component {
  heroCssClasses() {
    let defaultClasses = ["hero", "color-white", "flex", "vertical"];
    defaultClasses.push("hero--property-" + this.props.dominantTreeType);
    return defaultClasses.join(" ");
  }

  renderTreeDetails() {
    const {
      seedlingsValue,
      stockValue,
      totalFellingIncomes,
      earningsEstimate
    } = this.props;
    const growthOverTenYears = _.sum(
      earningsEstimate.map(function(i) {
        return i.fellingIncomes - i.treatmentCosts;
      })
    );

    return (
      <div className="hero-content padding-0-5 padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-m-10 col-m-shift-1">
              <ul className="stats-grid stats-grid--hero">
                <li className="stats-grid__item padding-0-5 padding-y">
                  <div className="stats-grid__label text-shadow-small">
                    Taimikot
                  </div>
                  <div className="stats-grid__value text-shadow-small">
                    {formatCurrency(seedlingsValue)}
                  </div>
                </li>
                <li className="stats-grid__item padding-0-5 padding-y">
                  <div className="stats-grid__label text-shadow-small">
                    Puusto
                  </div>
                  <div className="stats-grid__value text-shadow-small">
                    {formatCurrency(stockValue)}
                  </div>
                </li>
                <li className="stats-grid__item padding-0-5 padding-y">
                  <div className="stats-grid__label text-shadow-small">
                    Hakkuumahdollisuudet (10 v.)
                  </div>
                  <div className="stats-grid__value text-shadow-small">
                    {formatCurrency(totalFellingIncomes)}
                  </div>
                </li>
                <li className="stats-grid__item padding-0-5 padding-y">
                  <div className="stats-grid__label text-shadow-small">
                    Arvioitu nettotulo (10 v.)
                  </div>
                  <div className="stats-grid__value text-shadow-small">
                    {formatCurrency(growthOverTenYears)}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNavigation() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="hero-toolbar padding-0-5 padding-y">
                <div className="hero-toolbar__left" />
                <div className="hero-toolbar__middle">
                  <ul className="segmented-button segmented-button--shadow text-align-center">
                    <span className="segmented-button__item segmented-button__item--active">
                      Tiedot
                    </span>
                    <a
                      href={this.props.propertyMapPath}
                      data-turbolinks="false"
                      className="segmented-button__item"
                    >
                      Kartta
                    </a>
                  </ul>
                </div>
                <div className="hero-toolbar__right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPropertyDetails() {
    const { forestIdentifier, totalValue } = this.props;

    return (
      <div className={this.heroCssClasses()}>
        {this.renderNavigation()}

        <div className="flex grow align-center padding-0-5 padding-y">
          <div className="width-100">
            <div className="container">
              <div className="hero-property-content">
                <div className="hero-property-content__left">
                  <h1 className="size-1-75 line-height-1 text-shadow-large">
                    {forestName(this.props)}
                  </h1>
                  <span className="size-1-25 text-shadow-small">
                    Kiinteistötunnus: {forestIdentifier}
                  </span>
                </div>
                <div className="hero-property-content__right">
                  <span className="size-1-25 text-shadow-small">
                    Arvioitu tilan arvo
                  </span>
                  <div className="size-1-75 line-height-1 text-shadow-large">
                    {formatCurrency(totalValue)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.renderTreeDetails()}
      </div>
    );
  }

  render() {
    return <section>{this.renderPropertyDetails()}</section>;
  }
}

PropertyDetailsHero.propTypes = {
  dominantTreeType: PropTypes.string.isRequired,
  earningsEstimate: earningsEstimateShape,
  forestIdentifier: PropTypes.string.isRequired,
  totalValue: PropTypes.number.isRequired,
  totalFellingIncomes: PropTypes.number.isRequired,
  propertyMapPath: PropTypes.string.isRequired,
  seedlingsValue: PropTypes.number.isRequired,
  stockValue: PropTypes.number.isRequired,
  owners: PropTypes.arrayOf(PropTypes.string)
};

export default PropertyDetailsHero;
