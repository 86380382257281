
import React from "react";

import DraftLink from "./DraftLink";
import DocumentLink from "./DocumentLink";
import {DetailsListItem} from "../../DetailsListItem/DetailsListItem";

export default function PropertyInfo({item, hasBeenValuated}) {
  return (
    <React.Fragment>
      <div className="metsa-arvio-content__top">
        <div className="metsa-arvio-section">
          <ul>
            <DetailsListItem key="total_value" label="Käypä arvo" value={item.total_value_initial} strike={hasBeenValuated} />
            <DetailsListItem key="euro_per_encirclement" label="Euroa / motti" value={item.euro_per_encirclement_initial} strike={hasBeenValuated} />
            <DetailsListItem key="euro_per_hectare" label="Euroa / hehtaari" value={item.euro_per_hectare_initial} strike={hasBeenValuated} />
          </ul>
        </div>
        <div className="metsa-arvio-section">
          <ul>
            <DetailsListItem key="total_area" label="Kokonaisala" value={item.total_area} />
            <DetailsListItem key="total_forest_area" label="Metsäala" value={item.total_forest_area} />
            <DetailsListItem key="field_area" label="Peltoala" value={item.field_area} />
            <DetailsListItem key="uninventioned_forest_area" label="Inventoimaton pinta-ala" value={item.uninventioned_forest_area} />
            <DetailsListItem key="uninventioned_forest_price" label="Inventoimattoman alan arvo" value={item.uninventioned_forest_price} />
          </ul>
          <div key="peelo" className="margin-0-5 margin-top">
            <h4 className="size-1 medium">Metsänkäyttöilmoitukset</h4>
            <div className="size-0-75">
              {item.cutting_realizations && item.cutting_realizations.length > 0
                ? item.cutting_realizations.map(cuttingRealizationItem =>
                  <div>{cuttingRealizationItem}</div>
                )
                : <span className="color-gray-darken-1">Ei ilmoituksia </span>
                }
            </div>
          </div>
        </div>
      </div>
      <div className="metsa-arvio-content__bottom child-borders-y">
        <DraftLink id={item.id} initialProcessable={item.plus_is_possible} />
        {item.existingValuation &&
          <DocumentLink id={item.existingValuation.id} title="Lataa aikaisemmin tehty metsäarvio" subtitle={`Tälle kiinteistölle löytyy aikaisempi arvio, joka on laadittu ${item.existingValuation.timestamp}`}/>
        }
      </div>
    </React.Fragment>
  );
}
