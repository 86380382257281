import React, { useState } from "react";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import { formatArea } from "../../helpers/area";
import { formatVolume } from "../../helpers/volume";
import { formatCurrency } from "../../helpers/currency";

import DataTable from "react-data-table-component";

import PropertyMapSidebar from "../PropertyMapSidebar";

const columns = [
  {
    name: "Kuvio nro",
    selector: "identifier",
    sortable: true
  },
  {
    name: "Pinta-ala",
    selector: "area",
    sortable: true,
    format: row => formatArea(row.area)
  },
  {
    name: "Pituus",
    selector: "averageLength",
    sortable: true,
    hide: "lg",
    format: row => row.averageLength + " m"
  },
  {
    name: "Pääpuulaji",
    selector: "mainTreeType",
    sortable: true,
    hide: "sm"
  },
  {
    name: "Kehitysluokka",
    selector: "developmentClass",
    sortable: true,
    right: true
  },
  {
    name: "Kuitupuu",
    selector: "pulpWoodTotal",
    sortable: true,
    right: true,
    hide: "md",
    format: row =>
      row.pulpWoodTotal !== "-" ? formatVolume(row.pulpWoodTotal) + "/ha" : "-"
  },
  {
    name: "Tukkipuu",
    selector: "sawWoodTotal",
    sortable: true,
    right: true,
    hide: "md",
    format: row =>
      row.sawWoodTotal !== "-" ? formatVolume(row.sawWoodTotal) + "/ha" : "-"
  },
  {
    name: "Yhteensä",
    selector: "treeVolume",
    sortable: true,
    right: true,
    format: row =>
      row.treeVolume !== "-" ? formatVolume(row.treeVolume) + "/ha" : "-"
  }
];

const PropertyStands = ({ stands, actions }) => {
  const [activeTab, setActiveTab] = useState("stands");
  const standData = stands.map(stand => {
    return {
      identifier: stand.identifier,
      area: stand.area,
      averageLength:
        stand.treeSummaries && stand.treeSummaries.length
          ? stand.treeSummaries[0].averageLength
          : "-",
      mainTreeType:
        stand.treeSummaries &&
        stand.treeSummaries.length &&
        stand.treeSummaries[0].treeSpecies
          ? getMainTreeSpecies(stand.treeSummaries[0].treeSpecies)
          : "-",
      developmentClass: getDevelopmentClass(stand.developmentClass),
      pulpWoodTotal:
        stand.treeSummaries &&
        stand.treeSummaries.length &&
        stand.treeSummaries[0].pulpWoodSummaries
          ? getWoodTotal(stand.treeSummaries[0].pulpWoodSummaries) / stand.area
          : "-",
      sawWoodTotal:
        stand.treeSummaries &&
        stand.treeSummaries.length &&
        stand.treeSummaries[0].sawLogSummaries
          ? getWoodTotal(stand.treeSummaries[0].sawLogSummaries) / stand.area
          : "-",
      treeVolume:
        stand.treeSummaries && stand.treeSummaries.length
          ? stand.treeSummaries[0].treeVolume / stand.area
          : "-",
      stand: stand
    };
  });
  const actionsOrdered = orderBy(
    actions,
    [action => parseInt(action.propertyStand.identifier), "operationType"],
    "asc"
  );
  const actionsGrouped = groupBy(actionsOrdered, "proposedYear");

  const features = stands.reduce((filtered, stand) => {
    if (stand.specialFeatures && stand.specialFeatures.length) {
      stand.specialFeatures.map(feature => {
        filtered.push({
          type: feature.featureType,
          identifier: stand.identifier,
          name: feature.name,
          description: feature.description
        });
      });
    }
    return filtered;
  }, []);

  const featuresData = orderBy(features, [
    feature => parseInt(feature.identifier)
  ]);

  return (
    <div className="container">
      <div className="row center-m">
        <div className="col-xs-12 col-m-10 col-l-8 col-xl-6">
          <div className="margin-1 margin-bottom padding-0-5 padding-x">
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {activeTab === "stands" && <Stands standData={standData} />}
          {activeTab === "actions" && <Actions actionData={actionsGrouped} />}
          {activeTab === "specialFeatures" && (
            <Features featuresData={featuresData} />
          )}
        </div>
      </div>
    </div>
  );
};

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <ul className="segmented-button text-align-center">
      <button
        className={`segmented-button__item ${activeTab === "stands" &&
          "segmented-button__item--active"}`}
        onClick={() => setActiveTab("stands")}>
        Kuviot
      </button>
      <button
        className={`segmented-button__item ${activeTab === "actions" &&
          "segmented-button__item--active"}`}
        onClick={() => setActiveTab("actions")}>
        Toimenpiteet
      </button>
      <button
        className={`segmented-button__item ${activeTab === "specialFeatures" &&
          "segmented-button__item--active"}`}
        onClick={() => setActiveTab("specialFeatures")}>
        Luontokohteet
      </button>
    </ul>
  );
};

const Stands = ({ standData }) => {
  if (standData && standData.length > 0) {
    return (
      <div className="rdt">
        <DataTable
          defaultSortField="identifier"
          columns={columns}
          data={standData}
          sortFunction={customSort}
          expandableRows
          expandableRowsComponent={<StandExpanded />}
        />
      </div>
    );
  } else {
    return (
      <div className="text-align-center size-1-25 color-gray-darken-1 padding-4 padding-y">
        Ei kuviotietoja
      </div>
    );
  }
};

const StandExpanded = ({ data }) => {
  if (data && data.stand) {
    const { stand } = data;
    return (
      <PropertyMapSidebar
        key={stand.id}
        propertyName={"Kuviotiedot"}
        stand={stand}
        tableView
      />
    );
  } else {
    return (
      <div className="padding-0-5">
        Metsävaratiedon mukaan metsälle ei kohdistu hoitotoimenpiteitä
        seuraavalle 10 vuodelle.
      </div>
    );
  }
};

const Actions = ({ actionData }) => {
  if (actionData) {
    return (
      <div className="child-margins-y-1">
        {Object.keys(actionData).map(year => {
          return (
            <ActionYear key={year} year={year} actions={actionData[year]} />
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="text-align-center size-1-25 color-gray-darken-1 padding-4 padding-y">
        Ei tulevia toimenpiteitä
      </div>
    );
  }
};

const ActionYear = ({ year, actions }) => {
  return (
    <div>
      <h4 className="size-1-25 padding-0-25 weight-500">{year}</h4>
      <table className="compact-table">
        <thead>
          <tr>
            <th>Kuvio Nro</th>
            <th>Pinta-ala</th>
            <th>Toimenpide</th>
            <th className="compact-table__hide-l">Kasvupaikka</th>
            <th className="compact-table__hide-m">Pituus</th>
            <th className="compact-table__hide-s">Ikä</th>
            <th className="text-align-right">Tulot/Menot</th>
          </tr>
        </thead>
        <tbody>
          {actions.map(action => (
            <tr key={action.id}>
              <td>{action.propertyStand.identifier}</td>
              <td>{formatArea(action.operation.area)}</td>
              <td>{action.operation.name}</td>
              <td className="compact-table__hide-l">
                {action.propertyStand && action.propertyStand.soil_type
                  ? action.propertyStand.soil_type
                  : "-"}
              </td>
              <td className="compact-table__hide-m">
                {action.propertyStand &&
                action.propertyStand.tree_summaries &&
                action.propertyStand.tree_summaries.length &&
                action.propertyStand.tree_summaries[0]
                  ? action.propertyStand.tree_summaries[0].averageLength + " m"
                  : "-"}
              </td>
              <td className="compact-table__hide-s">
                {action.propertyStand &&
                action.propertyStand.tree_summaries &&
                action.propertyStand.tree_summaries.length &&
                action.propertyStand.tree_summaries[0]
                  ? action.propertyStand.tree_summaries[0].age + " v"
                  : "-"}
              </td>
              <td className="text-align-right">
                {formatCurrency(
                  action.operationType === "treatment"
                    ? action.operation.cost * -1
                    : action.operation.income
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Features = ({ featuresData }) => {
  if (featuresData && featuresData.length > 0) {
    return (
      <table className="compact-table">
        <thead>
          <tr>
            <th>Tyyppi</th>
            <th>Kuvionumero</th>
            <th>Luontokohteen tyyppi</th>
          </tr>
        </thead>
        <tbody>
          {featuresData.map((feature, index) => {
            return (
              <tr key={index}>
                <td>{feature.type}</td>
                <td>{feature.identifier}</td>
                <td>
                  {feature.name}
                  <div className="color-gray-darken-1">
                    {feature.description}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    return (
      <div className="text-align-center size-1-25 color-gray-darken-1 padding-4 padding-y">
        Palvelu ei metsävaratiedon perusteella tunnistanut lakisääteisiä tai
        Metso-ohjelmaan soveltuvia luontokohteita.
      </div>
    );
  }
};

const customSort = (rows, field, direction) => {
  const handleField = row => {
    if (field === "identifier") {
      return parseInt(row[field]);
    } else if (
      field === "area" ||
      field === "averageLength" ||
      field === "pulpWoodTotal" ||
      field === "sawWoodTotal" ||
      field === "treeVolume"
    ) {
      return parseFloat(row[field]);
    }

    return row[field].toString();
  };

  return orderBy(rows, handleField, direction);
};

const getDevelopmentClass = developmentClassText => {
  if (developmentClassText.length && developmentClassText.length > 2) {
    return developmentClassText.substring(0, 2);
  } else {
    return developmentClassText;
  }
};

const getMainTreeSpecies = treeSpecies => {
  const max = treeSpecies.reduce(function(prev, current) {
    return prev.percentage > current.percentage ? prev : current;
  });
  return max.name;
};

const getWoodTotal = woodSummaries => {
  const result = woodSummaries.reduce(function(prev, current) {
    return prev + current.volume;
  }, 0);
  return result;
};

export default PropertyStands;
