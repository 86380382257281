import { Modal } from "@op/opds.opux-modal";
import { Button } from "@op/opds.opux";
import React, { useState, useCallback } from "react";

// Open state is defined by the parent component and passed down to the Modal
export default function OpuxModal(
  {
    title = "",
    content = "",
    action = () => {},
    modalOpen = false,
    setModalOpen = useState(false)[1],
    setModalAccepted = useState(false)[1]
  }) {

  // Handle the wanted positive action
  function yesAction() {
    setModalOpen(false);
    setModalAccepted(true);

    action();
  }

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <div>
      <Modal
        className="opuxModal"
        appElement={document.getElementById("root-element")}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}>
            <Button compact onClick={closeModal}>
              Peruuta
            </Button>
            <Button compact onClick={yesAction}>
              Jatka
            </Button>
          </div>
        }
        isOpen={modalOpen}
        title={title}
        titleTagName="h4">
        <div>
          <p>{content}</p>
        </div>
      </Modal>
    </div>
  );
}