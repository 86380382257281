import React, { Component } from "react";
import PropTypes from "prop-types";
import { ResponsiveContainer, PieChart, Pie } from "recharts";

const DONE_COLOR = "#ff6a10";
const TOTAL_COLOR = "#dcdcdc";

class CounterPieChart extends Component {
  pieData() {
    return [
      { value: this.props.done, fill: DONE_COLOR },
      { value: this.props.total - this.props.done, fill: TOTAL_COLOR }
    ];
  }

  render() {
    return (
      <div className="counter-pie-chart">
        <ResponsiveContainer height={140}>
          <PieChart height={140}>
            <Pie
              startAngle={450}
              endAngle={90}
              data={this.pieData()}
              cx="50%"
              cy="50%"
              innerRadius={58}
              outerRadius={70}
              labelLine={false}
              label={false}
            />
          </PieChart>
        </ResponsiveContainer>
        <h3 className="product-progress-box__numbers size-1-5">
          <span className="color-primary">{this.props.done}</span>
          <span className="color-gray">/{this.props.total}</span>
        </h3>
      </div>
    );
  }
}

CounterPieChart.propTypes = {
  total: PropTypes.number.isRequired,
  done: PropTypes.number.isRequired
};

export default CounterPieChart;
