import $ from "jquery";

$(document).on("turbolinks:load", () => {
  $("[data-role='validate-forest-id-format']").on("keyup", e => {
    let valid = /^[0-9]{1,3}-[0-9]{1,3}-[0-9]{1,3}-[0-9]{1,3}$/.test(e.target.value);
    if (e.target.dataset.nullallowed === "true" && e.target.value === "") {
      valid = true;
    }
    if (valid || e.target.value === "") {
      $(e.target)
        .closest(".input")
        .removeClass("input--has-error");
    } else {
      $(e.target)
        .closest(".input")
        .addClass("input--has-error");
    }

    $(e.target)
      .closest("form")
      .find("input:submit[data-role='other-identifier-form-submit']")
      .prop("disabled", !valid);

    if (valid && e.keyCode === 13) {
      $(e.target)
        .closest("form")
        .submit();
    }
  });

  $(
    "[data-role='other-identifier-form-submit'], [data-role='toggle-loading-indicator']"
  ).on("click", e => {
    e.preventDefault();
    $(e.target)
      .closest("form")
      .find(".code-search__error")
      .hide();
    $(e.target)
      .closest("form")
      .find("[data-role='loading-spinner-wrapper']")
      .show();
    $(e.target)
      .closest("form")
      .find(".input-error")
      .hide();
    $(e.target)
      .closest("form")
      .submit();
  });

  $("[data-role='continue-to-payment']").on("click", e => {
    e.preventDefault();
    $(e.target)
      .closest("form")
      .find("#forest_valuation_continue_to_payment")
      .val(true);
    $(".edit_forest_valuation").submit();
  });
});
