import React, { Component } from "react";
import PropTypes from "prop-types";
import request from "../../request";
import IncomeSlip from "./IncomeSlip";
import ExpenseSlip from "./ExpenseSlip";
import TravelSlip from "./TravelSlip";
import { formatCurrencyWithDecimal } from "../../helpers/currency";
import ForestGroupSelect from "./ForestGroupSelect";

class TransactionSlips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slips: this.props.transactionSlips,
      activeSlips: this.props.transactionSlips,
      activeTab: "all",
      activeGroup: this.props.activeGroup
    };
  }

  renderTransactionSlips() {
    if (this.state.slips.length > 0) {
      if (this.state.activeSlips.length > 0) {
        return this.state.activeSlips.map(slip => {
          switch (slip.transactionType) {
            case "income":
              return <IncomeSlip key={"income_" + slip.id} slip={slip} />;
            case "expense":
              return <ExpenseSlip key={"expense_" + slip.id} slip={slip} />;
            case "travel":
              return <TravelSlip key={"travel_" + slip.id} slip={slip} />;
            default:
              console.log("Unhandled transaction slip type");
          }
        });
      } else {
        switch (this.state.activeTab) {
          case "income":
            return (
              <div className="list-links__item color-gray-darken-1">
                <div className="grow text-align-center padding-1 padding-y">
                  Tässä veromuistiossa ei ole tulotositteita
                </div>
              </div>
            );
          case "expense":
            return (
              <div className="list-links__item color-gray-darken-1">
                <div className="grow text-align-center padding-1 padding-y">
                  Tässä veromuistiossa ei ole menotositteita
                </div>
              </div>
            );
          case "travel":
            return (
              <div className="list-links__item color-gray-darken-1">
                <div className="grow text-align-center padding-1 padding-y">
                  Tässä veromuistiossa ei ole ajopäiväkirjamerkintöjä
                </div>
              </div>
            );
          default:
            console.log("Unhandled transaction slip type");
        }
      }
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="list-links__item">
                <div className="grow text-align-center padding-1 padding-y color-gray-darken-1 child-margins-y-0-5">
                  <p>Tässä veromuistiossa ei ole tapahtumia.</p>
                  <p>
                    Tallenna tositteita{" "}
                    <span className="color-gray-darken-2">Lisää tosite</span>{" "}
                    valikon kautta.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  handleTabChange(newActiveTab) {
    let newActiveSlips;
    if (newActiveTab === "all") {
      newActiveSlips = this.state.slips;
    } else {
      newActiveSlips = this.state.slips.filter(
        slip => slip.transactionType === newActiveTab
      );
    }
    this.setState({ activeTab: newActiveTab, activeSlips: newActiveSlips });
  }

  handleGroupChange(e) {
    const taxationYearId = parseInt(e.target.value);
    let newActiveGroup = this.props.taxationYears.find(
      item => item.id === taxationYearId
    );
    request.fetchTransactionSlips(taxationYearId).then(slips => {
      this.setState({
        activeGroup: newActiveGroup,
        slips: slips,
        activeSlips: slips,
        activeTab: "all"
      });
    });
  }

  renderTaxationYears() {
    let options = this.props.taxationYears.map(item => {
      return (
        <option key={`group_${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });
    return (
      <ForestGroupSelect
        label="Veromuistio"
        value={this.state.activeGroup.id}
        onChange={this.handleGroupChange.bind(this)}
        getLabel={() => this.state.activeGroup.name}
      >
        {options}
      </ForestGroupSelect>
    );
  }

  filterSlipsByType(type) {
    return this.state.slips.filter(item => item.transactionType === type);
  }

  incomeSlips() {
    return this.filterSlipsByType("income");
  }

  expenseSlips() {
    return this.filterSlipsByType("expense");
  }

  sumAmounts(array) {
    return array.reduce((a, b) => a + b, 0);
  }

  renderSummary() {
    return (
      <div>
        <div className="padding-0-75 padding-bottom-0">
          <div className="container container--collapse">
            <div className="row center-m text-align-left">
              <div className="col-xs-12 col-m-8 col-xl-5">
                {this.renderTaxationYears()}
              </div>
            </div>
          </div>
        </div>
        <div className="padding-0-75">
          <div className="container container--collapse">
            <div className="row center-m text-align-left">
              <div className="col-xs-12 col-m-8 col-xl-5">
                <div className="list-links list-links--tile">
                  <a
                    className="list-links__item"
                    href={`/vero/groups/${
                      this.state.activeGroup.fhgId
                    }/taxation_years/${this.state.activeGroup.year}`}
                    data-native-action="pushReportScreen"
                    data-native-title={`${this.state.activeGroup.name}`}
                  >
                    <div>
                      <i className=" opux-icon opux-icon-circled opux-icon-list2 color-primary size-1-5" />
                    </div>
                    <div className="size-1 grow color-gray-darken-2">
                      Näytä erittely veroilmoitusta varten
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTabs() {
    return (
      <div className="item-list-container">
        <div className="container container--collapse">
          <div className="row center-m">
            <div className="col-xs-12 col-m-8 col-xl-5">
              <div className="mobile-tabs">
                <div
                  className={`mobile-tabs__item ${
                    this.state.activeTab === "all"
                      ? "mobile-tabs__item--active"
                      : ""
                  }`}
                  onClick={() => this.handleTabChange("all")}
                >
                  Kaikki
                </div>
                <div
                  className={`mobile-tabs__item ${
                    this.state.activeTab === "expense"
                      ? "mobile-tabs__item--active"
                      : ""
                  }`}
                  onClick={() => this.handleTabChange("expense")}
                >
                  Menot
                </div>
                <div
                  className={`mobile-tabs__item ${
                    this.state.activeTab === "income"
                      ? "mobile-tabs__item--active"
                      : ""
                  }`}
                  onClick={() => this.handleTabChange("income")}
                >
                  Tulot
                </div>
                <div
                  className={`mobile-tabs__item ${
                    this.state.activeTab === "travel"
                      ? "mobile-tabs__item--active"
                      : ""
                  }`}
                  onClick={() => this.handleTabChange("travel")}
                >
                  Matkat
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="grow flex vertical">
        <div>{this.renderSummary()}</div>

        {this.renderTabs()}

        <div className="item-list-container">
          <div className="container container--collapse">
            <div className="row center-m text-align-left">
              <div className="col-xs-12 col-m-8 col-xl-5">
                <div className="item-list item-list--with-tabs">
                  <div className="list-links list-links--with-tabs list-links--chevron">
                    {this.renderTransactionSlips()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TransactionSlips.propTypes = {
  transactionSlips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      transactionDate: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    })
  ).isRequired
};

export default TransactionSlips;
