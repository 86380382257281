import React, {useState, useEffect} from "react";
import request from "../../../request";
import cx from "classnames";

import EvaluatorSelect from "../EvaluatorSelect/EvaluatorSelect";
export default function AssignmentHeader({orderToken, orderData}) {
  const [evaluators, setEvaluators] = useState([]);
  const [assignedEvaluatorId, setAssignedEvaluatorId] = useState(null);
  const [assignedToCurrentUser, setAssignedToCurrentUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  function handleGetEvaluatorData() {
    request.forestValuationEvaluatorData(orderToken)
      .then(response => {
        setAssignedToCurrentUser(response.data.assigned_to_current_user);
        setEvaluators(response.data.evaluator_selection.options);
        setAssignedEvaluatorId(response.data.evaluator_selection.selected);
      })
      .catch(() => {
        setErrorMessage("Arvioijan tietoja ei saatu haettua");
      });
  }

  useEffect(() => {
    handleGetEvaluatorData();
  }, []);

  const assignedEvaluator = evaluators.find(evaluator => evaluator.id === assignedEvaluatorId);

  const noOneAssigned = !assignedEvaluator;

  function content() {
    if (orderData.hasOwnProperty("current_user_can_process")) {
      if (orderData.current_user_can_process) {
        return (
          <div className={"assigned"}>
            <div className={"assigned__name"}>
              <h2 className={"size-1-25 weight-500"}>
                {noOneAssigned
                  ? "Ei arvioijaa"
                  : `Arviointia tekee: ${assignedEvaluator && assignedEvaluator.name}`
                }
              </h2>
            </div>
            {errorMessage && <div className={"assigned__name"}>
              {errorMessage}
            </div>}
            <EvaluatorSelect
              evaluators={evaluators}
              assignedEvaluatorId={assignedEvaluatorId}
              orderToken={orderToken}
              reloadEvaluator={handleGetEvaluatorData}
              setErrorMessage={setErrorMessage}
            />
          </div>
        );
      } else {
        return (
          <div className={"notification notification--alert"}>
            <div className={"notification__icon"}>
              <i className={"opux-icon opux-icon-error"}/>
            </div>
            <div className={"notification__body child-margins-y-0-25"}>
              <div className={"notification__content"}>
                <p>Olet arvioinut tässä tilauksessa olevan kiinteistön jo 2 kertaa putkeen. Jonkun toisen täytyy
                  suorittaa arvio tällä kertaa</p>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  return (
    <div>
      <section className={cx("assignment-header", {"assignment-header--warning": assignedToCurrentUser})}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-xs-12"}>
              {content()}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
