import $ from "jquery";

const makeRequest = (url, data, method) => {
  return $.ajax({
    url,
    method,
    data,
    headers: {
      "X-CSRF-Token": $("meta[name=\"csrf-token\"]").attr("content"),
    },
  });
};

const request = {
  reportValidity: (identifier) => {
    const urlParams = new URLSearchParams(window.location.search);
    const locale = urlParams.get("locale") || "fi";
    return makeRequest(`/admin/vakuusarvoija/forest_valuation_items/check_report_validity?forest_identifier=${identifier}&locale=${locale}`, {}, "GET");
  },

  wealthManagementReportValidity: (identifier) => {
    const urlParams = new URLSearchParams(window.location.search);
    const locale = urlParams.get("locale") || "fi";
    return makeRequest(`/admin/wealth_manager/reports/check_report_validity?forest_identifier=${identifier}&locale=${locale}`, {}, "POST");
  },

  realEstate: id => {
    return makeRequest("/search", { query: id }, "GET");
  },

  updateProperty: (property, payload) => {
    return makeRequest(
      `/properties/${property.slug}`,
      { property: payload },
      "PATCH"
    );
  },

  newsletterSignup: payload => {
    return makeRequest(
      "/newsletter_signups",
      { newsletter_signup: payload },
      "POST"
    );
  },

  markTransferViewed: transferId => {
    return makeRequest("/transfers/" + transferId + "/mark_viewed", {}, "PUT");
  },
  fetchTransactionSlips: taxationYearId => {
    return makeRequest(`/vero/transactions?ty_id=${taxationYearId}`, {}, "GET");
  },
  getS3SignedUrl: payload => {
    return makeRequest("/vero/transactions/sign", payload, "GET");
  },

  userEmailAddress: emailAddress => {
    return makeRequest(
      "/api/v1/user_email_address",
      { email: emailAddress },
      "GET"
    );
  },

  userRegistration: payload => {
    return makeRequest(
      "/api/v1/register",
      payload,
      "POST"
    );
  },

  createPropertySearch: payload => {
    return makeRequest(
      "/property_searches",
      payload,
      "POST"
    );
  },

  forestValuationOrderData: id => {
    return makeRequest("/api/v1/forest_valuations/order_data", { id: id }, "GET");
  },

  forestValuationEvaluatorData: id => {
    return makeRequest("/api/v1/forest_valuations/evaluator_data", { id: id }, "GET");
  },

  forestValuationItemData: id => {
    return makeRequest("/api/v1/forest_valuation_items/item_data", {id: id}, "GET");
  },

  forestValuationItemDraftLink: id => {
    return makeRequest("/api/v1/forest_valuation_items/draft_link", {id: id}, "GET");
  },

  forestValuationItemDraftReload: id => {
    return makeRequest("/api/v1/forest_valuation_items/init_draft_fetch", {id: id}, "PUT");
  },

  forestValuationItemDocumentLink: id => {
    return makeRequest("/api/v1/forest_valuation_items/document_link", {id: id}, "GET");
  },

  forestValuationUpdate: payload => {
    return makeRequest("/api/v1/forest_valuations", payload, "PUT");
  },

  forestValuationItemUpdate: payload => {
    return makeRequest("/api/v1/forest_valuation_items", payload, "PUT");
  },

  forestValuationItemUpdateProcessableState: payload => {
    return makeRequest("/api/v1/forest_valuation_items/update_processable_state", payload, "PUT");
  },

  forestValuationItemDestroy: id => {
    return makeRequest("/api/v1/forest_valuation_items", {id: id}, "DELETE");
  },

  forestValuationItemReturn: id => {
    return makeRequest("/api/v1/forest_valuation_items", {id: id}, "PUT");
  },

  forestValuationFinish: id => {
    return makeRequest("/api/v1/forest_valuations/finish", {id: id}, "PUT");
  },

  forestValuationResendReceipt: id => {
    return makeRequest("/api/v1/forest_valuations/resend", {id: id}, "POST");
  },

  propertyNameUpdate: (payload, requestUrl) => {
    return makeRequest(requestUrl, payload, "PATCH");
  }
};

export default request;
