import React from "react";

export default function ReturnedStatus({orderData}) {
  const orderReturned = () => {
    return orderData.payment_status == "returned";
  };

  const allItemsReturned = () => {
    return orderData.item_ids.length == orderData.returned_items_count;
  };

  const someItemsReturned = () => {
    return orderData.returned_items_count > 0;
  };

  const onlyOneItem = () => {
    return orderData.item_ids.length == 1;
  };

  const markAsReturned = () => {
    console.log("MARKING AS RETURNED!");
  };

  const returnedContent = () => {
    if (orderReturned()) {
      return (
        <div className={"tile margin-1 margin-top padding-0-5"}>
          <div className={"color-red text-align-center"}>Peruutettu: {orderData.returned_at}</div>
        </div>
      );
    } else if (allItemsReturned()) {
      return (
        <div className={"tile margin-1 margin-top padding-0-5"}>
          <div className={"color-red text-align-center"}>Tuotteet peruutettu</div>
        </div>
      );
    } else if (someItemsReturned()) {
      return (
        <div className={"tile margin-1 margin-top padding-0-5"}>
          <div className={"color-gray-darken-1 text-align-center"}>Osa tuotteista peruutettu</div>
        </div>
      );
    } else if (onlyOneItem()) {
      return (
        <div className={"tile margin-1 margin-top padding-0-5"}>
          <button
            className={"opux-btn opux-btn-primary opux-btn--small"}
            onClick={() => { if (window.confirm("Oletko varma?")) markAsReturned(); }}>Merkkaa tilaus peruutetuksi</button>
        </div>
      );
    }
  };

  return (
    <div>
      {returnedContent()}
    </div>
  );
}
