import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";

import { trackEvent } from "../../helpers/event";
import request from "../../request";
import floatingInput from "../../floatingInput";

import PikaAnalyysiSearch from "./PikaAnalyysiSearch/PikaAnalyysiSearch";
import PikaAnalyysiRegister from "./PikaAnalyysiRegister/PikaAnalyysiRegister";
import PikaAnalyysiResult from "./PikaAnalyysiResult/PikaAnalyysiResult";

class Loading extends Component {
  render() {
    return (
      <div className="code-search-fade__container">
        <section className="hero hero--white flex vertical justify-center">
          <div className="landing-content">
            <div className="row">
              <div className="col-xs-12 child-margins-y-1 text-align-center">
                <div>
                  <span className="opux-loading--large" />
                </div>
                <p className="size-1-25">{`Haetaan kiinteistön ${
                  this.props.query
                } tietoja...`}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class PikaAnalyysi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      forest: null,
      errorMessage: "",
      query: "",
      loading: false,
      accountCreated: false
    };

    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
  }

  componentDidMount() {
    floatingInput(this._root);
  }

  handleSearchSubmit(value) {
    this.setState({
      forest: null,
      query: value,
      disabled: true,
      loading: true,
      errorMessage: ""
    });
    trackEvent("Search", {
      content_category: "Property Search",
      search_string: value
    });
    request
      .realEstate(value)
      .then(response => {
        if (response && !response.error) {
          this.setState({
            errorMessage: null,
            disabled: false,
            loading: false,
            forest: response
          });
        } else {
          this.setState({
            errorMessage: response.error,
            disabled: false,
            loading: false,
            forest: null
          });
        }
      })
      .fail(() => {
        this.setState({
          errorMessage: "Tietoja ei saatu haettua. Yritä myöhemmin uudelleen.",
          disabled: false,
          loading: false,
          forest: null
        });
      });
  }

  resetSearch(e) {
    e.preventDefault();
    this.setState({
      disabled: false,
      forest: null,
      hideContent: false
    });
  }

  renderStartStep() {
    return (
      <section
        ref={c => (this._root = c)}
        className="hero flex vertical justify-center"
        style={{ backgroundImage: `url('${this.props.heroImg}')` }}>
        <div className="container width-100">
          <div className="landing-content">
            <div className="row">
              <div className="col-xs-12 col-m-10 col-xl-8">
                <h1 className="size-2 margin-0-5 margin-bottom color-white">
                  Tunnista metsäsi mahdollisuudet
                </h1>
                <p className="size-1-25 margin-0-75 margin-bottom color-white">
                  OP Metsä kertoo arvion metsäsi puuston arvosta,
                  hakkuumahdollisuuksista, hoitosuosituksista ja laskee metsäsi
                  hiilinielun koon.
                </p>

                {!this.props.disablePropertySearch ? (
                  <PikaAnalyysiSearch
                    disabled={this.state.disabled}
                    errorMessage={this.state.errorMessage}
                    onSubmit={this.handleSearchSubmit}
                  />
                ) : null}

                {!this.props.disablePropertySearch ? (
                  <p className="margin-0-75 margin-top color-white">
                    Hae kiinteistötunnus&nbsp;
                    <a
                      href="http://www.maanmittauslaitos.fi/kiinteistot/tietoja-kiinteistoista/kiinteistotunnuksen-selvittaminen"
                      className="nowrap"
                      target="_blank"
                      rel="noopener noreferrer">
                      kartalta
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  userSignedIn() {
    return (
      this.props.currentUser &&
      this.props.currentUser.email &&
      this.props.currentUser.email.length > 0
    );
  }

  render() {
    const { forest, loading } = this.state;

    const registrationCompleted = this.userSignedIn();

    const hideContent = forest || loading;

    if (hideContent) {
      document.getElementById("landing-content").classList.add("hidden");
    } else {
      document.getElementById("landing-content").classList.remove("hidden");
    }

    return (
      <div className="relative">
        <CSSTransition
          timeout={{ enter: 300, exit: 300 }}
          classNames="code-search-fade"
          in={!loading && !forest}
          unmountOnExit>
          {this.renderStartStep()}
        </CSSTransition>

        <CSSTransition
          timeout={{ enter: 300, exit: 300 }}
          classNames="code-search-fade"
          in={loading}
          unmountOnExit
          mountOnEnter>
          <Loading forest={this.state.forest} query={this.state.query} />
        </CSSTransition>

        <CSSTransition
          timeout={{ enter: 300, exit: 300 }}
          classNames="code-search-fade"
          unmountOnExit
          mountOnEnter
          in={!!(forest)}>
          <PikaAnalyysiResult
            forest={forest}
            resetSearch={this.resetSearch}
          />
        </CSSTransition>

        <CSSTransition
          timeout={{ enter: 300, exit: 300 }}
          classNames="code-search-fade"
          unmountOnExit
          mountOnEnter
          in={
            !this.userSignedIn() &&
            forest &&
            !registrationCompleted
          }>
          <PikaAnalyysiRegister
            query={this.state.query}
            forest={this.state.forest}
            resetSearch={this.resetSearch}
            onSubmit={({ accountCreated, email }) => {
              request.createPropertySearch({
                query: this.state.query,
                email
              });
              this.setState({ accountCreated });
            }}
          />
        </CSSTransition>
      </div>
    );
  }
}

export default PikaAnalyysi;
