import React, {useState, useEffect} from "react";
import Select, { components } from "react-select";

export default function BankAgentSelect({agents = null}) {
  const [selectedAgent, setSelectedAgent] = useState(null);

  function handleBankAgentSelect(value) {
    setSelectedAgent(value);
  }

  useEffect(() => {
    let hiddenRailsInput = document.querySelector("#forest_valuation_requester_id");
    hiddenRailsInput.setAttribute("value", selectedAgent?.value);
  }, [selectedAgent]);

  return (
    <div className="child-margins-y-1">
      <div>
        <div className="medium margin-0-25 margin-bottom">{selectedAgent ? selectedAgent.name : "Valitse OP-käyttäjä"}</div>
        <Select
          isClearable
          isMulti={false}
          options={agents}
          className="react-select-container"
          classNamePrefix="react-select"
          components={{ Option }}
          placeholder={"Hae nimellä"}
          noOptionsMessage={() => "Ei hakutuloksia"}
          onChange={handleBankAgentSelect}
        />
      </div>
    </div>
  );
}

const Option = props => {
  return (
    <components.Option {...props}>
      {props.data.label}
      <div className="size-0-75 color-gray-darken-1">{props.data.subtitle}</div>
    </components.Option>
  );
};

