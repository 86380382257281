import React from "react";
import cx from "classnames";

export function Tile({title, className, children}) {
  return (
    <div className={cx("tile", className)}>
      {title && <h2 className="tile__title">
        {title}
      </h2>}

      {children}
    </div>
  );
}

export function TileContent({children}) {
  return (
    <div className="tile__content">
      {children}
    </div>
  );
}

export function TileLink({title, subtitle, component, ...otherProps}) {
  const Wrapper = component || "a";
  return (
    <Wrapper {...otherProps} className="tile-link">
      <div className="tile-link__content">
        <div className="tile-link__title">
          {title}
        </div>
        {subtitle && <div className="tile-link__subtitle">
          {subtitle}
        </div>}
      </div>
      <i className="tile-link__icon opux-icon opux-icon-chevron-r" />
    </Wrapper>
  );
}
