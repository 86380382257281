export function internalEditLayerDefinitions({ url, mapToken }) {
  // "https://tiletest.bag-test.com/geodata/common/wmts"
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhIjoiT1BfSW50ZXJuYWwiLCJ1Ijoib3BfaW50ZXJuYWwiLCJsIjpbMCwxLDIsMyw2LDksMTAsMzksNDAsNTMsNTQsNTUsNTYsNTcsNTgsMTAyLDEwMywxMDQsMTA1LDEwNiwxMDcsMTA4LDExMiwxMTYsMjU3LDI2OSwyNzAsMjc0LDMxOCw0MzAsNDMxLDQzMiw0MzMsNDg5XX0.BzuPhpHF9UMUTQe5cHPtbYhKotXxOz61Im_iixeNvTc"
  //
  // "https://tiles.bitcomp.com/geodata/common/wmts"
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhIjoiT1BfSW50ZXJuYWwiLCJ1Ijoib3BfaW50ZXJuYWwiLCJsIjpbMCwxLDIsMyw2LDksMTAsMzksNDAsNTMsNTQsNTUsNTYsNTcsNTgsMTAyLDEwMywxMDQsMTA1LDEwNiwxMDcsMTA4LDExMiwxMTYsMjU3LDI2OSwyNzAsMjc0LDMxOCw0MzAsNDMxLDQzMiw0MzMsNDg5XX0.BzuPhpHF9UMUTQe5cHPtbYhKotXxOz61Im_iixeNvTc"
  return [
    {
      type: "Group",
      version: 1,
      visible: true,
      name: "geodataGroupMML",
      properties: {
        "geo-layer-group-open": true,
      },
      title: {
        en: "Background maps",
        fi: "Taustakartat",
      },
      layers: [
        {
          type: "WMTS",
          version: 1,
          name: "mmlTaustakarttarasteri",
          title: {
            en: "Background map",
            fi: "Taustakartta",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; MML",
          layer: "mml_taustakarttarasteri",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "mml_taustakarttayhdistelma",
          title: {
            en: "Custom background map",
            fi: "Taustakartta (yhdistelmä)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; MML",
          layer: "mml_taustakarttayhdistelma",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "mmlMaastokarttarasteri",
          title: {
            en: "Topographical map",
            fi: "Maastokartta",
          },
          visible: true,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; MML",
          layer: "mml_maastokarttarasteri",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "mmlOrtokuvaRgb",
          title: {
            en: "Orthophotograph (RGB)",
            fi: "Ortokuva ",
          },
          visible: true,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: 60000,
          attributions: "&copy; MML",
          layer: "mml_orto_rgb",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "mmlOrtokuvaVaaravari",
          title: {
            en: "Ortophotograph (False colour)",
            fi: "Ortokuva (Vääräväri)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: 60000,
          attributions: "&copy; MML",
          layer: "mml_orto_false_color",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          layerId: 5,
          name: "mmlHillshade",
          title: {
            en: "MML Hillshade",
            fi: "Rinnevarjostus",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          attributions: "&copy; MML",
          layer: "mml_hillshade",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "cds_fi_sentinel_rgb",
          title: {
            en: "Satellite",
            fi: "Satelliitti",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          layer: "cds_fi_sentinel_rgb",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "date",
                title: "Kuvauspäivä",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "cds_fi_sentinel_false_color",
          title: {
            en: "Satellite, false color",
            fi: "Satelliitti, vääräväri",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          layer: "cds_fi_sentinel_false_color",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "date",
                title: "Kuvauspäivä",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "cds_fi_sentinel_rgb_all",
          title: {
            en: "Satellite (All year round))",
            fi: "Satelliitti (Koko vuoden päivittyvä)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          layer: "cds_fi_sentinel_rgb_all",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "date",
                title: "Kuvauspäivä",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "cds_fi_sentinel_false_color_all",
          title: {
            en: "Satellite, false color (All year round)",
            fi: "Satelliitti, vääräväri (Koko vuoden päivittyvä)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          layer: "cds_fi_sentinel_false_color_all",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "date",
                title: "Kuvauspäivä",
              },
            ],
          },
        },
      ],
    },
    {
      type: "WMTS",
      version: 1,
      name: "mki_forestusedeclaration",
      title: {
        en: "Forest use declarations",
        fi: "Metsänkäyttöilmoitukset",
      },
      visible: true,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      attributions: "&copy; Metsäkeskus",
      layer: "mki_forestusedeclaration",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "standnumber",
            title: "Kuvionumero",
          },
          {
            field: "forestusedeclarationnumber",
            title: "Ilmoitusnro.",
          },
          {
            field: "processingareanumber",
            title: "Käsittelyaluenro.",
          },
          {
            field: "cuttingpurposetext",
            title: "Hakkuun tarkoitus",
          },
          {
            field: "cuttingrealizationpracticetext",
            title: "Hakkuutapa",
          },
          {
            field: "standarrivaldate",
            title: "Saapumispäivämäärä",
          },
          {
            field: "standarrivalmethodtext",
            title: "Ilmoitustapa",
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "chm_1m",
      title: {
        en: "Canopy height model (1m)",
        fi: "Puuston korkeusmalli (1m)",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      layer: "chm_1m",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "korkeus_m",
            title: "Puuston korkeus (m)",
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "chm_2m",
      title: {
        en: "Canopy height model (2m)",
        fi: "Puuston korkeusmalli (2m)",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      layer: "chm_2m",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "korkeus_m",
            title: "Puuston korkeus (m)",
          },
        ],
      },
    },
    {
      type: "Group",
      version: 1,
      visible: true,
      name: "GeodataGroupAvoinkuviodataTheme",
      properties: {
        "geo-layer-group-open": true,
      },
      title: {
        en: "Compartment theme maps",
        fi: "Metsikkökuvioiden teemakartat",
      },
      layers: [
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_stand",
          title: {
            en: "Forest compartments",
            fi: "Metsikkökuviot",
          },
          visible: true,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_stand",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "realestatetext",
                title: "Kiinteistötunnus",
              },
              {
                field: "standnumber",
                title: "Kuvionumero",
              },
              {
                field: "maingrouptext",
                title: "Pääryhmä",
              },
              {
                field: "subgrouptext",
                title: "Alaryhmä",
              },
              {
                field: "fertilityclasstext",
                title: "Kasvupaikkaluokka",
              },
              {
                field: "drainagestatetext",
                title: "Kuivatustilanne",
              },
              {
                field: "ditchingyear",
                title: "Ojitusvuosi",
              },
              {
                field: "developmentclasstext",
                title: "Kehitysluokka",
              },
              {
                field: "maintreespeciestext",
                title: "Pääpuulaji",
              },
              {
                field: "accessibilitytext",
                title: "Korjuukelpoisuus",
              },
              {
                field: "treestandtypetext",
                title: "Puuston tietolähde",
              },
              {
                field: "inventorydate",
                title: "Inventointipäivämäärä",
              },
              {
                field: "meanage",
                title: "Puuston keski-ikä (v)",
              },
              {
                field: "area",
                title: "Pinta-ala (ha)",
              },
              {
                field: "area",
                title: "Metsäala (ha)",
              },
              {
                field: "basalarea",
                title: "Pohjapinta-ala (m2/ha)",
              },
              {
                field: "stemcount",
                title: "Runkoluku (kpl/ha)",
              },
              {
                field: "meandiameter",
                title: "Keskiläpimitta (cm)",
              },
              {
                field: "meanheight",
                title: "Keskipituus (m)",
              },
              {
                field: "volume",
                title: "Tilavuus m3/ha",
              },
              {
                field: "sawlogvolume",
                title: "Tilavuus, tukkipuu m3/ha",
              },
              {
                field: "pulpwoodvolume",
                title: "Tilavuus, kuitupuu m3/ha",
              },
              {
                field: "fellingtext",
                title: "Seuraava hakkuu",
              },
              {
                field: "fellingyear",
                title: "Hakkuuvuosi",
              },
              {
                field: "silviculturaltext",
                title: "Seuraava metsänhoitotoimenpide",
              },
              {
                field: "silviculturalyear",
                title: "Toimenpiteen vuosi",
              },
              {
                field: "specialfeatures",
                title: "Erityiskohteet",
              },
              {
                field: "specialfeaturesadditional",
                title: "Erityiskohteiden lisämääreet",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_standnumber",
          title: {
            en: "Stand numbers",
            fi: "Kuvionumerot",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_standnumber",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_theme_cutting_proposal",
          title: {
            en: "Theme (Cutting proposal)",
            fi: "Teema (Hakkuuehdotus)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_theme_cutting_proposal",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "fellingyear",
                title: "Hakkuuvuosi",
              },
              {
                field: "fellingtext",
                title: "Seuraava hakkuu",
              },
            ],
            "geo-layer-legends": [
              {
                color: "#a6cee3",
                text: "Ylispuiden poisto",
              },
              {
                color: "#b2df8a",
                text: "Ensiharvennus",
              },
              {
                color: "#33a02c",
                text: "Harvennushakkuu",
              },
              {
                color: "#fb9a99",
                text: "Kaistalehakkuu",
              },
              {
                color: "#e31a1c",
                text: "Avohakkuu",
              },
              {
                color: "#fdbf6f",
                text: "Verhopuuhakkuu",
              },
              {
                color: "#ff7f00",
                text: "Suojuspuuhakkuu",
              },
              {
                color: "#cab2d6",
                text: "Siemenpuuhakkuu",
              },
              {
                color: "#cccccc",
                text: "Muu",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_theme_developmentclass",
          title: {
            en: "Theme (Development class)",
            fi: "Teema (Kehitysluokka)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_theme_developmentclass",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "developmentclass",
                title: "Koodi",
              },
              {
                field: "developmentclasstext",
                title: "Selite",
              },
            ],
            "geo-layer-legends": [
              {
                color: "#969696",
                text: "Aukea",
              },
              {
                color: "#addd8e",
                text: "Taimikko, alle 1,3m",
              },
              {
                color: "#41ab5d",
                text: "Taimikko, yli 1,3m",
              },
              {
                color: "#006837",
                text: "Ylispuustoinen taimikko",
              },
              {
                color: "#fed976",
                text: "Nuori kasvatusmetsikkö",
              },
              {
                color: "#fd8d3c",
                text: "Varttunut kasvatusmetsikkö",
              },
              {
                color: "#e31a1c",
                text: "Uudistuskypsä metsikkö",
              },
              {
                color: "#7fcdbb",
                text: "Suojuspuumetsikkö",
              },
              {
                color: "#1d91c0",
                text: "Siemenpuumetsikkö",
              },
              {
                color: "#253494",
                text: "Eri-ikäisrakenteinen metsikkö",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_theme_inventory_year",
          title: {
            en: "Theme (Inventory year)",
            fi: "Teema (Inventointivuosi)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_theme_inventory_year",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "inventoryyear",
                title: "Inventointivuosi",
              },
            ],
            "geo-layer-legends": [
              {
                color: "#4575b4",
                text: ">= 2017",
              },
              {
                color: "#91bfdb",
                text: "2016",
              },
              {
                color: "#e0f3f8",
                text: "2015",
              },
              {
                color: "#ffffbf",
                text: "2014",
              },
              {
                color: "#fee08b",
                text: "2013",
              },
              {
                color: "#fc8d59",
                text: "2012 - 2008",
              },
              {
                color: "#d73027",
                text: "< 2008",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_theme_maintreespecies",
          title: {
            en: "Theme (Main tree species)",
            fi: "Teema (Pääpuulaji)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_theme_maintreespecies",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "maintreespeciestext",
                title: "Pääpuulaji",
              },
            ],
            "geo-layer-legends": [
              {
                color: "#ff7f00",
                text: "Mänty",
              },
              {
                color: "#4daf4a",
                text: "Kuusi",
              },
              {
                color: "#377eb8",
                text: "Koivu",
              },
              {
                color: "#984ea3",
                text: "Muu",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_theme_specialfeature",
          title: {
            en: "Theme (Special feature)",
            fi: "Teema (ETE-kuviot)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_theme_specialfeature",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "specialfeatures",
                title: "Erityiskohteet",
              },
              {
                field: "specialfeaturesadditional",
                title: "Erityiskohteiden lisämääreet",
              },
            ],
            "geo-layer-legends": [
              {
                color: "#FF00FF",
                text: "Metsälain tärkeä elinympäristökuvio",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_theme_volume",
          title: {
            en: "Theme (Volume)",
            fi: "Teema (Tilavuus)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_theme_volume",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "volume",
                title: "Tilavuus",
              },
            ],
            "geo-layer-legends": [
              {
                color: "#ECF8FA",
                text: "0 - 50",
              },
              {
                color: "#CDECE5",
                text: "50 - 100",
              },
              {
                color: "#9BD8C9",
                text: "100 - 150",
              },
              {
                color: "#6AC1A4",
                text: "150 - 200",
              },
              {
                color: "#47AD78",
                text: "200 - 250",
              },
              {
                color: "#2C8B49",
                text: "250 - 300",
              },
              {
                color: "#095829",
                text: ">= 300",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "avoinkuviodata_theme_quality_pine",
          title: {
            en: "Theme (Quality pine)",
            fi: "Teema (Laatumänty)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; Metsäkeskus",
          layer: "avoinkuviodata_theme_quality_pine",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "developmentclasstext",
                title: "Kehitysluokka",
              },
              {
                field: "fertilityclasstext",
                title: "Kasvupaikkaluokka",
              },
              {
                field: "meanheight",
                title: "Keskipituus (m)",
              },
            ],
            "geo-layer-legends": [
              {
                color: "#e7fcdc",
                text: "16 - 19m",
              },
              {
                color: "#a1d99b",
                text: "19 - 22m",
              },
              {
                color: "#238b45",
                text: "22 - 25m",
              },
              {
                color: "#002a0f",
                text: "Yli 25m",
              },
            ],
          },
        },
      ],
    },
    {
      type: "Group",
      version: 1,
      visible: true,
      name: "ParcelGroupMML",
      properties: {
        "geo-layer-group-open": true,
      },
      title: {
        en: "MML property data",
        fi: "MML kiinteistörajat",
      },
      layers: [
        {
          type: "WMTS",
          version: 1,
          name: "mmlParcel",
          title: {
            en: "NLS parcel data",
            fi: "MML kiinteistöraja-aineisto",
          },
          visible: true,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; MML",
          layer: "mml_parcel",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "tpteksti",
                title: "Kiinteistötunnus",
              },
              {
                field: "area",
                title: "Palstan pinta-ala (ha)",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "mml_parcel_polygon",
          title: {
            en: "NLS parcel",
            fi: "MML kiinteistöt (palsta)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; MML",
          layer: "mml_parcel_polygon",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
            "geo-layer-info-fields": [
              {
                field: "tpteksti",
                title: "Kiinteistötunnus",
              },
              {
                field: "area",
                title: "Palstan pinta-ala (ha)",
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "mml_parcel_reference_point",
          title: {
            en: "NLS parcel reference point (NLS)",
            fi: "MML kiinteistöt (tunnuspiste)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; MML",
          layer: "mml_parcel_reference_point",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
          },
        },
        {
          type: "WMTS",
          version: 1,
          name: "mml_parcel_boundary_point",
          title: {
            en: "NLS parcel boundary point",
            fi: "MML kiinteistöt (rajamerkki)",
          },
          visible: false,
          url: url,
          projection: "EPSG:3067",
          minScaleDenominator: null,
          maxScaleDenominator: null,
          attributions: "&copy; MML",
          layer: "mml_parcel_boundary_point",
          matrixSet: "ETRS-TM35FIN-JHS180",
          format: "image/png",
          tileGrid: {
            extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
            resolutions: [
              8192,
              4096,
              2048,
              1024,
              512,
              256,
              128,
              64,
              32,
              16,
              8,
              4,
              2,
              1,
              0.5,
              0.25,
              0.125,
            ],
            tileSize: 256,
          },
          properties: {
            "geo-layer-headers": [
              {
                header: "Bag-Map-Token",
                value: mapToken,
              },
            ],
          },
        },
      ],
    },
    {
      version: 1,
      type: "Vector",
      name: "compartment-vector",
      title: {
        en: "Compartments",
        fi: "Kuviot",
      },
      visible: true,
      opacity: 1,
      minScaleDenominator: null,
      maxScaleDenominator: null,
      format: "GeoJSON",
      projection: "EPSG:3067",
      styles: [
        {
          version: 1,
          title: {
            en: "Compartments",
            fi: "Kuviot",
          },
          stroke: {
            color: "#00ac32",
            width: 3,
          },
          fill: {
            color: "rgba(255,255,255,0.0)",
          },
          text: {
            backgroundFill: {
              color: "#FFF",
            },
            font: "14px sans-serif",
            template: {
              en: "{{properties.identifier}}",
              fi: "{{properties.identifier}}",
            },
          },
        },
      ],
      properties: {
        "geo-layer-type": "overlay",
        "geo-layer-geometry-types": ["Polygon"],
        "geo-layer-info-fields": [],
      },
    },
    {
      version: 1,
      type: "Vector",
      name: "property-vector",
      title: {
        en: "Property",
        fi: "Kiinteistö",
      },
      visible: true,
      opacity: 1,
      minScaleDenominator: null,
      maxScaleDenominator: null,
      format: "GeoJSON",
      projection: "EPSG:3067",
      styles: [
        {
          version: 1,
          title: {
            en: "Property",
            fi: "Kiinteistö",
          },
          stroke: {
            color: "#000000",
            width: 4,
          },
          fill: {
            color: "rgba(255,255,255,0.0)",
          },
        },
      ],
    },
    {
      type: "WMTS",
      version: 1,
      name: "syke_natura_area_all",
      title: {
        en: "Syke: Natura",
        fi: "Syke: Natura",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      attributions: "&copy; SYKE",
      layer: "syke_natura_area_all",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "nimisuomi",
            title: "Nimi",
          },
        ],
        "geo-layer-legends": [
          {
            color: "#73FFE6CC",
            text: "Natura-alue (Kaikki)",
          },
        ],
      },
    },
    {
      type: "WMTS",
      version: 1,
      name: "syke_nature_conservation_area_all",
      title: {
        en: "Syke: Conservation areas",
        fi: "Syke: Suojelualueet",
      },
      visible: false,
      url: url,
      projection: "EPSG:3067",
      minScaleDenominator: null,
      maxScaleDenominator: null,
      attributions: "&copy; SYKE",
      layer: "syke_nature_conservation_area_all",
      matrixSet: "ETRS-TM35FIN-JHS180",
      format: "image/png",
      tileGrid: {
        extent: [50199.4814, 6582464.0358, 761274.6247, 7799839.8902],
        resolutions: [
          8192,
          4096,
          2048,
          1024,
          512,
          256,
          128,
          64,
          32,
          16,
          8,
          4,
          2,
          1,
          0.5,
          0.25,
          0.125,
        ],
        tileSize: 256,
      },
      properties: {
        "geo-layer-headers": [
          {
            header: "Bag-Map-Token",
            value: mapToken,
          },
        ],
        "geo-layer-info-fields": [
          {
            field: "nimi",
            title: "Nimi",
          },
        ],
        "geo-layer-legends": [
          {
            color: "#FFBEBECC",
            text: "Luonnonsuojelualue (Erämaa)",
          },
          {
            color: "#FFBEBECC",
            text: "Luonnonsuojelualue (Valtio)",
          },
          {
            color: "#FFBEBECC",
            text: "Luonnonsuojelualue (Ohjelma)",
          },
          {
            color: "#FFBEBECC",
            text: "Luonnonsuojelualue (Yksityinen)",
          },
        ],
      },
    },
  ];
}
