export function getMunicipalityName(lukeData, metsakeskusData) {
  let municipality = "";

  if (metsakeskusData && metsakeskusData.municipalityNames && metsakeskusData.municipalityNames.find(municipality => municipality.language.trim() === "fi")) {
    municipality = metsakeskusData.municipalityNames.find(municipality => municipality.language.trim() === "fi").description;
  } else if (lukeData && lukeData.municipalityNames && lukeData.municipalityNames.find(municipality => municipality.language.trim() === "fi")) {
    municipality = lukeData.municipalityNames.find(municipality => municipality.language.trim() === "fi").description;
  }

  return municipality;
}
