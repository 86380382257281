import React, {useState, useEffect} from "react";
import classnames from "classnames";
import request from "../../../request";

export default function EvaluatorSelect({ evaluators, orderToken, reloadEvaluator, assignedEvaluatorId, setErrorMessage }) {
  const [selectedEvaluatorId, setSelectedEvaluatorId] = useState("");

  const handleEvaluatorChange = (event) => {
    const evaluatorId = event.target.value;
    setSelectedEvaluatorId(evaluatorId);
  };

  const handleSetNewEvaluator = () => {
    setErrorMessage("");
    const assignTo = selectedEvaluatorId !== "0" ? selectedEvaluatorId : null;
    request.forestValuationUpdate({id: orderToken, forest_valuation: {assigned_to_id: assignTo}})
      .then(response => {
        reloadEvaluator();
      })
      .catch(() => {
        setErrorMessage("Arvioijan vaihtaminen ei onnistunut");
      });
  };

  useEffect(() => {
    if (assignedEvaluatorId) {
      setSelectedEvaluatorId(assignedEvaluatorId);
    }
  }, [assignedEvaluatorId]);

  return (
    <div>
      <div className={"assigned__form"}>
        <div className={"flex child-margins-x-0-5 opux-input--small"}>
          <div>
            <select value={selectedEvaluatorId} onChange={handleEvaluatorChange} className="opux-input-select">
              <option value={"0"}>Valitse</option>
              {evaluators.map(evaluator => (
                <option value={evaluator.id} key={evaluator.id}>{evaluator.name}</option>
              ))}
            </select>
          </div>
          <button
            className={classnames("btn opux-btn opux-btn-square opux-btn--small opux-btn-primary")}
            onClick={handleSetNewEvaluator}>Siirrä arvioitsijalle</button>
        </div>
      </div>
    </div>
  );
}
