import Turbolinks from "turbolinks";
import Rails from "rails-ujs";
import "../vendor/cocoon";
import "../vendor/jquery.dataTables.min";
import "babel-polyfill";
import "../bindPolyfill";
import "whatwg-fetch";
import "../giosg";

import Honeybadger from "honeybadger-js";

if (process.env.NODE_ENV === "production") {
  Honeybadger.configure({
    api_key: "570cad87",
    environment: '<%= ENV["ENVIRONMENT"] %>',
  });
}

Turbolinks.start();
Rails.start();

import "../navigation";
import "../flashMessages";
import "../forestValuationPicker";
import "../transferMessage";
import "../analytics";
import "../opKotiRequestConsent";
import floatingInput from "../floatingInput";
import "../consentForm";
import "../supportButton";
import "../accordion";
import "../propertyNameUpdate";
import { loadComponents, unmountComponents } from "../loadComponents";

$(document).on("turbolinks:before-visit", (event) => {
  if (window.interval) {
    clearInterval(window.interval);
  }
});
$(document).on("turbolinks:load", loadComponents);
document.addEventListener("turbolinks:before-render", unmountComponents);
$(document).on("turbolinks:load", (event) => {
  floatingInput();
  window.fbq && window.fbq("track", "PageView");

  // window.ga && window.ga('set', 'location', event.originalEvent.data.url)
  // window.ga && window.ga('send', 'pageview')

  $(document).on("change", "input[type='file']", (e) => {
    $(e.target).closest(".attachment-wrapper").css("background-image", "none");
    $(e.target)
      .closest(".attachment-wrapper")
      .addClass("attachment-wrapper--hidden");
  });

  $(
    "form#new_forest_valuation .insurance-selection__item, form#new_forest_valuation [data-role='validate-forest-id-format']"
  ).on("change keyup", (e) => {
    let validForestId = /^([0-9]+-){3}[0-9]+$/.test(
      $("[data-role='validate-forest-id-format']").val()
    );
    if ($("input:checked").length > 0 || validForestId) {
      $("input:submit").prop("disabled", false);
    } else {
      $(e.target).closest("input:submit").prop("disabled", true);
    }
  });

  $("[data-role='save-and-continue']").on("click tap", (e) => {
    e.preventDefault();
    $("#transaction_slip_draft").val("false");
    $(e.target).closest("form").submit();
  });

  $(document).on("change", ".file-upload input", (e) => {
    const input = e.target;
    const label = input.previousElementSibling;
    const labelVal = label.innerText;

    let fileName = e.target.value.split("\\").pop();

    if (fileName.length > 0) label.innerText = fileName;
    else label.innerText = labelVal;
  });

  $("#user_accepted_veromuistio_terms, #forest_valuation_accepted_terms").on(
    "change",
    (e) => {
      let checked = $(e.target).is(":checked");
      $(e.target)
        .closest("form")
        .find("input[type='submit']")
        .prop("disabled", !checked);
    }
  );

  var onPaymentPage = $("#submit-payment-form").length !== 0;
  if (onPaymentPage) {
    $("#submit-payment-form").click();
  }

  $("#lindorff_payment_application_type").on("change", (e) => {
    let value = e.target.value;
    switch (value) {
      case "inheritance":
        $(".customer_reference").removeClass("hidden");
        // $(".deceased_person_hetu").removeClass("hidden");
        // $(".customer_hetu").addClass("hidden");
        $(".customer_first_name").addClass("hidden");
        $(".customer_last_name").addClass("hidden");
        break;
      case "other_cession":
        $(".customer_reference").addClass("hidden");
        // $(".deceased_person_hetu").addClass("hidden");
        $(".customer_first_name").removeClass("hidden");
        $("label[for='lindorff_payment_customer_first_name']").text(
          "<%= I18n.t('activerecord.attributes.lindorff_payment.customer_first_name') %>"
        );
        $(".customer_last_name").removeClass("hidden");
        $("label[for='lindorff_payment_customer_last_name']").text(
          "<%= I18n.t('activerecord.attributes.lindorff_payment.customer_last_name') %>"
        );
        // $(".customer_hetu").removeClass("hidden");
        break;
      case "unbillable_collateral":
        $(".customer_reference").addClass("hidden");
        // $(".deceased_person_hetu").addClass("hidden");
        $(".customer_first_name").addClass("hidden");
        $(".customer_last_name").addClass("hidden");
        // $(".customer_hetu").addClass("hidden");
        break;
      case "billable_collateral":
        $(".customer_reference").addClass("hidden");
        // $(".deceased_person_hetu").addClass("hidden");
        $(".customer_first_name").removeClass("hidden");
        $("label[for='lindorff_payment_customer_first_name']").text(
          "Toimeksiantajan etunimi"
        );
        $(".customer_last_name").removeClass("hidden");
        $("label[for='lindorff_payment_customer_last_name']").text(
          "Toimeksiantajan sukunimi"
        );
        // $(".customer_hetu").removeClass("hidden");
        break;
      default:
        console.log("unhandled case");
    }
  });
});

document.addEventListener("turbolinks:before-cache", function () {
  floatingInput();
});

const patchPostMessageFunction = function () {
  var originalPostMessage = window.postMessage;

  var patchedPostMessage = function (message, targetOrigin, transfer) {
    originalPostMessage(message, targetOrigin, transfer);
  };

  patchedPostMessage.toString = function () {
    return String(Object.hasOwnProperty).replace(
      "hasOwnProperty",
      "postMessage"
    );
  };

  window.postMessage = patchedPostMessage;
};
patchPostMessageFunction();

window.triggerNativeAction = (data) => {
  whenPostMessageReady(() => {
    window.postMessage(JSON.stringify(data));
  });
};

window.whenPostMessageReady = (cb) => {
  if (window.postMessage.length === 1) {
    cb();
  } else {
    setTimeout(() => {
      whenPostMessageReady(cb);
    }, 50);
  }
};

window.isNative = () => $("body").hasClass("is-native");

$(function () {
  $("input[type='file']").on("change", (e) => {
    $(e.target).closest(".attachment-wrapper").css("background-image", "none");
    $(e.target)
      .closest(".attachment-wrapper")
      .addClass("attachment-wrapper--hidden");
  });

  $("[data-role='save-and-continue']").on("click tap", (e) => {
    e.preventDefault();
    $("#transaction_slip_draft").val("false");
    $(e.target).closest("form").submit();
  });

  if ($("body").hasClass("is-native")) {
    $(document).on("click", "[data-native-action]", (event) => {
      event.preventDefault();
      var target = $(event.currentTarget);
      var data = {
        action: target.data("nativeAction"),
        url: target.prop("href"),
        title: target.data("nativeTitle"),
        rightButtonUrl: target.data("nativeRightButtonUrl"),
      };
      triggerNativeAction(data);
    });
  }

  $("#transaction_slip_transactionable_attributes_category").on(
    "change",
    (e) => {
      let selectedValue = $(e.target).val();
      let vatLevel = $("#transaction_slip_vat_rate").data("defaultVatLevels")[
        selectedValue
      ];
      $("#transaction_slip_vat_rate").val(vatLevel);
    }
  );
});
