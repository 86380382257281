import React, { Component } from "react";
import PropTypes from "prop-types";
import AttachmentItem from "./AttachmentItem";
import Dropzone from "react-dropzone";

const updateMaterialCollection = (collection, index, key, value) => {
  const updated = { ...collection[index], [key]: value };

  return [
    ...collection.slice(0, index),
    updated,
    ...collection.slice(index + 1)
  ];
};

class AttachmentUpload extends Component {
  constructor(props) {
    super(props);

    this.handleDrop = this.handleDrop.bind(this);
    this.handleNativeBrowse = this.handleNativeBrowse.bind(this);
    this.handleNativeAddFile = this.handleNativeAddFile.bind(this);
    this.state = {
      attachments: props.initialAttachments || []
    };
  }

  componentDidMount() {
    document.addEventListener("message", this.handleNativeAddFile);
  }

  componentWillUnmount() {
    document.removeEventListener("message", this.handleNativeAddFile);
  }

  allowRemovingLastAttachment() {
    return (
      !this.props.initialAttachments ||
      this.props.initialAttachments.length === 0
    );
  }

  handleItemRemove(index) {
    this.setState(current => {
      return {
        ...current,
        attachments: updateMaterialCollection(
          current.attachments,
          index,
          "toBeDestroyed",
          true
        ),
        changed: true
      };
    });
  }

  saveAsDraft() {
    this.handleSave(true);
  }

  redirectToForm() {
    this.handleSave(false);
  }

  materialToJson(material, index) {
    let json = {
      id: material.id,
      name: material.name
    };

    if (material.toBeDestroyed) {
      json._destroy = true;
    }

    // new file
    if (material.filePath) {
      // The file needs to be stringified JSON to work
      json.image = JSON.stringify({
        id: material.filePath.match(/cache\/(.+)/)[1], // we have to remove the prefix part
        storage: "cache",
        metadata: {
          size: material.fileSize,
          filename: material.fileName,
          mime_type: material.fileMimeType
        }
      });
    }

    return json;
  }

  materialsToJson(materials) {
    return materials.map((material, index) => {
      return this.materialToJson(material, index);
    });
  }

  uploadStarted() {
    this.setState({
      uploadInProgress: true
    });
  }

  uploadEnded() {
    this.setState({
      uploadInProgress: false
    });
  }

  // Called when uploading via web
  handleDrop(files, rejectedFiles) {
    if (this.state.uploadInProgress) {
      return;
    }

    let result = Promise.resolve();

    this.uploadStarted();
    files.forEach(file => {
      result = result.then(() => {
        return this.getSignedRequest(file).then(signedUrl => {
          return this.uploadFile(file, signedUrl.fields, signedUrl.url);
        });
      });
    });

    result.then(() => {
      this.uploadEnded();
    });

    return result;
  }

  uploadFile(file, headers, url) {
    var formData = new FormData();

    Object.keys(headers).forEach(function(key) {
      const val = headers[key];
      formData.append(key, val);
    });
    formData.append("file", file);

    return fetch(url, {
      method: "POST",
      body: formData
    }).then(response => {
      if (response.status === 204) {
        let name = file.name.replace(/\.[^.]*$/, "");

        this.addFile({
          name: name,
          fileName: file.name.match(/[^\/\\]+$/)[0],
          filePath: headers.key,
          fileSize: file.size,
          fileMimeType: file.type
        });
      } else {
        alert("Tiedoston lataaminen ei onnistunut.");
      }
    });
  }

  addFile(attachment) {
    this.setState(current => {
      return {
        ...current,
        attachments: (current.attachments || []).concat(attachment),
        changed: true
      };
    });
    this.handleSave(true, false);
  }

  getSignedRequest(file = null) {
    let url = "/vero/images/cache/presign";

    if (file) {
      url += `?filename=${file.name}`;
    }

    return fetch(url, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "same-origin"
    }).then(response => {
      if (response.status === 200) {
        return response.json().then(json => {
          return { fields: json.fields, url: json.url };
        });
      } else {
        alert("Tiedoston lataaminen ei onnistunut.");
      }
    });
  }

  handleSave(draft, redirect = true) {
    this.setState({ saving: true });

    const attachments = this.materialsToJson(this.state.attachments);

    const payload = {
      transaction_slip: {
        draft: draft,
        transaction_type: this.props.transactionType,
        attachments_attributes: attachments
      },
      skip_amount_validation: true
    };

    let url, method;
    if (this.state.transactionSlipId) {
      url = `/vero/transactions/${this.state.transactionSlipId}`;
      method = "PATCH";
    } else {
      url = this.props.url;
      method = this.props.method;
    }

    fetch(url, {
      method: method,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload),
      credentials: "same-origin"
    }).then(response => {
      if (response.status === 200) {
        response.json().then(json => {
          if (redirect) {
            window.location = json.url;
          } else {
            this.setState({
              transactionSlipId: json.id,
              attachments: json.attachments
            });
          }
        });
      } else {
        this.setState({
          saving: false,
          hasError: true
        });
      }
    });
  }

  renderInstructions() {
    if (this.isShowingAttachments()) {
      return "";
    } else {
      return (
        <div className="list-links list-links--tile list-links--content margin-1 margin-bottom">
          <div className="list-links__item">
            <div className="grow">
              <h2 className="size-1-25 margin-0-5 margin-bottom">
                Liitteiden lisääminen
              </h2>
              <div className="child-margins-y-0-5 color-gray-darken-1">
                <p>Ota puhelimen kameralla kuva tai lisää liitetiedosto.</p>
                <p>Tuetut tiedostomuodot: JPG, PNG, PDF</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  handleNativeBrowse(event) {
    event.preventDefault();

    this.uploadStarted();
    return this.getSignedRequest().then(signedUrl => {
      window.triggerNativeAction({
        action: "uploadFile",
        url: signedUrl.url,
        fields: signedUrl.fields
      });
    });
  }

  handleNativeAddFile(event) {
    // let name = file.name.replace(/\.[^.]*$/, "");

    const data = JSON.parse(event.data);
    switch (data["action"]) {
      case "addFile":
        this.uploadEnded();

        this.addFile({
          // name: name,
          // fileName: file.name.match(/[^\/\\]+$/)[0],
          filePath: data["filePath"]
          // previewPath: data["previewPath"],
          // fileSize: file.size,
          // fileMimeType: file.type,
        });
        break;

      case "uploadStarted":
        this.uploadStarted();
        break;

      case "cancelFilePick":
        this.uploadEnded();
        break;
    }
  }

  renderUploadButton() {
    if (window.isNative()) {
      return (
        <div>
          {this.renderInstructions()}
          <div className="text-align-center">
            <button
              disabled={this.state.uploadInProgress}
              className="opux-btn opux-btn--small opux-btn-primary width-100"
              onClick={this.handleNativeBrowse}
            >
              Kuvaa tai lataa tosite
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <Dropzone onDrop={this.handleDrop} className="dropzone">
          {this.renderInstructions()}
          <div className="text-align-center">
            <button
              disabled={this.state.uploadInProgress}
              className="opux-btn opux-btn--small opux-btn-primary width-100"
            >
              Kuvaa tai lataa tosite
            </button>
          </div>
        </Dropzone>
      );
    }
  }

  isShowingAttachments() {
    return this.state.attachments.length > 0 || this.state.uploadInProgress;
  }

  renderUploadArea() {
    return (
      <div
        className={`${
          this.isShowingAttachments() ? "" : "grow"
        } bg-gray-lighten-3 padding-0-75`}
      >
        <div className="width-100">
          <div className="container container--collapse">
            <div className="row center-m text-align-left">
              <div className="col-xs-12 col-m-8 col-xl-5">
                {this.renderUploadButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAttachmentItem(attachment, index, allowRemove = false) {
    if (attachment.toBeDestroyed) {
      return null;
    }
    return (
      <AttachmentItem
        key={index}
        index={index + 1}
        attachment={attachment}
        onRemove={allowRemove ? this.handleItemRemove.bind(this, index) : null}
      />
    );
  }

  visibleAttachments() {
    return this.state.attachments.filter(
      attachment => !attachment.toBeDestroyed
    );
  }

  allowRemovingAttachment(index) {
    return (
      index > 0 ||
      this.visibleAttachments().length > 1 ||
      this.allowRemovingLastAttachment()
    );
  }

  renderAttachmentList() {
    if (this.isShowingAttachments()) {
      return (
        <div className="item-list-container">
          <div className="container container--collapse">
            <div className="row center-m text-align-left">
              <div className="col-xs-12 col-m-8 col-xl-5">
                <div className="item-list">
                  <div className="item-list__heading">
                    <h2 className="size-1-25 color-gray-darken-2">
                      Lisätyt liitteet
                    </h2>
                  </div>
                  <div className="list-links">
                    {this.state.attachments.map((attachment, index) =>
                      this.renderAttachmentItem(
                        attachment,
                        index,
                        this.allowRemovingAttachment(index)
                      )
                    )}
                    {this.renderInProgressUpload(
                      this.state.attachments.length + 1
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div />
        </div>
      );
    }
  }

  savingDisabled() {
    return (
      this.state.uploadInProgress || this.visibleAttachments().length === 0
    );
  }

  renderBottomToolbar() {
    return (
      <div className="mobile-toolbar">
        {this.props.enableDraft ? (
          <button
            disabled={this.savingDisabled()}
            className="opux-btn opux-btn--small"
            onClick={this.saveAsDraft.bind(this)}
          >
            Tallenna
          </button>
        ) : null}
        <button
          disabled={this.savingDisabled()}
          className="opux-btn opux-btn-primary opux-btn--small"
          onClick={this.redirectToForm.bind(this)}
        >
          Jatka
        </button>
      </div>
    );
  }

  renderInProgressUpload(index) {
    if (this.state.uploadInProgress) {
      return <AttachmentItem index={index} loading />;
    }
  }

  render() {
    return (
      <div className="flex vertical grow">
        <div className="grow flex vertical">
          {this.renderUploadArea()}
          {this.renderAttachmentList()}
        </div>
        {this.renderBottomToolbar()}
      </div>
    );
  }
}

AttachmentUpload.propTypes = {
  enableDraft: PropTypes.bool
};

export default AttachmentUpload;
