import React, { Component } from "react";
import PropTypes from "prop-types";

import insuranceShape from "../../../proptypes/Insurance";

import { formatArea } from "../../../helpers/area";

class PropertyDetailsAreaAndCheck extends Component {
  constructor(props) {
    super(props);
  }

  renderArea() {
    const { totalArea, forestryArea, areaTypes } = this.props;
    const areaTypesList = areaTypes.map((item, index) => {
      if (item.area !== 0) {
        return (
          <span
            key={item.id}
            className="size-1 nowrap inline-block color-gray-darken-1"
          >
            {item.name}&nbsp;
            <span className="color-gray-darken-2">{formatArea(item.area)}</span>
          </span>
        );
      }
    });
    return (
      <section className="border-bottom border-color-border">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-l-8 col-l-shift-2">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-s-6 col-s-divider">
                    <div className="padding-1-5 padding-y text-align-center">
                      <ul className="stats margin-auto-x">
                        <li className="stats__item">
                          <span className="stats__label size-1-25 line-height-1">
                            Pinta-ala
                          </span>
                          <span className="stats__value size-1-5">
                            {formatArea(totalArea)}
                          </span>
                        </li>
                      </ul>
                      <p className="color-gray-darken-1">
                        Lue lisätietoa pinta-aloista{" "}
                        <a
                          href="https://tuki.op-metsa.fi"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          täältä
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-s-6 text-align-center-s">
                    <div className="text-align-center padding-1-5 padding-y">
                      <ul className="stats margin-auto-x">
                        <li className="stats__item">
                          <span className="stats__label size-1-25 line-height-1">
                            Metsäala
                          </span>
                          <span className="stats__value size-1-5">
                            {formatArea(forestryArea)}
                          </span>
                        </li>
                      </ul>
                      <div className="child-margins-x-0-5">{areaTypesList}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderOwners() {
    const owners = this.props.owners.join(", ");

    if (owners.length > 0) {
      return (
        <div className="col-xs-12 col-m-6 size-0-75 text-align-center">
          <span className="medium">Omistajat: </span>
          <span className="color-gray-darken-1">{owners}</span>
        </div>
      );
    } else {
      return "";
    }
  }

  renderDataSource() {
    const dataSource = this.props.dataSource;

    return (
      <div className="border-bottom border-color-border padding-0-5 padding-y">
        <div className="container">
          <div className="row center-m">
            <div className="col-xs-12 col-m-6">
              <div className="size-0-75 text-align-center">
                <span className="medium">Tietolähde: </span>
                <span className="color-gray-darken-2">{dataSource} </span>
                <a
                  href="https://tuki.op-metsa.fi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lue lisää
                </a>
              </div>
            </div>
            {this.renderOwners()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section>
        {this.renderDataSource()}
        {this.renderArea()}
      </section>
    );
  }
}
PropertyDetailsAreaAndCheck.propTypes = {
  admin: PropTypes.bool,
  areaTypes: PropTypes.arrayOf(
    PropTypes.shape({
      area: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  totalArea: PropTypes.number.isRequired,
  forestryArea: PropTypes.number.isRequired
};

export default PropertyDetailsAreaAndCheck;
