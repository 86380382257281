import React, {useState, useEffect, useRef, useCallback} from "react";
import request from "../../../../request";

import {Button} from "../../Button/Button";
import OpuxModal from "../../../OpuxModal";
import OpuxFormModal from "../../../OpuxFormModal";

export default function ValuationForm({item, refetchItem, setValuationFormOpen, hasBeenValuated}) {
  const [valueAdjustment, setValueAdjustment] = useState(0);
  const [valueAdjustmentDescription, setValueAdjustmentDescription] = useState("");
  const [correctionPercent, setCorrectionPercent] = useState(0.0);
  const [correctionPercentText, setCorrectionPercentText] = useState("");
  const [canBeProcessed, setCanBeProcessed] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [processableNotice, setProcessableNotice] = useState("");
  const [satelliteCorrection, setSatelliteCorrection] = useState(false);
  const [aerialCorrection, setAerialCorrection] = useState(false);
  const [forestUsageCorrection, setForestUsageCorrection] = useState(false);
  const [otherCorrection, setOtherCorrection] = useState(false);
  const satelliteCheckbox = useRef();
  const aerialCheckbox = useRef();
  const [humanValuationNotPossibleReason, setHumanValuationNotPossibleReason] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAccepted, setModalAccepted] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [formModalAccepted, setFormModalAccepted] = useState(false);

  useEffect(() => {
    setValueAdjustment(item.value_adjustment || 0);
    setValueAdjustmentDescription(item.value_adjustment_description);
    setCorrectionPercent(Number(item.correction_percent || 0.0).toFixed(1));
    setCorrectionPercentText(item.correction_percent_text);
    setCanBeProcessed(item.plus_is_possible);
    setProcessableNotice(item.processable_notice);
    setSatelliteCorrection(item.corrections.satellite);
    setAerialCorrection(item.corrections.aerial);
    setForestUsageCorrection(item.corrections.forest_usage);
    setOtherCorrection(item.corrections.other);
  }, [item]);

  function handleValueAdjustment(event) {
    setValueAdjustment(event.target.value);
  }

  function handleValueAdjustmentDescription(event) {
    setValueAdjustmentDescription(event.target.value);
  }

  function handleCorrectionPercent(event) {
    setCorrectionPercent(event.target.value);
  }

  function handleCorrectionPercentText(event) {
    setCorrectionPercentText(event.target.value);
  }

  function handlehumanValuationNotPossibleReason(event) {
    setHumanValuationNotPossibleReason(event.target.value)
  }

  const updateProcessableState = (reason) => {
    request.forestValuationItemUpdateProcessableState({id: item.id, evaluation: {plus_is_possible: !canBeProcessed, valuation_not_possible_reason: reason}})
      .then(response => {
        refetchItem(item.id);
      })
      .catch(() => {
        setErrorMessage("Tietoja ei saatu haettua.");
      });
  }

  const handleProcessableStateUpdate = () => {
    if (canBeProcessed) {
      setFormModalOpen(true);
    } else {
      updateProcessableState("");
    }
  };

  const handleRequest = () => {
    let payload = {
      id: item.id,
      evaluation: {
        value_adjustment: valueAdjustment,
        value_adjustment_description: valueAdjustmentDescription,
        correction_percent: correctionPercent,
        correction_percent_text: correctionPercentText,
        satellite_image_correction: satelliteCorrection,
        aerial_image_correction: aerialCorrection,
        forest_usage_correction: forestUsageCorrection,
        other_correction: otherCorrection
      }
    };
    request.forestValuationItemUpdate(payload)
      .then(response => {
        if (response.data) {
          refetchItem(item.id);
        } else {
          setErrorMessage(response.error.message);
        }
      })
      .fail(response => {
        setErrorMessage((response.responseJSON && response.responseJSON.error)
          ? response.responseJSON.error : "Tietoja ei saatu haettua. Yritä myöhemmin uudelleen.");
      });
  };

  const handleSubmit = () => {
    if (valueAdjustment > 0 && !modalAccepted) {
      setModalOpen(true);
    } else {
      handleRequest();
    }
  };

  function handleSatelliteCorrectionChange(event) {
    setSatelliteCorrection(event.target.checked);

    let clearText = aerialCheckbox.current.checked == false && satelliteCheckbox.current.checked == false;

    modifyValueAdjustmentDescription(event, "Uudistushakkuut kuviolla", clearText);
  }

  function handleAerialCorrectionChange(event) {
    setAerialCorrection(event.target.checked);

    let clearText = aerialCheckbox.current.checked == false && satelliteCheckbox.current.checked == false;

    modifyValueAdjustmentDescription(event, "Uudistushakkuut kuviolla", clearText);
  }

  function handleForestUsageCorrectionChange(event) {
    setForestUsageCorrection(event.target.checked);

    modifyValueAdjustmentDescription(event, "Metsänkäyttöilmoitus uudistushakkuista kuviolla (mki)");
  }

  function handleOtherCorrectionChange(event) {
    setOtherCorrection(event.target.checked);
  }

  function modifyValueAdjustmentDescription(e, text, clearText = true) {
    if (e.target.checked) {
      if (valueAdjustmentDescription.length === 0) {
        setValueAdjustmentDescription(text);
      } else if (valueAdjustmentDescription.indexOf(text) === -1) {
        setValueAdjustmentDescription(valueAdjustmentDescription + "\n" + text);
      }
    } else if (clearText) {
      let descriptionWithoutTargetText = valueAdjustmentDescription.replace(text, "").trim().replace(/^\s*\n/gm, "");
      setValueAdjustmentDescription(descriptionWithoutTargetText);
    }
  }

  if (canBeProcessed) {
    return (
      <React.Fragment>
        <OpuxModal title="Huom! Kiinteistön arvo kasvaa"
                   content="Olet lisäämässä kiinteistölle arvoa. Haluatko varmasti jatkaa?"
                   action={handleRequest}
                   modalOpen={modalOpen}
                   setModalOpen={setModalOpen}
                   setModalAccepted={setModalAccepted}
        />
        <OpuxFormModal title="Metsäarviota ei voida toteuttaa ja se poistetaan tilaukselta"
                       content=""
                       action={updateProcessableState}
                       modalOpen={formModalOpen}
                       setModalOpen={setFormModalOpen}
                       setModalAccepted={setFormModalAccepted}
        />
        <div className="metsa-arvio-content__top">
          <div className="padding-0-5 border-bottom border-color-border child-margins-y-0-5">
            <label className="weight-500 size-0-75 block">
              <div className="margin-0-125 margin-bottom">Muut arvoon vaikuttavat tekijät yhteensä €</div>
              <input className="opux-input-text" id="value-adjustment-field"
                style={{maxWidth: 100}}
                value={valueAdjustment}
                onChange={handleValueAdjustment}
              />
            </label>

            <label className="weight-500 size-0-75 block">
              <div className="margin-0-125 margin-bottom">Perustelut</div>
              <textarea id="value-adjustment-description"
                className="opux-input-textarea"
                style={{resize: "vertical"}}
                value={valueAdjustmentDescription}
                onChange={handleValueAdjustmentDescription}
              />
            </label>
            <div className="opux-input opux-input-checkbox">
              <input
                type="checkbox"
                name="satelliteCorrection"
                id="satelliteCorrection"
                checked={satelliteCorrection}
                onChange={handleSatelliteCorrectionChange}
                ref={satelliteCheckbox}
              />
              <label htmlFor="satelliteCorrection">
                Satelliittikuva
              </label>
            </div>
            <div className="opux-input opux-input-checkbox">
              <input
                type="checkbox"
                name="aerialCorrection"
                id="aerialCorrection"
                checked={aerialCorrection}
                onChange={handleAerialCorrectionChange}
                ref={aerialCheckbox}
              />
              <label htmlFor="aerialCorrection">
                Ilmakuva
              </label>
            </div>
            <div className="opux-input opux-input-checkbox">
              <input
                type="checkbox"
                name="forestUsageCorrection"
                id="forestUsageCorrection"
                checked={forestUsageCorrection}
                onChange={handleForestUsageCorrectionChange}
              />
              <label htmlFor="forestUsageCorrection">
                Metsänkäyttöilmoitus
              </label>
            </div>
            <div className="opux-input opux-input-checkbox">
              <input
                type="checkbox"
                name="otherCorrection"
                id="otherCorrection"
                checked={otherCorrection}
                onChange={handleOtherCorrectionChange}
              />
              <label htmlFor="otherCorrection">
                Muu
              </label>
            </div>
          </div>
          <div className="padding-0-5 child-margins-y-0-5">
            <label className="weight-500 size-0-75 block">
              <div className="margin-0-125 margin-bottom">Arvonkorjausprosentti %</div>
              <input id="correction-percent"
                className="opux-input-text"
                style={{maxWidth: 100}}
                value={correctionPercent}
                onChange={handleCorrectionPercent}
              />
            </label>
            <label className="weight-500 size-0-75 block">
              <div className="margin-0-125 margin-bottom">Muistiinpanot</div>
              <textarea className="opux-input-textarea"
                value={correctionPercentText}
                onChange={handleCorrectionPercentText}
              />
            </label>
          </div>
        </div>
        <div className="metsa-arvio-content__bottom">
          <div className="metsa-arvio-form-footer">
            {hasBeenValuated && <Button
              onClick={() => setValuationFormOpen(false)}
              size="tiny">
              Peruuta
            </Button>
            }
            <div className="metsa-arvio-form-footer__buttons">
              <Button id="processable-state-btn" size="tiny" color={canBeProcessed ? "alt" : "primary"} onClick={handleProcessableStateUpdate}>
                {canBeProcessed ? "Ei voida toteuttaa" : "Voidaan toteuttaa"}
              </Button>
              <Button
                id="save-valuation-form"
                onClick={handleSubmit}
                color="primary"
                size="tiny">
              Tallenna
            </Button>
            </div>
          </div>
          {errorMessage &&
            <div className="metsa-arvio-link metsa-arvio-link--message">
              <i className="opux-icon opux-icon-alert metsa-arvio-link__icon" />
              {errorMessage}
            </div>
          }
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="metsa-arvio-section text-align-center">
        <div id="unprocessable-notice" className="margin-1 margin-top">
          OP Metsäarvio -tuotetta ei voida toimittaa
        </div>
        <div className="margin-1 margin-top color-primary">
          {humanValuationNotPossibleReason}
        </div>
        <div className="margin-1 margin-top color-primary">
          {processableNotice}
        </div>
        <div id="processable-btn" className="margin-0-5 margin-top">
          <Button size="tiny" onClick={handleProcessableStateUpdate}>
            {"Voidaan toteuttaa"}
          </Button>
        </div>
      </div>);
  }
}
