export const trackEvent = (type, options = {}) => {
  if (window.fbq) {
    if (type === "Search") {
      window.fbq("track", type, {
        search_string: options["search_string"],
        content_category: options["content_category"],
      });
    }
  }

  if (window._satellite && window._satellite.track) {
    window._satellite.track(type, options);
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: type,
      options: options,
    });
  }

  if (CustomEvent && window.dispatchEvent) {
    var event = new CustomEvent(type, options);
    window.dispatchEvent(event);
  }
};
