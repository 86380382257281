import React from "react";
import cx from "classnames";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";

function CustomTab({ isSelected, children, ...props }) {
  return (
    <Tab
      {...props} className={cx("tab", {"tab--active": isSelected})}>
      {children}
    </Tab>
  );
}

function CustomTabList({ children, ...props }) {
  return (
    <TabList
      className={"tab-list"} {...props}>
      {children}
    </TabList>
  );
}

export {Tabs, CustomTabList as TabList, TabPanels, TabPanel, CustomTab as Tab};
