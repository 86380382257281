// prettier-ignore
(function(w, t, f) {
  var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live2/'+f;
  w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);
})(window,document,"700724e6-486f-11ec-86f1-0242ac120018");

let hadConsent = false;
document.addEventListener("turbolinks:request-start", () => {
  let giosgRoot = document.querySelector("#giosg-root");
  if (giosgRoot) {
    giosgRoot.appendChild(document.querySelector("#giosg_chat_now_button"));
  }
  let consent = giosg.tcAPI.getVisitorConsent();
  hadConsent = consent.vendor;
  consent.vendor = false;
  giosg.tcAPI.setVisitorConsent(consent);
});

document.addEventListener("turbolinks:load", () => {
  if (!hadConsent) return;
  let consent = giosg.tcAPI.getVisitorConsent();
  consent.vendor = true;
  giosg.tcAPI.setVisitorConsent(consent);
});
