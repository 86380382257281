import React, { Component } from "react";
import PropTypes from "prop-types";
import request from "../../request";
import { formatArea } from "../../helpers/area";
import { formatCurrency } from "../../helpers/currency";
import update from "immutability-helper";
import _ from "lodash";
import insuranceShape from "../../proptypes/Insurance";

import PropertyCard from "../PropertyCard";

class OwnProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: this.props.properties,
      saving: []
    };

    this.handleChangeProperty = this.handleChangeProperty.bind(this);
  }

  forestInsurancesAmounts() {
    const { properties } = this.state;
    const propertiesSize = properties.length;
    const insuredPropertiesSize = properties.filter(
      property =>
        property.insurance != null && property.insurance.hasActiveInsurance
    ).length;
    return `${insuredPropertiesSize}/${propertiesSize}`;
  }

  forestPlansAmounts() {
    const { properties } = this.state;
    const propertiesSize = properties.length;
    const plansSize = properties.filter(property => property.hasForestPlan)
      .length;
    return `${plansSize}/${propertiesSize}`;
  }

  propertiesArea() {
    return this.state.properties
      .map(p => p.forestryArea || 0)
      .reduce((p, c) => p + c, 0);
  }

  propertiesValue() {
    return this.state.properties
      .map(p => p.totalValue || 0)
      .reduce((p, c) => p + c, 0);
  }

  setSaving(id) {
    return this.state.saving.concat(id);
  }

  removeSaving(id) {
    return _.chain(this.state.saving)
      .without(id)
      .uniq()
      .value();
  }

  handleChangeProperty(property, payload) {
    const { properties } = this.state;
    const idx = _.findIndex(properties, p => p.id === property.id);
    if (idx !== -1) {
      this.setState({ saving: this.setSaving(property.id) });
      request.updateProperty(property, payload).then(newProperty => {
        this.setState({
          saving: this.removeSaving(newProperty.id),
          properties: update(properties, { $splice: [[idx, 1, newProperty]] })
        });
      });
    }
  }

  renderPropertyCards() {
    const { insurances } = this.props;

    return this.state.properties.map(property => {
      return (
        <PropertyCard
          admin={this.props.admin}
          key={property.id}
          saving={this.state.saving.indexOf(property.id) !== -1}
          onChange={this.handleChangeProperty}
          insurances={insurances}
          property={property}
        />
      );
    });
  }

  render() {
    return (
      <div>
        <section className="border-bottom border-color-border padding-0-5 padding-y">
          <div className="container">
            <ul className="stat-grid">
              <li className="stat-grid__item stat">
                <div className="stat__label color-gray-darken-1">
                  Arvioitu metsävarallisuus
                </div>
                <div className="stat__value">
                  {formatCurrency(this.propertiesValue())}
                </div>
              </li>
              <li className="stat-grid__item stat">
                <div className="stat__label color-gray-darken-1">Metsäala</div>
                <div className="stat__value">
                  {formatArea(this.propertiesArea())}
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="properties-table-wrapper">
                  <div className="properties-header">
                    <div className="properties-header__inner">
                      <div className="properties-table__cell">
                        <span className="uppercase size-0-75 letter-spacing color-gray-darken-1">
                          Tila
                        </span>
                      </div>
                      <div className="properties-table__cell properties-table__cell--number properties-table__cell--last">
                        <span className="uppercase size-0-75 letter-spacing color-gray-darken-1">
                          Metsäala / Pinta-ala
                        </span>
                      </div>
                      <div className="properties-table__cell properties-table__cell--number">
                        <span className="uppercase size-0-75 letter-spacing color-gray-darken-1">
                          Tilan arvo
                        </span>
                      </div>
                      <div className="properties-table__cell properties-table__cell--number properties-table__cell--last">
                        <span className="uppercase size-0-75 letter-spacing color-gray-darken-1">
                          Tietolähde
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="properties-table-wrapper">
                  <div className="properties-table">
                    {this.renderPropertyCards()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

OwnProperties.propTypes = {
  admin: PropTypes.bool,
  insurances: PropTypes.arrayOf(insuranceShape).isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      dataSource: PropTypes.string.isRequired,
      forestIdentifier: PropTypes.string.isRequired,
      hasForestPlan: PropTypes.bool,
      id: PropTypes.number.isRequired,
      insurance: insuranceShape,
      municipality: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      seedlingsValue: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      totalArea: PropTypes.number.isRequired,
      totalValue: PropTypes.number.isRequired
    })
  ).isRequired
};

export default OwnProperties;
