import $ from "jquery";

const floatingInput = (container) => {
  if (!container) {
    container = window.document;
  }
  const floatLabelSelector =
    ".floating-label-input input, .floating-label-input textarea";
  const floatLabelParent = ".floating-label-input";
  const floatLabelActiveClass = "floating-label-input--has-value ";
  const floatLabelFocusClass = "floating-label-input--focused ";
  $(floatLabelParent, container).on("tap", function (event) {
    if (!$(this).hasClass(floatLabelFocusClass)) {
      $(this).find("input, textarea").focus();
    }
  });
  $(floatLabelSelector, container).each(function () {
    if (
      $(this).val() !== "" ||
      $(this).attr("placeholder") !== undefined ||
      $(this).attr("type") === "date"
    ) {
      $(this).closest(floatLabelParent).addClass(floatLabelActiveClass);
    }
    if ($(this).is(":focus")) {
      $(this)
        .closest(floatLabelParent)
        .addClass(floatLabelActiveClass + floatLabelFocusClass);
    }
  });
  $(floatLabelSelector, container).on("focus", function (event) {
    $(this)
      .closest(floatLabelParent)
      .addClass(floatLabelActiveClass + floatLabelFocusClass);
  });
  $(floatLabelSelector, container).on("blur", function (event) {
    if (
      $(this).val() === "" &&
      $(this).attr("placeholder") === undefined &&
      $(this).attr("type") !== "date"
    ) {
      $(this).closest(floatLabelParent).removeClass(floatLabelActiveClass);
    }
    $(this).closest(floatLabelParent).removeClass(floatLabelFocusClass);
  });
};

export default floatingInput;
