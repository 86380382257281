import React from "react";
import _ from "lodash";

import { formatCurrency } from "../../../helpers/currency";
import { formatArea } from "../../../helpers/area";
import { formatVolume } from "../../../helpers/volume";
import { formatPercentage } from "../../../helpers/percentage";
import { getMunicipalityName } from "../getMunicipalityName";

import {Tile, TileContent, TileLink} from "../../Tile";
import {Tabs, TabList, TabPanels, TabPanel, Tab} from "../../Tabs";

export default function PikaAnalyysiResult({forest, resetSearch}) {
  if (!forest) {
    return null;
  }

  const lukeData = forest.find(data => { return data.dataSource.toLowerCase().includes("luonnonvarakeskus"); });
  const metsakeskusData = forest.find(data => { return data.dataSource.toLowerCase().includes("suomen metsäkeskus"); });

  return (
    <React.Fragment>
      <BackNavigation resetSearch={resetSearch} lukeData={lukeData} metsakeskusData={metsakeskusData} />
      <section className="padding-1 padding-y ">
        <div className="container">
          <div className="pika-analyysi-grid">
            <TotalValueTile
              lukeData={lukeData} metsakeskusData={metsakeskusData}
            />
            <AreaTile lukeData={lukeData} metsakeskusData={metsakeskusData} />
            <TimberTile lukeData={lukeData} metsakeskusData={metsakeskusData} />
          </div>
        </div>
      </section>
      <DisclaimerSection />
    </React.Fragment>
  );
}

function BackNavigation({ resetSearch, lukeData, metsakeskusData }) {
  const name = metsakeskusData && metsakeskusData.name || lukeData && lukeData.name;
  const municipality = getMunicipalityName(lukeData, metsakeskusData);
  const forestIdentifier = metsakeskusData && metsakeskusData.visibleId || lukeData && lukeData.visibleId;
  return (
    <section className="padding-0-5 padding-y border-bottom border-color-border">
      <div className="container">
        <a
          href="#"
          onClick={resetSearch}
          className="code-search-back">
          <i className="opux-icon opux-icon-chevron-l" />
            Palaa alkuun
          </a>
        <h1 className="size-1-5">Pika-analyysi kohteelle {municipality} ({forestIdentifier})</h1>
      </div>
    </section>
  );
}

function TotalValueTile({lukeData, metsakeskusData}) {
  const forestIdentifier = metsakeskusData && metsakeskusData.visibleId || lukeData && lukeData.visibleId;
  const name = metsakeskusData && metsakeskusData.name || lukeData && lukeData.name;
  const municipality = getMunicipalityName(lukeData, metsakeskusData);
  const totalValueMinMetsakeskus = metsakeskusData && metsakeskusData.priceCalculation && metsakeskusData.priceCalculation[0].totalValueMin;
  const totalValueMinLuke = lukeData && lukeData.priceCalculation && lukeData.priceCalculation[0].totalValueMin;
  const totalValueMaxMetsakeskus = metsakeskusData && metsakeskusData.priceCalculation && metsakeskusData.priceCalculation[0].totalValueMax;
  const totalValueMaxLuke = lukeData && lukeData.priceCalculation && lukeData.priceCalculation[0].totalValueMax;

  const totalValueMin = totalValueMinMetsakeskus || totalValueMinLuke;
  const totalValueMax = totalValueMaxMetsakeskus || totalValueMaxLuke;

  return (
    <Tile title="Arvioitu tilan arvo" className="pika-analyysi-grid__total">
      <TileContent>
        <div className="pika-analyysi-total">
          {formatCurrency(Math.round(totalValueMin / 500) * 500)} – {formatCurrency(Math.round(totalValueMax / 500) * 500)}
        </div>
      </TileContent>
      <form
        noValidate="novalidate"
        className="simple_form new_forest_valuation"
        id="new_forest_valuation"
        action="/metsaarvio"
        acceptCharset="UTF-8"
        method="post">
        <input
          type="text"
          name="forest_valuation[items_attributes][0][forest_identifier]"
          value={forestIdentifier}
          hidden
        />
        <input type="hidden" name="authenticity_token" value={document.querySelector("meta[name='csrf-token']").getAttribute("content")}/>
        <TileLink component="button" type="submit" title={"Tarvitsetko metsäarvion?"} subtitle={"Tilaa OP Metsäarvio nopeasti ja edullisesti"} />
      </form>
      <TileLink href="https://www.op.fi/henkiloasiakkaat/vakuutukset/muut-vakuutukset/metsavakuutus" title={"Kysy tarjous metsävakuutuksesta"} subtitle={"Pohjola metsävakuutus tarpeesi mukaan"} target="_blank" rel="noopener noreferrer" />
      <TileLink href={`${window.location.origin}/op_koti_requests/new?property=${forestIdentifier}&property_name=${name}&municipality=${municipality}`} title={"Harkitsetko metsäsi myymistä?"} subtitle={"Jätä yhteydenottopyyntö OP Kodin asiantuntijoille"} target="_blank" rel="noopener noreferrer" />
    </Tile>
  );
}

function AreaTile({lukeData, metsakeskusData}) {
  const totalArea = metsakeskusData && metsakeskusData.totalArea || lukeData && lukeData.totalArea;
  const totalForestArea = metsakeskusData && metsakeskusData.totalForestArea || lukeData && lukeData.totalForestArea;
  const totalFieldArea = metsakeskusData && metsakeskusData.totalFieldArea || lukeData && lukeData.totalFieldArea;

  const totalAreaRounded = Math.round(totalArea * 10) / 10;
  const totalForestAreaRounded = Math.round(totalForestArea * 10) / 10;
  const totalFieldAreaRounded = Math.round(totalFieldArea * 10) / 10;

  return (
    <Tile title="Pinta-ala" className="pika-analyysi-grid__area">
      <TileContent>
        <div className="pika-analyysi-details pika-analyysi-details--area">
          <div className="pika-analyysi-details__item">
            <h4 className="value-box__label">Kokonaisala</h4>
            <div className="value-box__value">
              -
            </div>
          </div>
          <div className="pika-analyysi-details__item">
            <h4 className="value-box__label">Metsäala</h4>
            <div className="value-box__value">
              {formatArea(totalForestAreaRounded)}
            </div>
          </div>
          <div className="pika-analyysi-details__item">
            <h4 className="value-box__label">Peltoala</h4>
            <div className="value-box__value">
              {formatArea(totalFieldAreaRounded)}
            </div>
          </div>
        </div>
      </TileContent>
    </Tile>
  );
}

function TimberTile({lukeData, metsakeskusData}) {
  const metsakeskusPriceCalculations = metsakeskusData && metsakeskusData.priceCalculation && metsakeskusData.priceCalculation[0];
  const metsakeskusTotalTreeVolume = metsakeskusData && metsakeskusData.totalTreeVolume;
  const metsakeskusAverageTreeVolume = metsakeskusData && metsakeskusData.averageTreeVolume;
  const lukePriceCalculations = lukeData && lukeData.priceCalculation && lukeData.priceCalculation[0];
  const lukeTotalTreeVolume = lukeData && lukeData.totalTreeVolume;
  const lukeAverageTreeVolume = lukeData && lukeData.averageTreeVolume;

  if (!lukePriceCalculations && !metsakeskusPriceCalculations) {
    return null;
  }

  return (
    <Tile title="Puusto" className="pika-analyysi-grid__timber">
      <Tabs>
        <TabList>
          {metsakeskusPriceCalculations &&
            <Tab>
            Metsäkeskuksen metsävaratieto
            </Tab>
          }
          {lukePriceCalculations &&
            <Tab>
            Luonnonvarakeskus VMI 2019
            </Tab>
          }
        </TabList>
        <TabPanels>
          {metsakeskusPriceCalculations &&
            <TabPanel><TimberInfo totalTreeVolume={metsakeskusTotalTreeVolume} averageTreeVolume={metsakeskusAverageTreeVolume} priceCalculations={metsakeskusPriceCalculations}/></TabPanel>
          }
          {lukePriceCalculations &&
            <TabPanel><TimberInfo totalTreeVolume={lukeTotalTreeVolume} averageTreeVolume={lukeAverageTreeVolume} priceCalculations={lukePriceCalculations} /></TabPanel>
          }
        </TabPanels>
      </Tabs>

    </Tile>
  );
}

function TimberInfo({totalTreeVolume, averageTreeVolume, priceCalculations}) {
  const treeTypes = {
    birch: "Koivu",
    pine: "Mänty",
    spruce: "Kuusi"
  };

  const volumes = priceCalculations.volumes;
  const totalVolume = Object.keys(volumes).reduce((accumulator, currentKey) => { return accumulator + volumes[currentKey]; }, 0);
  const treeTypeKeys = [...new Set(Object.keys(volumes).map(key => {
    return key.replace(/Pulpwood|SawLogs/, "");
  }))];

  const timberRows = treeTypeKeys.reduce((accumulator, currentKey) => {
    const treeType = treeTypes[currentKey] || "Muu";
    const sawLogsKey = `${currentKey}SawLogs`;
    const pulpwoodKey = `${currentKey}Pulpwood`;

    const sawLogsVolume = volumes[sawLogsKey];
    const pulpwoodVolume = volumes[pulpwoodKey];
    const treeTotalVolume = sawLogsVolume + pulpwoodVolume;

    const sawLogPercentage = sawLogsVolume / treeTotalVolume * 100 || 0;
    const pulpwoodPercentage = pulpwoodVolume / treeTotalVolume * 100 || 0;

    const percentFromTotalVolume = treeTotalVolume / totalVolume * 100 || 0;

    if (treeTotalVolume && treeTotalVolume > 0) {
      accumulator.push({
        treeType: treeType,
        totalPercentage: percentFromTotalVolume,
        percentFromTotalVolume: formatPercentage(percentFromTotalVolume),
        sawLogPercentage: formatPercentage(sawLogPercentage),
        pulpwoodPercentage: formatPercentage(pulpwoodPercentage)
      });
    }

    return accumulator;
  }, []);

  const timberRowsSorted = _.orderBy(timberRows, "totalPercentage", "desc");

  const totalSawLogVolume = Object.keys(volumes).reduce((accumulator, currentKey) => {
    if (currentKey.includes("SawLogs")) {
      return accumulator += volumes[currentKey];
    }
    return accumulator;
  }, 0);

  const totalPulpWoodVolume = Object.keys(volumes).reduce((accumulator, currentKey) => {
    if (currentKey.includes("Pulpwood")) {
      return accumulator += volumes[currentKey];
    }
    return accumulator;
  }, 0);

  let totalSawLogPercentage = totalSawLogVolume / (totalSawLogVolume + totalPulpWoodVolume) * 100 || 0;
  let totalPulpWoodPercentage = totalPulpWoodVolume / (totalSawLogVolume + totalPulpWoodVolume) * 100 || 0;

  return (
    <React.Fragment>
      <div className="pika-analyysi-timber-totals">
        <div className="pika-analyysi-details pika-analyysi-timber-totals__numbers">
          <div className="pika-analyysi-details__item">
            <h4 className="value-box__label">
              Arvioitu puuston tilavuus
            </h4>
            <div className="value-box__value">
              {formatVolume(Math.round(totalTreeVolume))}
            </div>
          </div>
          <div className="pika-analyysi-details__item">
            <h4 className="value-box__label">
              Arvioitu puuston keskitilavuus
            </h4>
            <div className="value-box__value">
              {formatVolume(averageTreeVolume)}/ha
            </div>
          </div>
        </div>
        <div className="pika-analyysi-timber pika-analyysi-timber-totals__graph">
          <div className="pika-analyysi-timber__logs" style={{ "--timber-percentage": totalSawLogPercentage + "%" }}>
            <h4 className="size-0-75">Tukkipuuta</h4>
            <div className="size-1">
              {formatPercentage(totalSawLogPercentage)}
            </div>
          </div>
          <div className="pika-analyysi-timber__pulp" style={{ "--timber-percentage": totalPulpWoodPercentage + "%" }}>
            <h4 className="size-0-75">Kuitupuuta</h4>
            <div className="size-1">
              {formatPercentage(totalPulpWoodPercentage)}
            </div>
          </div>
        </div>
      </div>
      <table className="tile-table">
        <thead>
          <tr>
            <th>Puulaji</th>
            <th>Osuus</th>
            <th>Tukkia</th>
            <th>Kuitua</th>
          </tr>
        </thead>
        <tbody>
          {timberRowsSorted.map((row, index) => (
            <tr key={index}>
              <td>{row.treeType}</td>
              <td>{row.percentFromTotalVolume}</td>
              <td>{row.sawLogPercentage}</td>
              <td>{row.pulpwoodPercentage}</td>
            </tr>
        ))}

        </tbody>
      </table>
    </React.Fragment>
  );
}

function DisclaimerSection() {
  return (
    <section className="bg-gray-lighten-3 padding-1 padding-y">
      <div className="container">
        <div>
          OP Metsän pika-analyysi on karkea arvio kohteen puustosta ja sen arvosta. Voit vaihtaa tietolähteitä Metsäkeskuksen metsävaratiedon ja Luonnonvarakeskuksen tuottaman valtakunnan metsien inventoinnin (VMI) vuoden 2019 aineiston välillä, mikäli kohteelle on saatavissa molemmat aineistot. Kohteen arvioitu arvo perustuu ensisijaisesti Metsäkeskuksen metsävaratietoon. Huomioithan, että VMI-aineistoa ei päivitetä metsänkäyttöilmoituksilla, joten pika-analyysissä ei pystytä huomioimaan hakkuiden vaikutuksia kohteen puustotietoihin. Myös Metsäkeskuksen metsävaratiedossa voi olla mukana vanhentuneita kuviotietoja.{" "}
          <a
            href="https://www.op-metsa.fi/faq"
            target="_blank"
            rel="noopener noreferrer">
            Voit lukea täältä lisätietoa pika-analyysin arvonlaskennasta.
        </a>
          {" "}Mikäli haluat tarkemman arvion kohteesta, tilaa asiantuntijan laatima OP Metsäarvio.
        </div>
      </div>
    </section>
  );
}

