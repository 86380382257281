import React, {Component} from "react";

class TransferNoPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="text-align-center">

        <div className="padding-1-5 padding-y">
          <div className="margin-0-75 margin-bottom">
            <i className="opux-icon opux-icon-circled opux-icon-help size-74 color-primary" />
          </div>
          <h1 className="size-1-5 margin-0-5 margin-bottom">
            Tietojen siirto epäonnistui
          </h1>
          <p className="size-1 color-gray-darken-1">
            Löysimme <span className="medium color-gray-darken-2">{this.props.transfer.estates.no_permission}</span> metsätilaa, mutta oikeus tietojen siirtoon puuttuu.
          </p>
        </div>

        <div className="borders border-color-border padding-0-5 margin-y margin-top border-radius">
          <p className="size-1-25 color-gray-darken-1">
            Ennen tietojen siirtoa sinun tulee antaa suostumuksesi metsätietojen <span className="medium color-gray-darken-2">siirtoon</span> Metsäkeskuksen palvelussa.
          </p>
        </div>

        <a href="/consent" className="opux-btn opux-btn-large opux-btn-primary opux-btn--tiny margin-top margin-2">
          <i className="opux-icon opux-icon-arrow-l padding-left padding-1"/>
          <span className="padding-right padding-1-25">Palaa antamaan suostumus</span>
        </a>

      </div>
    );
  }
}

TransferNoPermission.propTypes = {
};

export default TransferNoPermission;
