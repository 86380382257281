import React, {Component} from "react";
import $ from "jquery";

class TransferNotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="padding-1-5 text-align-center">
        <div className="margin-0-75 margin-bottom">
          <i className="opux-icon opux-icon-circled opux-icon-help size-74 color-primary" />
        </div>
        <h1 className="size-1-5 margin-0-5 margin-bottom">
          Tietoja ei löytynyt
        </h1>
        <p className="size-1 color-gray-darken-1">
          Emme löytäneet tiedoistasi yhtään metsätilaa. Voit selvittää metsätilasi tiedot, <a href="http://www.metsaan.fi/anna-palautetta?layoutPlid=20212&metsaan-from-url=%2F" target="_blank" rel="noopener noreferrer">ottamalla yhteyttä Metsään.fi</a> -palveluun.
        </p>
      </div>
    );
  }

}

TransferNotFound.propTypes = {
};

export default TransferNotFound;
