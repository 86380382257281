/* eslint no-unused-vars: "off" */
import { NumberFormat } from "intl";
import fiLocale from "intl/locale-data/jsonp/fi.js";

const formatter = new NumberFormat("fi", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const decimalFormatter = new NumberFormat("fi", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const formatCurrency = (value) => {
  return formatter.format(value);
};

export const formatCurrencyWithDecimal = (value) => {
  return decimalFormatter.format(value);
};
