import React, { Component } from "react";
import request from "../../request";
import floatingInput from "../../floatingInput";

class NewsletterSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      submitEnabled: false,
      errorMessage: null,
      submitSuccess: false,
      termsAgreed: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTermAgreementCheckbox = this.handleTermAgreementCheckbox.bind(
      this
    );
  }

  componentDidMount() {
    floatingInput(this._root);
  }

  handleSubmit(event) {
    event.preventDefault();
    request
      .newsletterSignup({ email: this.state.inputValue })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            submitEnabled: false,
            inputValue: "",
            termsAgreed: false,
            errorMessage: null,
            submitSuccess: true
          });
        } else {
          this.setState({ errorMessage: response.errors.email[0] });
        }
      });
  }

  handleInputChange(event) {
    let newState = { inputValue: event.target.value };
    if (event.target.value.length > 0) {
      newState["submitEnabled"] = true;
    } else {
      newState["submitEnabled"] = false;
    }
    this.setState(newState);
  }

  submitDisabledValue() {
    return !(this.state.submitEnabled && this.state.termsAgreed);
  }

  renderError() {
    if (this.state.errorMessage) {
      return (
        <div className="color-red margin-0-25 margin-top inline-error">
          <i className="opux-icon opux-icon-alert margin-0-125 margin-right" />
          <span className="size-0-75">{this.state.errorMessage}</span>
        </div>
      );
    } else {
      return null;
    }
  }

  handleTermAgreementCheckbox() {
    this.setState({
      termsAgreed: !this.state.termsAgreed
    });
  }

  renderSuccessMessage() {
    return (
      <div className="text-align-center">
        <i className="color-green opux-icon opux-icon-check margin-0-25 margin-bottom size-52" />
        <p className="size-1 color-gray-darken-1">
          Kiitos! Ilmoitamme sinulle heti kun OP Metsä on käytettävissäsi.
        </p>
      </div>
    );
  }

  renderForm() {
    let submitCssClasses = "opux-btn opux-btn-medium block width-100";
    if (this.submitDisabledValue()) {
      submitCssClasses = submitCssClasses + " opux-btn-disabled";
    }

    if (this.state.submitSuccess) {
      return this.renderSuccessMessage();
    } else {
      return (
        <form onSubmit={this.handleSubmit}>
          <div className="margin-1-5 margin-bottom">
            <div className="floating-label-input floating-label-input--text floating-label-input--material">
              <input
                ref
                id="newsletter_signup_email"
                name="email"
                type="text"
                onChange={this.handleInputChange}
                value={this.state.inputValue}
              />
              <label htmlFor="newsletter_signup_email">Sähköposti</label>
            </div>
            {this.renderError()}
          </div>
          <div className="opux-input opux-input-checkbox margin-0-5 margin-bottom">
            <input
              value={this.state.termsAgreed}
              onChange={this.handleTermAgreementCheckbox}
              id="agree-to-terms"
              type="checkbox"
            />
            <label htmlFor="agree-to-terms"> Hyväksyn ehdot </label>
          </div>
          <p className="color-gray-darken-1 size-0-75 margin-0-75 margin-bottom">
            Klikkaamalla Tilaa ilmoitus hyväksyt tietojesi lähettämisen
            yritykselle OP, joka käyttää niitä yksityisyydensuojakäytäntönsä
            mukaisesti.&nbsp;
            <a
              href="https://uusi.op.fi/documents/20556/63056/Potentiaalisten+asiakkaiden+rekisteri/551f0622-e474-4c25-afeb-ede59f873211"
              target="_blank"
              rel="noopener noreferrer"
            >
              OP:n markkinointirekisteriseloste
            </a>
          </p>
          <button
            type="submit"
            className={submitCssClasses}
            disabled={this.submitDisabledValue()}
          >
            Tilaa ilmoitus
          </button>
        </form>
      );
    }
  }

  render() {
    return (
      <div ref={c => (this._root = c)} className="landing-promo__tile bg-white">
        <div className="padding-0-75 border-bottom border-color-border text-align-center">
          <h3 className="size-1-25 margin-0-25 margin-bottom">
            Avaamme palvelun pian
          </h3>
          <p className="color-gray-darken-1 size-1">
            Jätä sähköpostiosoitteesi, niin ilmoitamme sinulle heti kun palvelu
            on käytettävissäsi.
          </p>
        </div>
        <div className="padding-0-75">{this.renderForm()}</div>
      </div>
    );
  }
}

export default NewsletterSignup;
