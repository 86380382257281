import React, {useState, useEffect} from "react";
import request from "../../../../request";
import {BlockLink} from "../../BlockLink/BlockLink";
import {Button} from "../../Button/Button";

export default function DraftLink({id, initialProcessable}) {
  const [draftLink, setDraftLink] = useState("");
  const [notice, setNotice] = useState(null);
  const [loading, setLoading] = useState(initialProcessable);
  const [loadingText, setLoadingText] = useState("Perusversiota ladataan");
  const [showReload, setShowReload] = useState(false);
  const [documentReady, setDocumentReady] = useState(false);
  const [processable, setProcessable] = useState(initialProcessable);

  const GET_DOCUMENT_INTERVAL = 1000 * 20;
  const NEW_LINK_INTERVAL = 1000 * 60 * 14;

  function getDraftLink() {
    setLoading(true);
    request.forestValuationItemDraftLink(id)
      .then(response => {
        setDraftLink(response.documentUrl);
        setProcessable(response.processable);
        setNotice(null);
        setLoading(false);
        setShowReload(false);
        setDocumentReady(true);
        setLoadingText("Perusversiota ladataan");
      })
      .catch((error) => {
        if (error?.responseJSON?.message) {
          setLoadingText(error.responseJSON.message);
        } else if (error?.responseJSON?.error) {
          setNotice(error.responseJSON.error);
          setLoading(false);
          setLoadingText("Perusversiota ladataan");
          setProcessable(error.responseJSON.processable);
        } else {
          setNotice("Perusversiota ei saatu haettua");
          setLoading(false);
          setLoadingText("Perusversiota ladataan");
        }
        setShowReload(true);
      });
  }

  function reloadDraft() {
    setLoading(true);
    setShowReload(false);
    request.forestValuationItemDraftReload(id)
      .then(response => {
        setNotice("Perusversion haku käynnistetty");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.responseJSON?.error) {
          setNotice(error.responseJSON.error);
        } else {
          setNotice("Perusversion hakua ei saatu käynnistettyä");
        }
        setLoading(false);
        setShowReload(true);
      });
  }

  useEffect(() => {
    if (!processable) {
      setNotice("Tilaus on peruttu");
      return null;
    }
    if (!documentReady) {
      getDraftLink();
    }
    const interval = setInterval(() => {
      if (!documentReady) {
        getDraftLink();
      }
    }, GET_DOCUMENT_INTERVAL);
    return () => clearInterval(interval);
  }, [id, documentReady]);

  useEffect(() => {
    if (!processable) {
      setNotice("Tilaus on peruttu");
      return null;
    }
    if (documentReady) {
      getDraftLink();
    }
    const interval = setInterval(() => {
      if (documentReady) {
        getDraftLink();
      }
    }, NEW_LINK_INTERVAL);
    return () => clearInterval(interval);
  }, [id, documentReady]);

  useEffect(() => {
    setShowReload(processable);
  }, [processable]);

  return (
    <div className="flex align-center justify">
      <BlockLink href={draftLink} title={"Lataa perusversio ja kuviotiedot"} loading={loading} loadingText={loadingText} notice={notice}/>
      {showReload &&
        <div className="padding-0-25">
          <Button
            onClick={reloadDraft}
            size="tiny">
            Hae perusversio uudestaan
          </Button>
        </div>
      }
    </div>
  );
}
