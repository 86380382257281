import React, { Component } from "react";
import PropTypes from "prop-types";
import AttachmentPreview from "./AttachmentPreview";

class AttachmentItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onClickRemove = this.onClickRemove.bind(this);
  }

  onClickRemove() {
    this.props.onRemove();
  }

  componentDidUpdate() {
    if (
      this.props.attachment &&
      !this.props.attachment.smallUrl &&
      this.props.attachment.id
    ) {
      this.startPollingForPreview();
    }
  }

  startPollingForPreview() {
    if (this.previewPoller) {
      return;
    }

    this.pollForPreview();
  }

  pollForPreview() {
    const url = `/vero/attachments/${this.props.attachment.id}`;

    fetch(url, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "same-origin"
    })
      .then(response => response.json())
      .then(response => {
        if (response.smallUrl) {
          this.stopPollingForPreview();
          this.setState({
            smallUrl: response.smallUrl,
            largeUrl: response.largeUrl
          });
        }
      });

    this.previewPoller = setTimeout(this.pollForPreview.bind(this), 1000);
  }

  componentWillUnmount() {
    this.stopPollingForPreview();
  }

  stopPollingForPreview() {
    if (this.previewPoller) {
      clearTimeout(this.previewPoller);
    }
  }

  // Not used yet
  renderProgress() {
    if (this.props.progress) {
      return (
        <div
          style={{
            backgroundColor: "#FF6A10",
            position: "absolute",
            bottom: 0,
            height: 2,
            left: 0,
            margin: 0,
            width: `${this.props.progress}%`
          }}
        />
      );
    }
  }

  renderRemoveButton() {
    // if (this.props.loading) {
    //   return <span className="opux-loading--small" />;
    // }

    if (this.props.onRemove) {
      return (
        <i
          className="size-1 opux-icon opux-icon-close color-gray"
          onClick={this.onClickRemove}
        />
      );
    }
  }

  previewUrl() {
    if (this.state.smallUrl) {
      return this.state.smallUrl;
    }
    if (this.props.attachment && this.props.attachment.smallUrl) {
      return this.props.attachment.smallUrl;
    }
  }

  largeUrl() {
    if (this.state.largeUrl) {
      return this.state.largeUrl;
    }
    if (this.props.attachment && this.props.attachment.largeUrl) {
      return this.props.attachment.largeUrl;
    }
  }

  renderPreview() {
    if (this.previewUrl()) {
      return (
        <div className="width-2">
          <AttachmentPreview
            index={this.props.index}
            thumbnailUrl={this.previewUrl()}
            originalUrl={this.largeUrl()}
          />
        </div>
      );
    } else {
      return (
        <div className="width-2">
          <span className="opux-loading--small" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="list-links__item">
        {this.renderPreview()}
        <div className="grow">
          <div className="size-1 margin-0-25 margin-y">
            Liite {this.props.index}
          </div>
        </div>
        <div>{this.renderRemoveButton()}</div>
        {this.renderProgress()}
      </div>
    );
  }
}

AttachmentItem.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
  progress: PropTypes.number
};

export default AttachmentItem;
