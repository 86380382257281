import React, {useState, useEffect} from "react";
import CreatableSelect from "react-select/creatable";
import Select, { components } from "react-select";
const municipalities = require("./kuntaluettelo-suppeat-tiedot-2021-01-01.csv");

export default function FieldValuationMultiSelect({province = null}) {
  const [selectedMunicipalitiesAndProvinces, setSelectedMunicipalitiesAndProvinces] = useState([]);
  const [selectedVillages, setSelectedVillages] = useState([]);

  let filteredMunicipalities = municipalities;
  let provinceName = "";

  if (province) {
    filteredMunicipalities = municipalities.filter(municipality => {
      return municipality["Maakuntanro"] === province;
    });
    provinceName = filteredMunicipalities[0]["Maakunnan nimi"];
  }

  const municipalitiesMapped = filteredMunicipalities.sort((a, b) => {
    const nameA = a["Kunnan nimi"];
    const nameB = b["Kunnan nimi"];
    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
  }).map(muni => {
    return { value: muni["Kuntanumero"], "label": muni["Kunnan nimi"], subtitle: muni["Maakunnan nimi"], type: "municipality" };
  });

  const provincesMapped = municipalities.reduce((accumulator, currentMuni) => {
    if (!accumulator.some(province => province.value === currentMuni["Maakuntanro"])) {
      accumulator.push({ value: currentMuni["Maakuntanro"], label: currentMuni["Maakunnan nimi"], subtitle: "Maakunta", type: "province" });
    }
    return accumulator;
  }, []).sort((a, b) => {
    const nameA = a["label"];
    const nameB = b["label"];
    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
  });

  const allOptions = [ {label: "Kunnat", options: municipalitiesMapped}, {...!province && {label: "Maakunnat", options: provincesMapped}} ];

  function handleMunicipalityAndProvinceSelect(values) {
    setSelectedMunicipalitiesAndProvinces(values);
  }

  function handleVillageSelect(values) {
    const valuesWithType = values.map(value => { return {value: value.value, type: "village"}; });
    setSelectedVillages(valuesWithType);
  }

  useEffect(() => {
    let hiddenRailsInput = document.querySelector("#field_valuation_query");
    if (province) {
      hiddenRailsInput = document.querySelector(`[data-province~='${province}']`);
    }
    const values = JSON.stringify([...selectedMunicipalitiesAndProvinces, ...selectedVillages]);
    hiddenRailsInput.setAttribute("value", values);
  }, [selectedMunicipalitiesAndProvinces, selectedVillages]);

  function validateRegex(value) {
    return /^([0-9]+-){1}[0-9]+$/.test(value);
  }

  return (
    <div className="child-margins-y-1">
      <div>
        <div className="medium margin-0-25 margin-bottom">{province ? provinceName : "Kunnat ja maakunnat"}</div>
        <Select
          isClearable
          isMulti
          options={allOptions}
          className="react-select-container"
          classNamePrefix="react-select"
          components={{ Option }}
          placeholder={province ? "Hae kunta" : "Etsi kunta tai maakunta"}
          noOptionsMessage={() => "Ei hakutuloksia"}
          onChange={handleMunicipalityAndProvinceSelect}
        />
      </div>
      {!province &&
        (
          <div>
            <div className="medium margin-0-25 margin-bottom">Hae kylätunnuksella</div>
            <CreatableSelect
              isClearable
              isMulti
              className="react-select-container"
              components={{ DropdownIndicator: null, IndicatorSeparator: null }}
              classNamePrefix="react-select"
              placeholder={"Syötä kylätunnus muodossa 123-123"}
              isValidNewOption={(value) => validateRegex(value)}
              noOptionsMessage={() => null}
              formatCreateLabel={(searchValue) => `Lisää kylätunnus: ${searchValue}`}
              onChange={handleVillageSelect}
            />
          </div>
        )
      }
    </div>
  );
}

const Option = props => {
  return (
    <components.Option {...props}>
      {props.data.label}
      <div className="size-0-75 color-gray-darken-1">{props.data.subtitle}</div>
    </components.Option>
  );
};

