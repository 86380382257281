import React, { Suspense } from "react";
import AdminPropertyMap from "./AdminPropertyMap";
import ErrorBoundary from "../ErrorBoundary";

export default function LazyAdminPropertyMap(props) {
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div className="row">
            <div className="col-xs-12 flex justify-center">
              <div className="padding-1">Ladataan…</div>
            </div>
          </div>
        }
      >
        <AdminPropertyMap {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
