import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatCurrencyWithDecimal } from "../../helpers/currency";
import { formatDate, formatDateWithoutYear } from "../../helpers/date";

class TravelSlip extends Component {
  renderDescription() {
    if (this.props.slip.transactionable.travelItinerary.length > 0) {
      return this.props.slip.transactionable.travelItinerary;
    } else {
      return "Ei selitettä";
    }
  }

  renderDates() {
    if (
      this.props.slip.transactionable.startDate ===
      this.props.slip.transactionable.endDate
    ) {
      return (
        <span className="color-gray-darken-1 size-0-75 line-1 inline">
          ({formatDate(this.props.slip.transactionable.endDate)})
        </span>
      );
    } else {
      return (
        <span className="color-gray-darken-1 size-0-75 line-1 inline">
          ({formatDateWithoutYear(this.props.slip.transactionable.startDate)}
          &nbsp;-&nbsp;
          {formatDate(this.props.slip.transactionable.endDate)})
        </span>
      );
    }
  }

  renderPlainTextDates() {
    if (
      this.props.slip.transactionable.startDate ===
      this.props.slip.transactionable.endDate
    ) {
      return formatDate(this.props.slip.transactionable.endDate);
    } else {
      return `${formatDateWithoutYear(
        this.props.slip.transactionable.startDate
      )} – ${formatDate(this.props.slip.transactionable.endDate)}`;
    }
  }

  render() {
    return (
      <a
        className="list-links__item"
        href={`/vero/transactions/${this.props.slip.id}`}
        data-native-action="pushScreen"
        data-native-title={`Ajopäiväkirja ${this.renderPlainTextDates()}`}
        data-native-right-button-url={`/vero/transactions/${
          this.props.slip.id
        }/edit`}
      >
        <div className="grow overflow-x-hidden">
          <div className="size-1 color-gray-darken-2">
            Ajopäiväkirja {this.renderDates()}
          </div>
          <div className="size-0-75 color-gray-darken-1 overflow-x-hidden overflow-ellipsis nowrap">
            {this.renderDescription()}
          </div>
        </div>
        <div className="flex align-center text-align-right">
          <div className="size-0-75 line-1-25 color-gray-darken-1">
            {formatCurrencyWithDecimal(this.props.slip.amount)}
          </div>
          <i className="opux-icon opux-icon-chevron-r color-gray-darken-1 size-1-25" />
        </div>
      </a>
    );
  }
}

TravelSlip.propTypes = {
  slip: PropTypes.shape({
    id: PropTypes.number.isRequired,
    transactionable: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }).isRequired
};

export default TravelSlip;
