import React from "react";
import PaymentStatus from "./PaymentStatus";
import ReturnedStatus from "./ReturnedStatus";

export default function OrderInfo({orderData, orderToken}) {
  const priceField = () => {
    if (orderData.external) {
      return (
        <div className={"label-value-item"}>
          <div className={"label-value-item__label"}>Hinta:</div>
          <div className={"label-value-item__value"}>{orderData.total_price}</div>
        </div>
      );
    }
  };

  const paymentStatusSection = () => {
    if (orderData.external) {
      return <PaymentStatus orderData={orderData} orderToken={orderToken} />;
    }
  };

  const returnedStatusSection = () => {
    if (orderData.external) {
      return <ReturnedStatus orderData={orderData} />;
    }
  };

  return (
    <div className={"col-xs-12"}>
      <h3 className={"size-1-25 weight-500 margin-0-5 margin-bottom"}>Tilaustiedot</h3>
      <div className={"margin-2 margin-bottom"}>
        <div className={"label-value-grid"}>
          <div className={"label-value-item"}>
            <div className={"label-value-item__label"}>Nimi:</div>
            <div className={"label-value-item__value"}>{orderData.requester_info}</div>
          </div>
          <div className={"label-value-item"}>
            <div className={"label-value-item__label"}>Tilausnumero:</div>
            <div className={"label-value-item__value"}>{orderData.order_number}</div>
          </div>
          <div>{orderData.own_reference}</div>
          <div className={"label-value-item"}>
            <div className={"label-value-item__label"}>Puhelinnumero:</div>
            <div className={"label-value-item__value"}>{orderData.requester_phone_number}</div>
          </div>
          {priceField()}
        </div>
        {paymentStatusSection()}
      </div>
      {returnedStatusSection()}
    </div>
  );
}
