document.addEventListener("turbolinks:load", function(event) {
  bindAccordions();
});

function bindAccordions() {
  const accordions = document.querySelectorAll("[data-role='accordion']");
  accordions.forEach((accordion) => {
    const button = accordion.querySelector("[data-action='accordion-toggle']");
    button.addEventListener("click", (event) => {
      accordion.classList.toggle("accordion--active");
    });
  });
}
