import { Modal } from "@op/opds.opux-modal";
import { Button, Select, Form, FormRow, TextInput } from "@op/opds.opux";
import React, { useState, useRef } from "react";

// Open state is defined by the parent component and passed down to the Modal
export default function OpuxFormModal(
  {
    title = "",
    content = "",
    action = (reason) => {},
    modalOpen = false,
    setModalOpen = useState(false)[1],
    setModalAccepted = useState(false)[1]
  }) {
  const modalRef = useRef();
  const [reason, setReason] = useState("");

  function cancelAction() {
    setModalOpen(false)
  }

  function yesAction() {
    setModalOpen(false)

    action(reason);
  }

  function handleClick() {
    if (modalOpen) modalRef.current.close();
    else modalRef.current.open();
    setModalOpen(false);
  }

  function handleReasonChange() {
    setReason(event.target.value);
  }

  return (
    <div>
      <Modal
        className="opuxModal"
        appElement={document.getElementById("root-element")}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}>
            <Button alternate onClick={cancelAction}>
              Peruuta
            </Button>
            <Button finish onClick={yesAction}>
              Jatka
            </Button>
          </div>
        }
        large
        onUpdate={(state) => {
          setModalOpen(state.open);
        }}
        isOpen={modalOpen}
        ref={modalRef}
        title={title}
        titleTagName="h4">
        <Form>
          <FormRow label="Arviota ei voida toteuttaa">
            <Select value={reason} onChange={handleReasonChange}>
              <option disabled value="">Valitse syy</option>
              <option value="is_field_area">Kiinteistö on peltomaata</option>
              <option value="is_development_land">Kiinteistö on tonttimaata/pihapiiriä</option>
              <option value="data_not_available">Inventoitua metsävaratietoa ei ole saatavilla</option>
              <option value="system_error">Järjestelmähäiriö, arvio toimitetaan sinulle erikseen</option>
            </Select>
          </FormRow>
        </Form>
      </Modal>
    </div>
  );
}