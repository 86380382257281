import React, { Component } from "react";
import PropTypes from "prop-types";
import request from "../../request";

import PropertyDetailsHero from "./PropertyDetailsHero";
import PropertyDetailsAreaAndCheck from "./PropertyDetailsAreaAndCheck";
import PropertyDetailsTimber from "./PropertyDetailsTimber";
import PropertyDetailsValueGrowth from "./PropertyDetailsValueGrowth";
import ActionTimeline from "../ActionTimeline";

class PropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property: this.props.property,
      saving: false
    };
  }

  renderValueGrowth() {
    if (this.state.property.dataSource !== "Luonnonvarakeskus") {
      return (
        <PropertyDetailsValueGrowth
          earningsEstimate={this.state.property.earningsEstimate}
        />
      );
    }
  }

  render() {
    const {
      name,
      forestIdentifier,
      municipality,
      totalArea,
      totalTreeVolume,
      averageTreeVolume,
      totalValue,
      seedlingsValue,
      stockValue,
      totalFellingIncomes,
      priceCalculations,
      earningsEstimate,
      areaTypes,
      actions,
      timberTypeAllocation,
      totalOperationIncome,
      totalOperationCost,
      dominantTreeType,
      dataSource,
      owners,
      forestryArea
    } = this.props.property;
    const { admin, propertyMapPath, heroImagePath } = this.props;
    return (
      <div>
        <PropertyDetailsHero
          admin={admin}
          name={name}
          forestIdentifier={forestIdentifier}
          municipality={municipality}
          heroImagePath={heroImagePath}
          totalValue={totalValue}
          seedlingsValue={seedlingsValue}
          stockValue={stockValue}
          totalFellingIncomes={totalFellingIncomes}
          earningsEstimate={earningsEstimate}
          propertyMapPath={propertyMapPath}
          dominantTreeType={dominantTreeType}
        />
        <PropertyDetailsAreaAndCheck
          admin={admin}
          totalArea={totalArea}
          forestryArea={forestryArea}
          areaTypes={areaTypes}
          dataSource={dataSource}
          owners={owners}
        />
        <section className="padding-1-5 padding-y border-bottom border-color-border">
          <PropertyDetailsTimber
            priceCalculations={priceCalculations}
            totalTreeVolume={totalTreeVolume}
            averageTreeVolume={averageTreeVolume}
            timberTypeAllocation={timberTypeAllocation}
          />
        </section>

        {this.renderValueGrowth()}

        <ActionTimeline
          actions={actions}
          totalOperationIncome={totalOperationIncome}
          totalOperationCost={totalOperationCost}
          dataSource={dataSource}
          forSingleProperty
        />
      </div>
    );
  }
}

PropertyDetails.propTypes = {
  admin: PropTypes.bool,
  property: PropTypes.object.isRequired,
  propertyMapPath: PropTypes.string.isRequired,
  heroImagePath: PropTypes.string.isRequired,
  newInsuranceEnquiryPath: PropTypes.string.isRequired,
  forestPlanPath: PropTypes.string
};

export default PropertyDetails;
