import React, { Suspense, useState, useEffect, useMemo, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Sidebar from "react-sidebar";
import PropertyMapSidebar from "../PropertyMapSidebar";
import PropertyMapGeo2 from "./index";
import ErrorBoundary from "../ErrorBoundary";

function sidebarContent(propertyName, stand, clearMapSelection) {
  let sidebar;
  if (stand) {
    sidebar = (
      <PropertyMapSidebar
        key={stand.id}
        propertyName={propertyName}
        stand={stand}
        closeStandDetails={clearMapSelection}
      />
    );
  } else {
    sidebar = <div />;
  }

  return (
    <CSSTransition
      classNames="sidebar-content-animate"
      timeout={{ enter: 300, exit: 300 }}
    >
      {sidebar}
    </CSSTransition>
  );
}

export default function LazyPropertyMapGeo2(props) {
  const [mql, setMql] = useState({});
  const [selectedStandId, setSelectedStandId] = useState();
  const mapRef = useRef();

  const selectedStand = useMemo(() => {
    if (selectedStandId) {
      return props.stands.find((stand) => {
        return stand.id === selectedStandId;
      });
    }
  }, [selectedStandId, props.stands]);

  useEffect(() => {
    const mql = window.matchMedia("(min-width: 700px)");
    setMql(mql);
  }, []);

  const { name } = props.property;
  const sidebarWidth = mql.matches
    ? window.innerWidth / 4
    : window.innerWidth * 0.84;

  function clearMapSelection() {
    setSelectedStandId(null);
    mapRef.current.clearSelection();
  }

  const sidebarProps = {
    sidebar: sidebarContent(name, selectedStand, clearMapSelection),
    docked: false,
    sidebarClassName: "map-sidebar",
    open: !!selectedStandId,
    styles: {
      sidebar: {
        width: sidebarWidth,
        minWidth: 280,
        overflowX: "hidden",
        transition: "transform 250ms cubic-bezier(0.770, 0.000, 0.175, 1.000)",
        WebkitTransition:
          "-webkit-transform 250ms cubic-bezier(0.770, 0.000, 0.175, 1.000)",
      },
      overlay: {
        display: "none",
      },
    },
  };

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div className="row">
            <div className="col-xs-12 flex justify-center">
              <div className="padding-1">Ladataan…</div>
            </div>
          </div>
        }
      >
        <div style={{ position: "relative", height: "100%" }}>
          <Sidebar {...sidebarProps}>
            <div style={{ height: "100%" }}>
              <PropertyMapGeo2
                ref={mapRef}
                onSelectStand={setSelectedStandId}
                {...props}
              />
            </div>
          </Sidebar>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
}
