window.addEventListener("adobeLaunchLoaded", function(event) {
  initializeConsentForm();
});

document.addEventListener("turbolinks:load", function(event) {
  initializeConsentForm();
});

function initializeConsentForm(different) {
  document.querySelectorAll("[data-action='set-consent']").forEach((form) => {
    const initialConsentArray = window.opConsent ? window.opConsent.getConsent().categories : [0, 0, 0, 0];
    form.elements["opConsentValttamattomat"].checked = initialConsentArray[0];
    form.elements["opConsentToiminnanKehitys"].checked = initialConsentArray[1];
    form.elements["opConsentKohdentaminen"].checked = initialConsentArray[2];
    form.elements["opConsentKolmannenOsapuolen"].checked = initialConsentArray[3];

    form.addEventListener("submit", (event) => {
      event.preventDefault();
      const consentArray = [
        form.elements["opConsentValttamattomat"].checked ? 1 : 0,
        form.elements["opConsentToiminnanKehitys"].checked ? 1 : 0,
        form.elements["opConsentKohdentaminen"].checked ? 1 : 0,
        form.elements["opConsentKolmannenOsapuolen"].checked ? 1 : 0,
      ];
      if (window.opConsent ? window.opConsent.setConsentArray(consentArray) : false) {
        document.querySelector("#consent-message").className = "color-green";
        document.querySelector("#consent-message").innerHTML = "Evästeasetukset tallennettu";
      } else {
        document.querySelector("#consent-message").className = "color-red";
        document.querySelector("#consent-message").innerHTML = "Evästeasetusten tallentaminen ei onnistunut";
      }
      setTimeout(() => {
        document.querySelector("#consent-message").innerHTML = "";
      }, 5000);
    });
  });
}
