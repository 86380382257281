import React, {useState, useEffect} from "react";
import request from "../../../../request";
import {BlockLink} from "../../BlockLink/BlockLink";

export default function DocumentLink({id, title, subtitle}) {
  const [documentLink, setDocumentLink] = useState("");
  const [notice, setNotice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Metsäarviota ladataan");
  const [documentReady, setDocumentReady] = useState(false);

  const GET_DOCUMENT_INTERVAL = 1000 * 20;
  const NEW_LINK_INTERVAL = 1000 * 60 * 14;

  function getDocumentLink() {
    setLoading(true);
    request.forestValuationItemDocumentLink(id)
      .then(response => {
        setDocumentLink(response.documentUrl);
        setNotice(null);
        setLoading(false);
        setDocumentReady(true);
        setLoadingText("Metsäarviota ladataan")
      })
      .catch((error) => {
        if (error?.responseJSON?.message) {
          setLoadingText(error.responseJSON.message)
        } else if (error?.responseJSON?.error) {
          setNotice(error.responseJSON.error)
          setLoading(false);
          setLoadingText("Metsäarviota ladataan")
        } else {
          setNotice("Metsäarviota ei saatu haettua")
          setLoading(false);
          setLoadingText("Metsäarviota ladataan")
        }
      });
  }

  useEffect(() => {
    if (!documentReady) {
      getDocumentLink();
    }
    const interval = setInterval(() => {
    if (!documentReady) {
      getDocumentLink();
    }
    }, GET_DOCUMENT_INTERVAL);
    return () => clearInterval(interval);
  }, [id, documentReady]);

  useEffect(() => {
    if (documentReady) {
      getDocumentLink();
    }
    const interval = setInterval(() => {
      if (documentReady) {
        getDocumentLink();
      }
    }, NEW_LINK_INTERVAL);

    return () => clearInterval(interval);
  }, [id, documentReady]);

  return (
    <BlockLink href={documentLink} title={title} subtitle={subtitle} loading={loading} loadingText={loadingText} notice={notice}/>
  );
}
