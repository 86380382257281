import React, { Component } from "react";
import PropTypes from "prop-types";
import { ResponsiveContainer, PieChart, Pie } from "recharts";
import { formatCurrency } from "../../helpers/currency";

const colors = [
  "#ff6a10",
  "#6e6e6e",
  "#969696",
  "#bebebe",
  "#dcdcdc",
  "#f0f0f0"
];

class KarhuPieChart extends Component {
  renderLabel(props) {
    let x;
    if (props.textAnchor === "start") {
      x = props.x + 30;
    } else if (props.textAnchor === "end") {
      x = props.x - 30;
    } else {
      x = props.x;
    }

    return (
      <text
        textAnchor="middle"
        y={props.y}
        alignmentBaseline="middle"
        className="recharts-pie-label-text"
      >
        <tspan x={x}>{props.name}</tspan>
        <tspan x={x} dy="1.25em">
          {formatCurrency(props.value)}
        </tspan>
      </text>
    );
  }

  centerText() {
    let count = this.props.data.amount;
    let text;
    if (count === 1) {
      text = "metsätila";
    } else {
      text = "metsätilaa";
    }
    return (
      <g className="pie-center-text">
        <text
          className="pie-center-text-count size-1-75"
          fontSize="41px"
          color="orange"
          x="50%"
          y="50%"
          textAnchor="middle"
        >
          {count}
        </text>
        <text
          className="size-1"
          fontSize="20px"
          x="50%"
          y="60%"
          textAnchor="middle"
        >
          {text}
        </text>
      </g>
    );
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const data = this.props.data.forests.map((datum, index) => {
      return Object.assign({ fill: colors[index % 5] }, datum);
    });
    return (
      <div className="karhu-pie-chart size-0-75">
        <ResponsiveContainer height={300}>
          <PieChart height={300}>
            <Pie
              startAngle={450}
              endAngle={90}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={82}
              outerRadius={100}
              labelLine={false}
              label={this.renderLabel}
            />
            {this.centerText()}
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

KarhuPieChart.propTypes = {
  data: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    forests: PropTypes.array.isRequired
  }).isRequired
};

export default KarhuPieChart;
