import React from "react";
import cx from "classnames";

export function Button({ children, className, size, color, square, ...otherProps }) {
  return (
    <button className={cx("opux-btn", {
      "opux-btn--extratiny": size === "extratiny",
      "opux-btn--tiny": size === "tiny",
      "opux-btn--small": size === "small",
      "opux-btn--large": size === "large",
      "opux-btn-square": square,
      "opux-btn-primary": color === "primary",
      "opux-btn-alternate": color === "alt",
    }, className)}
      {...otherProps}>
      {children}
    </button>
  );
}
