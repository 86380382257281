import React, { Component } from "react";

import request from "../../../request";
import floatingInput from "../../../floatingInput";
import { getMunicipalityName } from "../getMunicipalityName";

import { debounce } from "lodash";

export default class PikaAnalyysiRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      passwordConfirmation: "",
      marketingConsent: false,
      emailIsKnown: false,
      authenticated: false
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    const lukeData = this.props.forest.find(data => { return data.dataSource.toLowerCase().includes("luonnonvarakeskus"); });
    const metsakeskusData = this.props.forest.find(data => { return data.dataSource.toLowerCase().includes("suomen metsäkeskus"); });

    this.name = metsakeskusData && metsakeskusData.name || lukeData && lukeData.name;
    this.municipality = getMunicipalityName(lukeData, metsakeskusData);
    this.forestIdentifier = metsakeskusData && metsakeskusData.visibleId || lukeData && lukeData.visibleId;

    this.checkUserEmailExistence = debounce(email => {
      this.setState({
        emailIsKnown: false
      });
      request.userEmailAddress(email).then((_response, status) => {
        if (status === "success") {
          this.setState({
            emailIsKnown: true
          });
        }
      });
    }, 1000);
  }

  componentDidMount() {
    floatingInput(this._root);
  }

  handleCheckboxChange(event) {
    this.setState({
      [event.target.name]: event.target.checked
    });
  }

  handleEmailChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });

    this.checkUserEmailExistence(event.target.value);
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      errorMessage: ""
    });

    if (
      this.state.password.length === 0 ||
      (!this.state.emailIsKnown && this.state.password !== this.state.passwordConfirmation)
    ) {
      this.setState({
        errorMessage: "Salasana ja salasanan varmistus eivät vastaa toisiaan"
      });
    } else {
      request
        .userRegistration({
          user: {
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.passwordConfirmation,
            marketing_consent: this.state.marketingConsent,
            property_search: true
          }
        })
        .then(response => {
          this.setState({authenticated: true})
          this.props.onSubmit({
            accountCreated: true,
            email: this.state.email
          });
        })
        .fail(response => {
          this.setState({
            errorMessage: "Tiliä ei pystytty luomaan."
          });
        });
    }
  }

  render() {
    if (!this.state.authenticated) { 
      return (
        <section>
          <section className="padding-1 padding-y">
            <div className="container">
              <div className="row text-align-left">
                <div className="col-xs-12 col-xl-9">
                  <div className="row row--collapse">
                    <div className="col-xs-12 col-m-10 col-xl-8">
                      <form onSubmit={this.handleSubmit}>
                        {!this.state.emailIsKnown ? (
                          <div>
                            <h3 className="size-1-25 margin-1 margin-bottom">
                              Luo maksuton OP Metsä -käyttäjätili
                            </h3>
                            <p className="margin-0-5 margin-bottom">
                              Näin saat käyttöösi kaikki OP Metsän ominaisuudet
                              mm. tarkempi arvio metsäsi puuston arvosta ja
                              maksuton veromuistio-sovellus metsätalouden
                              tositteiden ja ajopäiväkirjan hallintaan.
                            </p>
                          </div>
                        ) : (
                          <h3 className="size-1-25 margin-1 margin-bottom">
                            Kirjaudu sisään
                          </h3>
                        )}
                        <div className="input">
                          <div className="floating-label-input floating-label-input--text floating-label-input--material">
                            <input
                              id="registrationEmail"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleEmailChange}
                            />
                            <label htmlFor="registrationEmail">
                              Sähköpostiosoite
                            </label>
                          </div>
                        </div>
                        <div className="bg-white">
                          <AccountFields
                            onInputChange={this.handleInputChange}
                            createAccount={!this.state.emailIsKnown ? true : false}
                          />
                        </div>
                        {this.state.errorMessage ? (
                          <div className="margin-0-5 margin-top">
                            <div className="input-error">
                              {this.state.errorMessage}
                            </div>
                          </div>
                        ) : null}
                        <div className="margin-1-5 margin-top">
                          <button
                            type="submit"
                            className="opux-btn opux-btn-primary opux-btn-large"
                            disabled={this.state.email.length === 0}>
                            {!this.state.emailIsKnown
                              ? "Luo käyttäjätili"
                              : "Kirjaudu sisään"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
    )} else {
      return null
    };
  }
}

class AccountFields extends Component {
  componentDidMount() {
    floatingInput(this._root);
  }
  render() {
    return (
      <div
        className="child-margins-y-0-5 margin-0-5 margin-top"
        ref={c => (this._root = c)}>
        <div className="input">
          <div className="floating-label-input floating-label-input--text floating-label-input--material">
            <input
              name="password"
              type="password"
              id="password"
              value={this.props.password}
              onChange={this.props.onInputChange}
            />
            <label htmlFor="password">Salasana</label>
          </div>
        </div>
        {this.props.createAccount && (
          <div className="input">
            <div className="floating-label-input floating-label-input--text floating-label-input--material">
              <input
                name="passwordConfirmation"
                id="passwordConfirmation"
                type="password"
                value={this.props.passwordConfirmation}
                onChange={this.props.onInputChange}
              />
              <label htmlFor="passwordConfirmation">Salasana uudestaan</label>
            </div>
          </div>
        )}
      </div>
    );
  }
}
