import React, { useRef } from "react";

import { internalEditLayerDefinitions } from "./layerdefinitions/internalEditLayerDefinitions";
import { GeoExtension } from "karhu-geo-extension-component";

const controls = [
  "CONTROL_ZOOM",
  "CONTROL_TOOLS",
  "CONTROL_LAYER_PANEL",
  "CONTROL_PRINT",
  "CONTROL_SCALELINE",
  // "CONTROL_SCALE_SELECT",
  "CONTROL_SCALE_INPUT",
  // "CONTROL_TARGET_LAYER_LABEL",
  // "CONTROL_TARGET_LAYER_SELECT",
  "CONTROL_LEGEND",
  "CONTROL_FULLSCREEN",
  // "CONTROL_ATTRIBUTION",
  "CONTROL_OVERVIEWMAP",
  "CONTROL_PROGRESS",
  "CONTROL_MESSAGE",
  "CONTROL_CURSOR_COORDINATE",
];
const tools = [
  "TOOL_INFO",
  "TOOL_POSITION",
  "TOOL_SELECTION",
  "TOOL_CREATE_POINT",
  "TOOL_CREATE_LINESTRING",
  "TOOL_CREATE_POLYGON",
  "TOOL_CREATE_CIRCLE",
  "TOOL_EDIT",
  "TOOL_DELETE",
  "TOOL_MEASURE_DISTANCE",
  "TOOL_MEASURE_AREA",
];
const toolOptions = ["TOOL_OPTION_EDIT"];

export default function AdminPropertyMap({ property, stands, url, mapToken }) {
  if (!property) return;
  const mapRef = useRef();

  const handleMapReady = (map) => {
    console.log("map ready");
    mapRef.current = map;

    map.on("geoext.layer.addfeature", addGeometryListener);
    map.on("geoext.layer.changefeature", changeGeometryListener);
    map.on("geoext.layer.removefeature", removeGeometryListener);
    map.on("geoext.layer.selectfeature", selectFeatureListener);
    map.on("geoext.selection.cleared", selectionCleared);
    map.on("change:geoext.selection", selectionChanged);

    // Compartment borders
    const insertStandsDataEvt = {
      type: "geoext.layer.insertdata",
      layer: "compartment-vector",
      data: { property, stands },
    };
    map.dispatchEvent(insertStandsDataEvt);

    // Property borders
    const insertPropertyDataEvt = {
      type: "geoext.layer.insertdata",
      layer: "property-vector",
      data: { property, stands: [property] },
    };
    map.dispatchEvent(insertPropertyDataEvt);
    map.dispatchEvent({
      type: "geo.targetlayer.use",
      layerId: "compartment-vector",
    });
  };

  function addGeometryListener(event) {
    console.log(event);
    // Handle new geometry from desired layer
    // Remember to handle also effects
  }
  function changeGeometryListener(event) {
    console.log(event);
    // Handle changed geometry from desired layer
    // Remember to handle also effects
  }
  function removeGeometryListener(event) {
    console.log(event);
    // Handle removed geometry from desired layer
  }
  function selectFeatureListener(event) {
    console.log(event);
    // See selectfeature event structure
  }
  function selectionChanged(event) {
    console.log(event);
    // Handle selection changed
    // console.log(map.get('geoext.selection'));
  }

  function selectionCleared(event) {
    console.log(event);
    // Handle selection changed
    // console.log(map.get('geoext.selection'));
  }

  if (!url || !mapToken) {
    return;
  }

  return (
    <div>
      <GeoExtension
        layerDefinitions={internalEditLayerDefinitions({ url, mapToken })}
        locale={"fi"}
        controls={controls}
        tools={tools}
        toolOptions={toolOptions}
        mapReady={handleMapReady}
      />
    </div>
  );
}
