import React, { Component } from "react";
import $ from "jquery";

class ForestServiceAgreementItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFetchComplete: false,
      estateData: {}
    };
  }

  componentDidMount() {
    let interval = setInterval(() => {
      if (!this.state.dataFetchComplete) {
        console.log(this.state);
        $.getJSON(`/metsatulo/${this.props.forestServiceAgreementId}/items/${this.props.forestServiceAgreementItemId}`, data => {
          if (data && data.id) {
            this.setState({ dataFetchComplete: true, estateData: data});
          }
        });
      }
    }, 3000);
    window.interval = interval;
  }

  render() {
    if (this.state.dataFetchComplete) {
      return <h1>Data fetching complete ... render data below and form (check /metsatulo/forest_service_agreements/edit) to actually request the service quote</h1>;
    } else {
      return <h1>Fetching the data</h1>;
    }
  }
}

ForestServiceAgreementItem.propTypes = {};

export default ForestServiceAgreementItem;
