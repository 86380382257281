import React, {Component} from "react";
import Lightbox from "react-image-lightbox";
import Modal from "react-modal";

class AttachmentPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }

  renderLightbox() {
    if (this.state.isOpen) {
      return (
        <Lightbox
          mainSrc={this.props.originalUrl}
          onCloseRequest={() => this.setState({isOpen: false})}
          imageCaption={`Liite ${this.props.index}`}
        />
      );
    } else {
      return "";
    }
  }

  render() {
    return (
      <div className="cursor-pointer">
        <img
          src={this.props.thumbnailUrl}
          onClick={() => this.setState({isOpen: true})}
        />
        {this.renderLightbox()}
      </div>
    );
  }
}

AttachmentPreview.propTypes = {};

export default AttachmentPreview;
