import React, {useState} from "react";

import {Button} from "../Button/Button";

export function ConfirmDialog({ children, onClick = () => {}, buttonText = "Kyllä", title = "Oletko varma", message }) {
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  return (
    <div className="confirm-dialog-wrapper" onClick={togglePopup}>
      {popupVisible &&
        <div className="confirm-dialog-popup">
          <h4 className="medium">
            {title}
          </h4>
          {message && <p className="size-0-75 margin-top margin-0-25">
            {message}
          </p>}

          <div className="confirm-dialog-popup__footer">
            <Button size="tiny" onClick={togglePopup}>Peruuta</Button>
            <Button size="tiny" color="primary" onClick={onClick}>{buttonText}</Button>
          </div>
        </div>
      }
      {children}
    </div>
  );
}
