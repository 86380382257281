import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import request from "../../request";
import update from "immutability-helper";
import { formatArea } from "../../helpers/area";

class TransferComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: [],
      selectedPropertyIds: []
    };
  }

  componentDidMount() {
    request.markTransferViewed(this.props.transfer.id);
    $.getJSON("/properties", data => {
      this.setState({
        properties: data,
        selectedPropertyIds: data.map(property => {
          return property.id;
        })
      });
    });
  }

  updateSelectedPropertyIds(e) {
    const propertyId = parseInt(e.target.dataset.propertyId);
    if (!e.target.checked) {
      let index = this.state.selectedPropertyIds.indexOf(propertyId);
      this.setState({
        selectedPropertyIds: update(this.state.selectedPropertyIds, {
          $splice: [[index, 1]]
        })
      });
    } else {
      this.setState({
        selectedPropertyIds: update(this.state.selectedPropertyIds, {
          $push: [propertyId]
        })
      });
    }
  }

  updatePropertyVisibilitySettings(e) {
    e.preventDefault();
    fetch("/manage_properties", {
      method: "PUT",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        manage_properties: { property_ids: this.state.selectedPropertyIds }
      }),
      credentials: "same-origin"
    })
      .then(response => response.json())
      .then(response => {
        window.location = "/";
      });
  }

  renderProperties() {
    return this.state.properties.map(property => {
      return (
        <div className="properties-table__row" key={property.id}>
          <div className="properties-table__cell">
            <span className="properties-table__label size-0-75 color-gray-darken-1">
              Tila
            </span>
            <div className="properties-table__value">
              <div className="size-0-75">{property.forestIdentifier}</div>
              <div>{property.name}</div>
            </div>
          </div>
          <div className="properties-table__cell">
            <span className="properties-table__label size-0-75 color-gray-darken-1">
              Pinta-ala
            </span>
            <span className="properties-table__value">
              {formatArea(property.totalArea)}
            </span>
          </div>
          <div className="properties-table__cell">
            <span className="properties-table__label size-0-75 color-gray-darken-1">
              Tietolähde
            </span>
            <span className="properties-table__value">
              {property.dataSource}
            </span>
          </div>
        </div>
      );
    });
  }

  renderCopy() {
    if (this.props.isInitialTransfer) {
      return (
        <div>
          <p className="size-1 color-gray-darken-1">
            Siirrettiin onnistuneesti{" "}
            <span className="medium color-gray-darken-2">
              {this.state.properties.length}
            </span>{" "}
            metsätilan tiedot.
          </p>
          <p className="size-1 color-gray-darken-1">
            Jos ei halua jotain tilaa näytettävän, poista valinta alla olevasta
            listasta.
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="size-1 color-gray-darken-1">
            Metsätilojesi tiedot on päivitetty onnistuneesti
          </p>
        </div>
      );
    }
  }

  renderPropertyChecklist() {
    if (this.props.isInitialTransfer) {
      return (
        <div className="margin-1 margin-bottom">
          <div className="properties-table-wrapper">
            <div className="properties-header">
              <div className="properties-header__inner">
                <div className="properties-table__cell size-0-75 uppercase letter-spacing">
                  Tila
                </div>
                <div className="properties-table__cell size-0-75 uppercase letter-spacing">
                  Pinta-ala
                </div>
                <div className="properties-table__cell size-0-75 uppercase letter-spacing">
                  Tietolähde
                </div>
              </div>
            </div>
          </div>

          <div className="properties-table-wrapper">
            <div className="properties-table">{this.renderProperties()}</div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    return (
      <div>
        <div className="padding-1-5 padding-y text-align-center">
          <div className="margin-0-75 margin-bottom">
            <i className="opux-icon opux-icon-circled opux-icon-check size-74 color-green" />
          </div>
          <h3 className="size-1-5 margin-0-5 margin-bottom">
            Tietojen siirto valmis
          </h3>
          {this.renderCopy()}
        </div>
        {this.renderPropertyChecklist()}

        <div className="text-align-center">
          <a
            href="/home?account=new"
            className="opux-btn opux-btn-large opux-btn-primary"
          >
            {this.props.isInitialTransfer ? "Aloita OP Metsän käyttö" : "Jatka"}
          </a>
        </div>
      </div>
    );
  }
}

TransferComplete.propTypes = {
  isInitialTransfer: PropTypes.bool.isRequired,
  transfer: PropTypes.object.isRequired
};

export default TransferComplete;
