/* global Geo:false */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import Sidebar from "react-sidebar";
import PropertyMapSidebar from "../PropertyMapSidebar";

const compartmentMapLayer = 3;
const propertyBorderLayer = 5;

class PropertyMap extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.initializeGeoBasicMap = this.initializeGeoBasicMap.bind(this);
    this.closeStandDetails = this.closeStandDetails.bind(this);
  }

  geoProperties() {
    const properties = new Geo.Properties();
    properties.setRestServiceUrl(this.props.bitcompApi);
    properties.setLanguage("fi");
    properties.setBaseCrs("EPSG:3067");
    properties.setExtent([50599.4814, 6602464.0358, 751274.6247, 7709839.8902]);
    properties.setHomeExtent([390000.0, 6850000.0, 400000.0, 6950000.0]);
    properties.setResolutions([
      1024,
      512,
      256,
      128,
      64,
      32,
      16,
      8,
      4,
      2,
      1,
      0.5,
      0.25
    ]);
    properties.setSaveLayerVisibility(false);
    properties.setLibraryUrl("GeoBasic");
    properties.setTools(["TOOL_SELECTION"]);
    properties.setStyles({
      hover: {
        strokeColor: "#323232",
        strokeWidth: 2,
        fillColor: "#323232",
        fontColor: "#323232",
        fontFamily: "Chevin",
        fontWeight: "600",
        fontSize: 16
      },
      objectSelected: {
        fillOpacity: 1,
        strokeColor: "#323232",
        strokeWidth: 3,
        fillColor: "#323232",
        fontSize: 16,
        fontColor: "#ff6a10",
        fontFamily: "Chevin"
      }
    });
    // To enable geolocation, add: "OL_MAP_SHOW_GEOLOCATION"
    properties.setControls([
      "OL_MAP_ATTRIBUTION",
      "OL_MAP_SCALE_LINE",
      "OL_MAP_TOOL_CHOOSER",
      "OL_MAP_ZOOM_BUTTONS",
      "GEO_NOTIFICATION"
    ]);
    properties.setMobileDigitizing(true);

    const authHeader = new Geo.Header(
      "bitapps_username",
      this.props.bitcompEmail
    );
    const sessionId = new Geo.Header(
      "bitapps_authentication_id",
      this.props.bitcompSessionId
    );
    const applicationId = new Geo.Header("applicationPartId", "1");
    properties.setHeaders([authHeader, sessionId, applicationId]);

    return properties;
  }

  initializeGeoBasicMap() {
    this.map = new Geo.BasicMap("property-map-map-panel", this.geoProperties());

    this.map.addListener("mapInitialized", (type, eventObject) => {
      this.map.setTargetLayer(compartmentMapLayer);
      this.map.activateTool("TOOL_SELECTION");

      const x = this.props.mapX;
      const y = this.props.mapY;
      const zoomLevel = 9;

      this.map.zoomToCoordinate(x, y, zoomLevel);
      if (this.props.stands.length === 0) {
        this.map.addStyle(
          propertyBorderLayer,
          [this.props.property.bitcompId],
          {
            strokeColor: "#ff6a10",
            strokeDashstyle: "solid",
            fillColor: "#ff6a10"
          }
        );
      } else {
        this.map.hideAllFeatures(propertyBorderLayer);
      }
      this.map.hideAllFeatures(compartmentMapLayer);
    });

    // On initial load, all features are hidden and then we reveal the ones we
    // want to show.
    this.map.addListener("layerVisibilityChanged", (type, event) => {
      if (event.layerId === compartmentMapLayer) {
        this.showActiveStands();
      }
    });

    // When clicked on stands in this property, open the details panel.
    this.map.addListener("objectsSelectionChanged", (type, event) => {
      this.selectStand(event.objectId);
    });

    this.map.init();
  }

  showActiveStands() {
    const standIds = this.props.stands.map(stand => stand.bitcompId);
    this.map.addStyle(compartmentMapLayer, standIds, {
      display: "inline"
    });
  }

  selectStand(selectedObjects) {
    this.map.removeSelections();
    if (this.findStand(selectedObjects[0])) {
      this.openStandDetails(selectedObjects[0]);
      this.map.addSelection(compartmentMapLayer, [selectedObjects], []);
    }
  }

  componentWillMount() {
    window.addEventListener("mapready", this.initializeGeoBasicMap);

    const mql = window.matchMedia("(min-width: 700px)");
    this.setState({ mql: mql });
  }

  openStandDetails(standId) {
    this.setState({
      selectedStandId: standId
    });
  }

  clearMapSelection() {
    this.map.removeSelections();
  }

  findStand(standId) {
    return this.props.stands.find(stand => {
      return stand.bitcompId === standId;
    });
  }

  closeStandDetails(event) {
    event.preventDefault();

    this.setState({
      selectedStandId: null
    });
    this.clearMapSelection();
  }

  sidebarContent(propertyName, stand) {
    let sidebar;
    if (stand) {
      sidebar = (
        <PropertyMapSidebar
          key={stand.id}
          propertyName={propertyName}
          stand={stand}
          closeStandDetails={this.closeStandDetails}
        />
      );
    } else {
      sidebar = <div />;
    }

    return (
      <CSSTransition
        classNames="sidebar-content-animate"
        timeout={{ enter: 300, exit: 300 }}
      >
        {sidebar}
      </CSSTransition>
    );
  }

  sidebarProps() {
    const { name } = this.props.property;
    const sidebarWidth = this.state.mql.matches
      ? window.innerWidth / 4
      : window.innerWidth * 0.84;

    return {
      sidebar: this.sidebarContent(
        name,
        this.findStand(this.state.selectedStandId)
      ),
      docked: this.state.docked,
      sidebarClassName: "map-sidebar",
      open: !!this.state.selectedStandId,
      styles: {
        sidebar: {
          width: sidebarWidth,
          minWidth: 280,
          overflowX: "hidden",
          transition:
            "transform 250ms cubic-bezier(0.770, 0.000, 0.175, 1.000)",
          WebkitTransition:
            "-webkit-transform 250ms cubic-bezier(0.770, 0.000, 0.175, 1.000)"
        },
        overlay: {
          display: "none"
        }
      }
    };
  }

  render() {
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <Sidebar {...this.sidebarProps()}>
          <div className="property-map" style={{ height: "100%" }}>
            <div
              id="property-map-map-panel"
              style={{ width: "100%", height: "100%" }}
              className="flat"
            />
          </div>
        </Sidebar>
      </div>
    );
  }
}

PropertyMap.propTypes = {
  bitcompApi: PropTypes.string.isRequired,
  property: PropTypes.object.isRequired,
  bitcompEmail: PropTypes.string.isRequired,
  bitcompSessionId: PropTypes.string.isRequired,
  mapX: PropTypes.number.isRequired,
  mapY: PropTypes.number.isRequired,
  stands: PropTypes.array.isRequired
};

export default PropertyMap;
