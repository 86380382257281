import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatCurrencyWithDecimal } from "../../helpers/currency";
import { formatDate } from "../../helpers/date";
import SlipDescription from "./SlipDescription";

class IncomeSlip extends Component {
  render() {
    return (
      <a
        className="list-links__item"
        href={`/vero/transactions/${this.props.slip.id}`}
        data-native-action="pushScreen"
        data-native-title={`Tulotosite ${formatDate(
          this.props.slip.transactionDate
        )}`}
        data-native-right-button-url={`/vero/transactions/${
          this.props.slip.id
        }/edit`}
      >
        <div className="grow overflow-x-hidden">
          <div className="size-1 color-gray-darken-2">
            Tulotosite {formatDate(this.props.slip.transactionDate)}
          </div>
          <div className="size-0-75 color-gray-darken-1 overflow-x-hidden overflow-ellipsis nowrap">
            <SlipDescription description={this.props.slip.description} />
          </div>
        </div>
        <div className="flex align-center text-align-right">
          <div className="size-0-75 line-1-25 color-gray-darken-1">
            {formatCurrencyWithDecimal(this.props.slip.amount)}
          </div>
          <i className="opux-icon opux-icon-chevron-r color-gray-darken-1 size-1-25" />
        </div>
      </a>
    );
  }
}

IncomeSlip.propTypes = {
  slip: PropTypes.shape({
    id: PropTypes.number.isRequired,
    transactionable: PropTypes.shape({
      id: PropTypes.number.isRequired,
      category: PropTypes.string.isRequired
    })
  }).isRequired
};

export default IncomeSlip;
