import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatArea } from "../helpers/area";
import { formatVolume } from "../helpers/volume";
import _ from "lodash";

class PropertyMapSidebar extends Component {
  renderGeneralInformation() {
    const stand = this.props.stand;
    return (
      <div className="border-color-border">
        <div className="padding-0-5 border-bottom border-color-border">
          <h3 className="size-1-25">Perustiedot</h3>
        </div>
        <ul className="child-margins-y-0-5 padding-0-5 padding-y">
          {this.renderListItem("Pinta-ala", formatArea(stand.area))}
          {this.renderListItem("Kehitysluokka", stand.developmentClass)}
          <li className="padding-0-5 padding-x">
            <span className="block size-0-75 color-gray-darken-1">
              Puuston tilavuus
            </span>
            <span className="child-margins-x-0-5">
              <span className="nowrap inline-block">
                Kuviolla {formatVolume(stand.totalTreeVolume)}
              </span>
              <span className="nowrap inline-block">
                Hehtaarilla {formatVolume(stand.averageTreeVolume)}/ha
              </span>
            </span>
          </li>
          {this.renderListItem("Kasvupaikka", stand.siteClass)}
          {this.renderListItem("Maaperä", stand.soilType)}
          {this.renderListItem("Saavutettavuus", stand.accessibility)}
        </ul>
      </div>
    );
  }

  renderListItem(label, value, unit = "") {
    if (
      (_.isArray(value) && value.length > 0) ||
      (_.isString(value) && _.trim(value).length > 0) ||
      _.isFinite(value)
    ) {
      return (
        <li className="padding-0-5 padding-x">
          <span className="block size-0-75 color-gray-darken-1">{label}</span>
          <span className="size-1 color-gray-darken-2 block">
            {value} {unit}
          </span>
        </li>
      );
    }
  }
  renderListItemArray(label, values) {
    if (values && values.length > 0) {
      return (
        <li className="padding-0-5 padding-x">
          <span className="block size-0-75 color-gray-darken-1">{label}</span>
          <span className="child-margins-x-0-5 block">{values}</span>
        </li>
      );
    }
  }
  renderSummaryBlock(summary) {
    return (
      <span key={summary.description}>
        <span className="size-1 nowrap inline-block color-gray-darken-2">
          {summary.description}&nbsp;
        </span>
        <span className="color-gray-darken-2">
          {formatVolume(summary.volume)}
        </span>
      </span>
    );
  }

  renderTreeSummaries() {
    const stand = this.props.stand;

    if (!stand.treeSummaries) {
      return [];
    }
    return stand.treeSummaries.map((summary, index) => {
      let treeSpecies = [];
      if (summary.treeSpecies) {
        treeSpecies = treeSpecies.map((treeSpecie) => {
          return (
            <span key={treeSpecie.name}>
              <span className="size-1 nowrap inline-block color-gray-darken-2">
                {treeSpecie.name}&nbsp;
              </span>
              <span className="color-gray-darken-2">
                {treeSpecie.percentage}%
              </span>
            </span>
          );
        });
      }

      const pulpWoodSummary = (summary.pulpWoodSummaries || []).map(
        this.renderSummaryBlock
      );
      const sawLogSummary = (summary.sawLogSummaries || []).map(
        this.renderSummaryBlock
      );
      return (
        <div key={index}>
          <div className="padding-0-5 borders-v border-color-border">
            <h3 className="size-1-25">Puusto</h3>
          </div>
          <ul
            className="child-margins-y-0-5 padding-0-5 padding-y"
            key={summary.title}
          >
            {this.renderListItem("Ikä", summary.age, "v")}
            {this.renderListItem(
              "Keskiläpimitta",
              summary.averageDiameter,
              "cm"
            )}
            {this.renderListItem("Keskipituus", stand.averageLength, "m")}
            {this.renderListItem("Runkoluku", summary.density, "kpl/ha")}
            {this.renderListItem("Pohjapinta-ala", summary.basalArea, "m²/ha")}
            {this.renderListItem("Tilavuus", formatVolume(summary.treeVolume))}
            {this.renderListItemArray("Puulajijakauma", treeSpecies)}
            {this.renderListItemArray("Tukki", sawLogSummary)}
            {this.renderListItemArray("Kuitu", pulpWoodSummary)}
          </ul>
        </div>
      );
    });
  }

  renderSpecialFeatures() {
    const stand = this.props.stand;
    const features = this.props.stand.specialFeatures.map((feature, index) => {
      return (
        <li className="padding-0-5 padding-x border-color-border" key={index}>
          <span className="block size-1 color-gray-darken-2">
            {feature.name}
          </span>
          <span className="block size-0-75 color-gray-darken-1">
            {feature.featureType}
          </span>
          <span className="block size-0-75 color-gray-darken-1">
            {feature.description}
          </span>
        </li>
      );
    });

    if (stand.specialFeatures && stand.specialFeatures.length > 0) {
      return (
        <div>
          <div className="padding-0-5 borders-v border-color-border">
            <h3 className="size-1-25">Luontokohteet</h3>
          </div>
          <ul className="child-margins-y-0-5 padding-0-5 padding-y child-borders-y">
            {features}
          </ul>
        </div>
      );
    }
  }

  render() {
    const stand = this.props.stand;
    if (!stand) {
      return null;
    }

    return (
      <div className="sidebar-content" key={stand.identifier}>
        <div
          className={`padding-0-5 border-color-border ${
            this.props.tableView ? "border-top" : "border-bottom"
          }`}
        >
          <div className="table">
            {this.props.closeStandDetails && (
              <div className="table-cell vertical-middle padding-0-25 padding-right">
                <a
                  href="#"
                  className="opux-icon opux-icon-arrow-l color-gray-darken-2 hover-no-underline"
                  onClick={(event) => {
                    event.preventDefault();
                    this.props.closeStandDetails();
                  }}
                />
              </div>
            )}
            <div className="table-cell vertical-middle">
              <h2 className="size-1-25">
                {this.props.propertyName && (
                  <span className="block size-1">
                    {this.props.propertyName}
                  </span>
                )}
                <span>Kuvio {stand.identifier}</span>
              </h2>
            </div>
          </div>
        </div>

        {this.renderGeneralInformation()}

        {this.renderTreeSummaries()}

        {this.renderSpecialFeatures()}
      </div>
    );
  }
}

PropertyMapSidebar.propTypes = {
  stand: PropTypes.object.isRequired,
  propertyName: PropTypes.string,
  closeStandDetails: PropTypes.func,
  tableView: PropTypes.bool,
};

export default PropertyMapSidebar;
