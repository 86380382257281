import React, {useState, useEffect} from "react";
import request from "../../../../request";
import {CopyToClipboard} from "react-copy-to-clipboard";
import cx from "classnames";

import ValuationForm from "./ValuationForm";
import ValuationSummary from "./ValuationSummary";
import PropertyInfo from "./PropertyInfo";
import {Button} from "../../Button/Button";
import {Loader} from "../../Loader/Loader";
import {ConfirmDialog} from "../../ConfirmDialog/ConfirmDialog";

export default function ForestValuationOrderItem({itemId, reloadOrder}) {
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [valuationFormOpen, setValuationFormOpen] = useState(false);
  const [hasBeenValuated, setHasBeenValuated] = useState(false);

  function handleItemData(id) {
    setLoading(true);
    request.forestValuationItemData(id)
      .then(response => {
        const item = response.data;
        setItem(item);
        setValuationFormOpen(!item.corrections_applied);
        setHasBeenValuated(item.corrections_applied);
        setLoading(false);
      })
      .catch(() => {
        setErrorMessage("Tietoja ei saatu haettua.");
        setLoading(false);
      });
  }

  useEffect(() => {
    handleItemData(itemId);
  }, []);

  const processed = item && item.status && item.status === "ready_for_sending";
  const cannotBeProcessed = item && item.status && (item.status === "valuation_not_possible" || item.status === "report_preparation_failed");

  return (
    <div className={cx("tile", {"tile--green": processed, "tile--yellow": cannotBeProcessed && !errorMessage, "tile--red": errorMessage})}>
      <Header item={item} reloadOrder={reloadOrder}/>
      <div className="metsa-arvio-content">
        <div className="metsa-arvio-content__left">
          <div className="metsa-arvio-section">
            <h3 className="size-1-25 medium">Perustiedot</h3>
          </div>
          <LeftSide loading={loading} errorMessage={errorMessage} item={item} hasBeenValuated={hasBeenValuated} />
        </div>
        <div className="metsa-arvio-content__right">
          <div className="metsa-arvio-section">
            <h3 className="size-1-25 medium">Arviointi</h3>
          </div>
          <RightSide
            loading={loading}
            errorMessage={errorMessage}
            item={item}
            hasBeenValuated={hasBeenValuated}
            refetchItem={handleItemData}
            valuationFormOpen={valuationFormOpen}
            setValuationFormOpen={setValuationFormOpen}
            cannotBeProcessed={cannotBeProcessed}
          />
        </div>
      </div>
    </div>
  );
}

function Header({item, reloadOrder}) {
  const [copied, setCopied] = useState(false);

  function handleDeleteItem() {
    request.forestValuationItemDestroy(item.id)
      .then(response => {
        reloadOrder();
        setLoading(false);
      })
      .catch(() => {
        setErrorMessage("Tuotetta ei saatu poistettua.");
        setLoading(false);
      });
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, [copied]);

  return (
    <div className="metsa-arvio-header">
      <div className="metsa-arvio-header__left">
        <div>
          <div className="left-title">
            <h3 className="weight-500 size-1-25 line-1">
              {item.forest_identifier}
              {item.municipality_name && ', '}
              {item.municipality_name && <span className="title-gray">{item.municipality_name}</span>}
            </h3>
          </div>
          <div className="size-0-75">{item.name}</div>
        </div>
        <div className="right-side">
          {item.forest_identifier && (
            <CopyToClipboard
              text={item.forest_identifier}
              onCopy={() => setCopied(true)}
            >
              <Button size="tiny">
                {copied ? "Kopioitu" : "Kopioi kiinteistötunnus"}
              </Button>
            </CopyToClipboard>
          )}
          <a
            href={`/admin/metsaarvio/forest_valuation_items/${item.id}/map`}
            target="_blank"
          >
            Kartta
          </a>
        </div>
      </div>
      <div className="metsa-arvio-header__right">
        <ConfirmDialog onClick={handleDeleteItem} buttonText="Poista" >
          <button
            className={"opux-btn opux-btn-alternate opux-btn--tiny"}>Poista tilaukselta</button>
        </ConfirmDialog>
      </div>
    </div>
  );
}

function LeftSide({loading, errorMessage, item, hasBeenValuated}) {
  if (errorMessage) {
    return <div className="padding-1 grow flex align-center justify-center text-align-center color-red">{errorMessage}</div>;
  }
  if (loading || Object.keys(item).length === 0) {
    return <div className="padding-1 grow flex align-center justify-center"><Loader large/></div>;
  }
  return (
    <PropertyInfo item={item} hasBeenValuated={hasBeenValuated}/>
  );
}
function RightSide({loading, errorMessage, item, hasBeenValuated, valuationFormOpen, setValuationFormOpen, refetchItem, cannotBeProcessed}) {
  if (errorMessage) {
    return <div className="padding-1 grow flex align-center justify-center text-align-center color-red">{errorMessage}</div>;
  }
  if (loading || Object.keys(item).length === 0) {
    return <div className="padding-1 grow flex align-center justify-center"><Loader large/></div>;
  }
  return (
    <React.Fragment>
      {valuationFormOpen || cannotBeProcessed
      ? <ValuationForm item={item} refetchItem={refetchItem} setValuationFormOpen={setValuationFormOpen} hasBeenValuated={hasBeenValuated} />
      : <ValuationSummary item={item} setValuationFormOpen={setValuationFormOpen} />
    }
    </React.Fragment>
  );
}
