import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatArea } from "../../../helpers/area";
import { formatCurrency } from "../../../helpers/currency";

class ActionTimelineItem extends Component {
  renderTitle() {
    const {
      forSingleProperty,
      propertySlug,
      propertyName,
      operation
    } = this.props;
    if (forSingleProperty) {
      return (
        <h3 className="tile__title size-1">
          <span>{operation.name}</span>
        </h3>
      );
    } else {
      return (
        <h3 className="tile__title">
          <a
            href={"/properties/" + propertySlug}
            className="tile__link tile__link--title"
          >
            <p className="block size-0-75">{propertyName}</p>
            <h3 className="size-1">{operation.name}</h3>
          </a>
        </h3>
      );
    }
  }
  renderStats() {
    const { operationType, stand, operation } = this.props;
    if (operationType === "treatment") {
      return (
        <div className="stats stats--left">
          <div className="stats__item padding-0-5 padding-x">
            <span className="stats__label size-0-75 color-gray-darken-1">
              Kuvio
            </span>
            <span className="stats__value size-1">{stand.identifier}</span>
          </div>
          <div className="stats__item padding-0-5 padding-x">
            <span className="stats__label size-0-75 color-gray-darken-1">
              Pinta-ala
            </span>
            <span className="stats__value size-1">
              {formatArea(operation.area)}
            </span>
          </div>
          <div className="stats__item padding-0-5 padding-x">
            <span className="stats__label size-0-75 color-gray-darken-1">
              Menoarvio
            </span>
            <span className="stats__value size-1">
              {formatCurrency(operation.cost)}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="stats stats--left">
          <div className="stats__item padding-0-5 padding-x">
            <span className="stats__label size-0-75 color-gray-darken-1">
              Kuvio
            </span>
            <span className="stats__value size-1">{stand.identifier}</span>
          </div>
          <div className="stats__item padding-0-5 padding-x">
            <span className="stats__label size-0-75 color-gray-darken-1">
              Pinta-ala
            </span>
            <span className="stats__value size-1">
              {formatArea(operation.area)}
            </span>
          </div>
          <div className="stats__item padding-0-5 padding-x">
            <span className="stats__label size-0-75 color-gray-darken-1">
              Tuloarvio
            </span>
            <span className="stats__value size-1">
              {formatCurrency(operation.income)}
            </span>
          </div>
        </div>
      );
    }
  }

  render() {
    const { proposedYear } = this.props;
    return (
      <li className="timeline__item">
        <div className="timeline__circle" />
        <div className="timeline__content">
          <div className="tile timeline__tile">
            <div className="tile__content">{this.renderTitle()}</div>
            <div className="tile__content">{this.renderStats()}</div>
          </div>
        </div>
        <div className="timeline__date">
          <span className="timeline__year">{proposedYear}</span>
        </div>
      </li>
    );
  }
}

ActionTimelineItem.propTypes = {
  forSingleProperty: PropTypes.bool,
  propertyName: PropTypes.string,
  propertySlug: PropTypes.string,
  stand: PropTypes.object.isRequired,
  operationType: PropTypes.string,
  operation: PropTypes.object.isRequired,
  proposedYear: PropTypes.number
};

export default ActionTimelineItem;
