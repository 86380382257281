import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatArea } from "../../helpers/area";
import { formatCurrency } from "../../helpers/currency";
import { getYear } from "../../helpers/date";

class PropertyCard extends Component {
  nextActionDetails() {
    if (this.props.property.nextAction) {
      return (
        <span>
          {getYear(this.props.property.nextAction.date)}{" "}
          {this.props.property.nextAction.operation.name}
        </span>
      );
    } else {
      return <span>Ei toimenpiteitä</span>;
    }
  }

  render() {
    const {
      name,
      slug,
      forestIdentifier,
      totalArea,
      forestryArea,
      municipality,
      totalValue,
      dataSource,
      userId
    } = this.props.property;
    const owners = this.props.property.owners.join(", ");
    let propertyUrl;
    if (this.props.admin) {
      propertyUrl = `/admin/users/${userId}/properties/${slug}`;
    } else {
      propertyUrl = `/properties/${slug}`;
    }

    return (
      <a
        href={propertyUrl}
        className="properties-table__row hover-no-underline color-gray-darken-2 color-hover-orange"
      >
        <div className="properties-table__cell">
          <div className="property-arrow">
            <i className="opux-icon opux-icon-chevron-r color-primary top-1 size-1-25 property-arrow__icon" />
            <div className="property-arrow__label">
              <div className="size-0-75">{forestIdentifier}</div>
              <div>
                {name}, {municipality}
              </div>
            </div>
          </div>
        </div>
        <div className="properties-table__cell properties-table__cell--number">
          <span className="properties-table__label size-0-75 color-gray-darken-1">
            Metsäala / Pinta-ala
          </span>
          <span className="properties-table__value">
            {formatArea(forestryArea)}
            {" / "}
            {formatArea(totalArea)}
          </span>
        </div>
        <div className="properties-table__cell properties-table__cell--number">
          <span className="properties-table__label size-0-75 color-gray-darken-1">
            Puuston arvo
          </span>
          <span className="properties-table__value">
            {formatCurrency(totalValue)}
          </span>
        </div>
        <div className="properties-table__cell properties-table__cell--number">
          <span className="properties-table__label size-0-75 color-gray-darken-1">
            Tietolähde
          </span>
          <span className="properties-table__value">{dataSource}</span>
        </div>
      </a>
    );
  }
}

PropertyCard.propTypes = {
  property: PropTypes.object.isRequired
};

export default PropertyCard;
