import React from "react";
import { Alert } from '@op/opds.opux';

export default function OpuxAlert({ message, type, inline }) {
  return (
    <Alert
      className="opuxAlert"
      {...(inline ? { inline } : {})}
      {...(type ? type={ type } : {})}
    >
      {message}
    </Alert>
  );
}