import React, {Component} from "react";
import $ from "jquery";

import TransferActive from "./TransferActive";
import TransferComplete from "./TransferComplete";
import TransferNoPermission from "./TransferNoPermission";
import TransferNotFound from "./TransferNotFound";
import TransferFailed from "./TransferFailed";

class TransferStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transferStatus: "active",
      isInitialTransfer: this.props.isInitialTransfer
    };
  }

  componentDidMount() {
    let interval = setInterval(() => {
      if (this.state.transferStatus === "active") {
        $.getJSON("/properties_synced", data => {
          if (data.transfer.status === "initiated") {
            this.setState({
              statusText: "Haetaan kiinteistölistaasi…",
              step: 1,
            });
          } else if (data.transfer.status === "getting_permissions") {
            this.setState({
              statusText: "Haetaan kiinteistölistaasi…",
              step: 1,
            });
          } else if (data.transfer.status === "in_progress") {
            if (this.state.step === 3) {
              this.setState({
                statusText: `Lasketaan metsätilojen arvoa (${data.transfer
                  .estates.processed}/${data.transfer.estates.total})…`,
              });
            } else {
              this.setState({
                statusText: `Haetaan metsätilojen tietoja (${data.transfer
                  .estates.processed}/${data.transfer.estates.total})…`,
                step: 2,
              });

              setTimeout(() => {
                this.setState({
                  statusText: `Lasketaan metsätilojen arvoa (${data.transfer
                    .estates.processed}/${data.transfer.estates.total})…`,
                  step: 3,
                });
              }, 15000);
            }
          } else if (data.transfer.status === "success") {
            this.setState({
              transferStatus: "complete",
              isInitialTransfer: data.transfer.is_initial_transfer,
              transfer: data.transfer,
            });
          } else if (data.transfer.status === "failed") {
            switch (data.transfer.fail_reason) {
              case "no_properties":
                this.setState({transferStatus: "notFound"});
                break;
              case "no_permissions":
                this.setState({
                  transferStatus: "noPermission",
                  transfer: data.transfer,
                });
                break;
              case "initial_meke_call_failed":
                this.setState({transferStatus: "mekeApiFail"});
                break;
              default:
                this.setState({transferStatus: "mekeApiError"});
            }
          } else {
            alert("Jokin meni pieleen siirron tilan haussa.");
          }
        });
      }
    }, 3000);
    window.interval = interval;
  }

  renderView() {
    switch (this.state.transferStatus) {
      case "active":
        return (
          <TransferActive
            step={this.state.step}
            statusText={this.state.statusText}
          />
        );
      case "complete":
        return (
          <TransferComplete
            metsaanUrl={this.props.metsaanUrl}
            transfer={this.state.transfer}
            isInitialTransfer={this.state.isInitialTransfer}
          />
        );
      case "noPermission":
        return (
          <TransferNoPermission
            metsaanUrl={this.props.metsaanUrl}
            transfer={this.state.transfer}
          />
        );
      case "notFound":
        return <TransferNotFound metsaanUrl={this.props.metsaanFeedbackUrl} />;
      case "mekeApiFail":
        return <TransferFailed transferStatus="mekeApiFail" />;
      case "mekeApiError":
        return <TransferFailed transferStatus="mekeApiError" />;
    }
  }

  render() {
    return <div>{this.renderView()}</div>;
  }
}

TransferStatus.propTypes = {};

export default TransferStatus;
