import PropTypes from "prop-types";

export default PropTypes.arrayOf(
  PropTypes.shape({
    fellingIncomes: PropTypes.number.isRequired,
    treatmentCosts: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    valueOfGrowth: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired
  })
);
