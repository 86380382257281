import moment from "moment";

export const getYear = date => {
  if (date) {
    return moment(date).format("YYYY");
  } else {
    return "";
  }
};

export const formatDate = date => {
  if (date) {
    return moment(date).format("D.M.YYYY");
  } else {
    return "";
  }
};

export const formatDateWithoutYear = date => {
  if (date) {
    return moment(date).format("D.M.");
  } else {
    return "";
  }
};

