import React from "react";
import ReactDOM from "react-dom";
import PikaAnalyysi from "./components/PikaAnalyysi";
import NewsletterSignup from "./components/NewsletterSignup";
import PropertyProgressBox from "./components/PropertyProgressBox";
import ActionTimeline from "./components/ActionTimeline";
import PropertyCard from "./components/PropertyCard";
import OwnProperties from "./components/OwnProperties";
import TransferStatusBanner from "./components/TransferStatusBanner";
import PropertyStands from "./components/PropertyStands";
import PropertyDetails from "./components/PropertyDetails";
import PropertyDetailsValueGrowth from "./components/PropertyDetails//PropertyDetailsValueGrowth";
import PropertyDetailsHero from "./components/PropertyDetails/PropertyDetailsHero";
import PropertyDetailsAreaAndCheck from "./components/PropertyDetails/PropertyDetailsAreaAndCheck";
import PropertyDetailsTimber from "./components/PropertyDetails/PropertyDetailsTimber";
import SelectBorderless from "./components/SelectBorderless";
import KarhuBarChart from "./components/KarhuBarChart";
import KarhuPieChart from "./components/KarhuPieChart";
import CounterPieChart from "./components/CounterPieChart";
import PercentagePieChart from "./components/PercentagePieChart";
import TransferStatus from "./components/TransferStatus/TransferStatus";
import TransactionSlips from "./components/Vero/TransactionSlips";
import AttachmentUpload from "./components/Vero/AttachmentUpload";
import AttachmentPreview from "./components/Vero/AttachmentPreview";
import ForestServiceAgreementItem from "./components/ForestServiceAgreementItem/ForestServiceAgreementItem";
import FieldValuationMultiSelect from "./components/FieldValuationMultiSelect/FieldValuationMultiSelect";
import BankAgentSelect from "./components/BankAgentSelect/BankAgentSelect";
import PropertyMap from "./components/PropertyMap";
import PropertyMapGeo2 from "./components/PropertyMapGeo2/LazyPropertyMapGeo2";
import AdminPropertyMap from "./components/AdminPropertyMap/LazyAdminPropertyMap";
import SearchableSelect from "./components/SearchableSelect/SearchableSelect";


// Admin components
import ForestValuationOrder from "./components/admin/ForestValuationOrder";
import OpuxAlert from "./components/admin/Alert";

const components = {
  PikaAnalyysi,
  NewsletterSignup,
  PropertyProgressBox,
  ActionTimeline,
  PropertyCard,
  OwnProperties,
  TransferStatusBanner,
  PropertyDetails,
  PropertyDetailsHero,
  PropertyDetailsValueGrowth,
  PropertyDetailsAreaAndCheck,
  PropertyDetailsTimber,
  SelectBorderless,
  PropertyStands,
  KarhuBarChart,
  KarhuPieChart,
  CounterPieChart,
  PercentagePieChart,
  TransferStatus,
  TransactionSlips,
  AttachmentUpload,
  AttachmentPreview,
  ForestServiceAgreementItem,
  FieldValuationMultiSelect,
  BankAgentSelect,
  PropertyMap,
  PropertyMapGeo2,
  AdminPropertyMap,
  SearchableSelect
};

const adminComponents = {
  ForestValuationOrder,
  OpuxAlert
};

export const loadComponents = (event) => {
  $("[data-component]").each((idx, element) => {
    const componentName = element.getAttribute("data-component");
    const component =
      components[componentName] || adminComponents[componentName];
    if (component) {
      const props = JSON.parse(element.getAttribute("data-props") || "{}");
      const reactElem = React.createElement(component, props);
      ReactDOM.render(reactElem, element);
    } else {
      throw new Error(
        `Could not find "${componentName}" from the components table. Did you forgot to add it?`
      );
    }
  });
};

export function unmountComponents() {
  document.querySelectorAll("[data-component]").forEach((node) => {
    ReactDOM.unmountComponentAtNode(node);
  });
}
