import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid
} from "recharts";
import { formatCurrency } from "../../helpers/currency";

class CustomTooltip extends Component {
  renderPayload() {
    const { payload } = this.props;
    return payload.map(payloadItem => {
      return (
        <div key={payloadItem.name}>
          <span className="size-0-75 color-gray-darken-1">
            <span
              className="tooltip__legend"
              style={{ background: payloadItem.color }}
            />
            {payloadItem.name}:&nbsp;
          </span>
          <span className="size-0-75">{formatCurrency(payloadItem.value)}</span>
        </div>
      );
    });
  }
  render() {
    return (
      <div className="tooltip padding-0-5">
        <div className="tooltip__header size-1 margin-0-125 margin-bottom">
          <span>Ennuste {this.props.label}</span>
        </div>
        <div className="tooltip__content child-margins-y-0-125">
          {this.renderPayload()}
        </div>
      </div>
    );
  }
}
class KarhuBarChart extends Component {
  render() {
    const updatedData = this.props.data.map(d => {
      if (!d.value) {
        d.value = 0;
      }
      if (!d.seedlingsValue) {
        d.seedlingsValue = 0;
      }
      if (!d.fellingIncomes) {
        d.fellingIncomes = 0;
      }

      return d;
    });

    return (
      <div className="money-bar-chart size-0-75">
        <ResponsiveContainer height={300}>
          <BarChart
            height={300}
            data={updatedData}
            margin={{ top: 40, bottom: 10, left: 10, right: 40 }}
          >
            <XAxis dataKey="year" height={40} />
            <YAxis width={80} axisLine={false} tickFormatter={formatCurrency} />
            <Tooltip
              active
              content={<CustomTooltip />}
              unit={false}
              cursor={false}
            />
            <CartesianGrid vertical={false} />
            <Bar
              name="Puusto"
              stackId="1"
              unit="€"
              type="monotone"
              dataKey="value"
              barSize={18}
              fill="#6e6e6e"
            />
            <Bar
              name="Taimikot"
              stackId="1"
              unit="€"
              type="monotone"
              dataKey="seedlingsValue"
              barSize={18}
              fill="#bebebe"
            />
            <Bar
              name="Hakkuutulot"
              stackId="1"
              unit="€"
              type="monotone"
              dataKey="fellingIncomes"
              barSize={18}
              fill="#ff6a10"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

KarhuBarChart.propTypes = {
  data: PropTypes.array.isRequired
};
CustomTooltip.propTypes = {
  payload: PropTypes.array,
  label: PropTypes.number
};

export default KarhuBarChart;
