import React, { Component } from "react";
import PropTypes from "prop-types";

class SelectBorderless extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasValue: false
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleFocus(event) {
    this.setState({ isFocused: true });
    this.props.onFocus(event);
  }
  handleBlur(event) {
    this.setState({ hasValue: Boolean(event.currentTarget.value) });
    this.setState({ isFocused: false });
    this.props.onBlur(event);
  }
  handleChange(event) {
    this.setState({ hasValue: Boolean(event.currentTarget.value) });
    this.props.onChange(event);
  }
  render() {
    const { disabled, value, getLabel } = this.props;

    let wrapperClasses = ["select-borderless"];
    if (this.state.isFocused) wrapperClasses.push("select-borderless--focused");
    if (this.props.small) wrapperClasses.push("select-borderless--small");
    return (
      <span className={wrapperClasses.join(" ")}>
        <span className="select-borderless__text">{getLabel(value)}</span>
        <select
          className="select-borderless__select"
          value={value}
          disabled={disabled}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          {this.props.children}
        </select>
      </span>
    );
  }
}

SelectBorderless.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  getLabel: PropTypes.func,
  value: PropTypes.any,
  children: PropTypes.node
};

SelectBorderless.defaultProps = {
  disabled: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  getLabel: value => value
};

export default SelectBorderless;
