import React, {useState, useEffect} from "react";
import Select, { components } from "react-select";

export default function SearchableSelect({options = null, selected = null, formId = "", label = "Pankit", fieldSelector = "#query"}) {
  const [selectedOption, setSelectedOption] = useState(selected ? selected : null);

  const allOptions = [ {label: label, options: options}, ];

  function handleBankSelect(value) {
    setSelectedOption(value);

    let hiddenRailsInput = document.querySelector(fieldSelector);
    const json = JSON.stringify(value);
    hiddenRailsInput.setAttribute("value", json);

    //let form = document.querySelector(formId);
    //form.submit();
  }

  useEffect(() => {
  }, [selectedOption]);

  return (
    <div className="child-margins-y-1">
      <div>
        <div className="medium margin-0-25 margin-bottom">{label}</div>
        <Select
          isClearable
          value={selectedOption}
          isMulti={false}
          options={allOptions}
          className="react-select-container"
          classNamePrefix="react-select"
          components={{ Option }}
          placeholder={"Valitse pankki"}
          noOptionsMessage={() => "Ei hakutuloksia"}
          onChange={handleBankSelect}
        />
      </div>
    </div>
  );
}

const Option = props => {
  return (
    <components.Option {...props}>
      {props.data.label}
      <div className="size-0-75 color-gray-darken-1">{props.data.subtitle}</div>
    </components.Option>
  );
};

